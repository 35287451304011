export class ApiObjPopulateFileOrderStep3 {

    // ==============================================================
    // === Fields
    // ==============================================================

    match_count_details: Array<{type: number, count: number}>;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.match_count_details = [];
        for (const item of data.match_count_details) {
            this.match_count_details.push({
                type: parseInt(item.type),
                count: parseInt(item.count)
            });
        }
    }
}