import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';

const DialogComponent = ({ className = '', newOpen, children, setNewOpen }) => {
  const [open, setOpen] = useState(false);
  const handleShowDialog = (value) => {
    setOpen(value);
    setNewOpen(value);
  };
  useEffect(() => {
    setOpen(newOpen);
  }, [newOpen]);
  return (
    <Dialog open={open} onClose={() => handleShowDialog(false)} className={className}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogComponent;
