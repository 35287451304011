import { Box, Button, Checkbox, MenuItem, Modal, Paper, Select, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import { ApiObjPopulateFile } from '../../api/object/populate_file/ApiObjPopulateFile';
import { API_URL } from '../../constants/Constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
    userData: UserData,
    baseData: ApiObjBaseData,
    populateFileObj: ApiObjPopulateFile
    useAdminUrl: boolean,
    onClose: () => void
}

export const PopulateFileDownloadModal: FunctionComponent<Props> = ({
    userData,
    baseData,
    populateFileObj,
    useAdminUrl,
    onClose
}) => {

    // ===========================================================
    // === State
    // ===========================================================
    const [inputDataOption, setInputDataOption] = useState <number> (2);
    const [excludeNoMatch, setExcludeNoMatch] = useState <boolean> (false);
    const [excludeCleanStopfile, setExcludeCleanStopfile] = useState <boolean> (false);
    const [excludeCleanLowIncome, setExcludeCleanLowIncome] = useState <boolean> (false);
    const [excludeCleanNix, setExcludeCleanNix] = useState <boolean> (false);
    const [excludeCleanHlr, setExcludeCleanHlr] = useState <boolean> (false);
    const [formatPersonalId, setFormatPersonalId] = useState <number> (1);
    const [formatBirthDate, setFormatBirthDate] = useState <number> (1);
    const [formatPhoneNumber, setFormatPhoneNumber] = useState <number> (1);
    const [keepInputDataFormat, setKeepInputDataFormat] = useState <boolean> (true);
    const [fileFormat, setFileFormat] = useState <string> ('xlsx');

    useEffect(() => {
        const inputDataOption = JSON.parse(sessionStorage.getItem("inputDataOption") as string)
        const excludeNoMatch = JSON.parse(sessionStorage.getItem("excludeNoMatch") as string)
        const excludeCleanStopfile = JSON.parse(sessionStorage.getItem("excludeCleanStopfile") as string)
        const excludeCleanLowIncome = JSON.parse(sessionStorage.getItem("excludeCleanLowIncome") as string)
        const excludeCleanNix = JSON.parse(sessionStorage.getItem("excludeCleanNix") as string)
        const excludeCleanHlr = JSON.parse(sessionStorage.getItem("excludeCleanHlr") as string)
        const formatPersonalId = JSON.parse(localStorage.getItem("formatPersonalId") as string)
        const formatBirthDate = JSON.parse(localStorage.getItem("formatBirthDate") as string)
        const formatPhoneNumber = JSON.parse(localStorage.getItem("formatPhoneNumber") as string)
        const keepInputDataFormat = JSON.parse(sessionStorage.getItem("keepInputDataFormat") as string)
        const fileFormat = JSON.parse(sessionStorage.getItem("fileFormat") as string)
        setInputDataOption(inputDataOption ?? 2)
        setExcludeNoMatch(excludeNoMatch ?? false)
        setExcludeCleanStopfile(excludeCleanStopfile ?? false)
        setExcludeCleanLowIncome(excludeCleanLowIncome ?? false)
        setExcludeCleanNix(excludeCleanNix ?? false)
        setExcludeCleanHlr(excludeCleanHlr ?? false)
        setFormatPersonalId(formatPersonalId ?? 1)
        setFormatBirthDate(formatBirthDate ?? 1)
        setFormatPhoneNumber(formatPhoneNumber ?? 1)
        setKeepInputDataFormat(keepInputDataFormat ?? true)
        setFileFormat(fileFormat ?? "xlsx")
    }, [])

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionDownload = () => {
        const queryParams = [
            'input_data_option='+inputDataOption,
            'exclude_no_match='+excludeNoMatch,
            'exclude_clean_stopfile='+excludeCleanStopfile,
            'exclude_clean_low_income='+ (populateFileObj.params.clean_low_income === 2 ? true : excludeCleanLowIncome),
            'exclude_clean_nix='+ (populateFileObj.params.clean_nix === 2 ? true : excludeCleanNix),
            'exclude_clean_hlr='+ (populateFileObj.params.clean_hlr === 2 ? true : excludeCleanHlr),
            'format_personal_id='+formatPersonalId,
            'format_birth_date='+formatBirthDate,
            'format_phone_number='+formatPhoneNumber,
            'keep_input_data_format='+keepInputDataFormat,
            'file_format='+fileFormat
        ];

        if (useAdminUrl) {
            throw new Error("Admin download url not implemented");
        } else {
            const url = API_URL+'/populate_file/'+populateFileObj.id+'/download?'+queryParams.join('&');
            window.open(url);
        }
    }

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const styleInfoItem = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
    } as React.CSSProperties;

    const renderTitle = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Namn:</Box>
                <Box>{populateFileObj.params.name}</Box>
            </Box>
        );
    }

    const renderInputDataOption = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Orginaldata:</Box>
                <Box>
                    <Select
                        value={inputDataOption}
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setInputDataOption(parseInt(event.target.value));
                            } else {
                                setInputDataOption(event.target.value);
                            }
                            sessionStorage.setItem("inputDataOption", JSON.stringify(event.target.value))
                        }}>

                        <MenuItem
                            key={1}
                            value={1}>
                                {'Ta bort'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'Inkludera'}
                        </MenuItem>
                        <MenuItem
                            key={3}
                            value={3}>
                                {'Sätt in ny data, skriv över orginal'}
                        </MenuItem>
                        <MenuItem
                            key={4}
                            value={4}>
                                {'Sätt in ny data, behåll orginal'}
                        </MenuItem>

                    </Select>
                </Box>
            </Box>
        );
    }
    
    const renderExcludeNoMatch = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Exkludera ej matchade</Box>
                <Box>
                    <Checkbox
                        checked={excludeNoMatch}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeNoMatch(event.target.checked);
                            sessionStorage.setItem("excludeNoMatch", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderExcludeCleanStopfile = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Exkludera tvättade, stopfil</Box>
                <Box>
                    <Checkbox
                        checked={excludeCleanStopfile}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeCleanStopfile(event.target.checked);
                            sessionStorage.setItem("excludeCleanStopfile", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderExcludeCleanLowIncome = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Exkludera tvättade, betalningsförmåga</Box>
                <Box>
                    <Checkbox
                        checked={excludeCleanLowIncome}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeCleanLowIncome(event.target.checked);
                            sessionStorage.setItem("excludeCleanLowIncome", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderExcludeCleanNix = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Exkludera tvättade, NIX</Box>
                <Box>
                    <Checkbox
                        checked={excludeCleanNix}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeCleanNix(event.target.checked);
                            sessionStorage.setItem("excludeCleanNix", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderExcludeCleanHlr = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Exkludera tvättade, HLR</Box>
                <Box>
                    <Checkbox
                        checked={excludeCleanHlr}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeCleanHlr(event.target.checked);
                            sessionStorage.setItem("excludeCleanHlr", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderFormatPersonalId = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Format, personnummer</Box>
                <Box>
                    <Select
                        value={formatPersonalId}
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setFormatPersonalId(parseInt(event.target.value));
                            } else {
                                setFormatPersonalId(event.target.value);
                            }
                            localStorage.setItem("formatPersonalId", JSON.stringify(event.target.value))
                        }}>
                            
                        <MenuItem
                            key={1}
                            value={1}>
                                {'ÅÅÅÅMMDDxxxx'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'ÅÅÅÅMMDD-xxxx'}
                        </MenuItem>
                        <MenuItem
                            key={3}
                            value={3}>
                                {'ÅÅMMDDxxxx'}
                        </MenuItem>
                        <MenuItem
                            key={4}
                            value={4}>
                                {'ÅÅMMDD-xxxx'}
                        </MenuItem>

                    </Select>
                </Box>
            </Box>
        );
    }

    const renderFormatBirthDate = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Format, födelsedatum</Box>
                <Box>
                    <Select
                        value={formatBirthDate}
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setFormatBirthDate(parseInt(event.target.value));
                            } else {
                                setFormatBirthDate(event.target.value);
                            }
                            localStorage.setItem("formatBirthDate", JSON.stringify(event.target.value))
                        }}>

                        <MenuItem
                            key={1}
                            value={1}>
                                {'ÅÅÅÅMMDD'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'ÅÅMMDD'}
                        </MenuItem>
                        <MenuItem
                            key={3}
                            value={3}>
                                {'ÅÅÅÅ-MM-DD'}
                        </MenuItem>
                        <MenuItem
                            key={4}
                            value={4}>
                                {'ÅÅ-MM-DD'}
                        </MenuItem>
                        <MenuItem
                            key={5}
                            value={5}>
                                {'ÅÅÅÅ/MM/DD'}
                        </MenuItem>
                        <MenuItem
                            key={6}
                            value={6}>
                                {'ÅÅ/MM/DD'}
                        </MenuItem>

                    </Select>
                </Box>
            </Box>
        );
    }

    const renderFormatPhoneNumber = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Format, telefonnummer</Box>
                <Box>
                    <Select
                        value={formatPhoneNumber}
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setFormatPhoneNumber(parseInt(event.target.value));
                            } else {
                                setFormatPhoneNumber(event.target.value);
                            }
                            localStorage.setItem("formatPhoneNumber", JSON.stringify(event.target.value))
                        }}>
                        <MenuItem
                            key={1}
                            value={1}>
                                {'076xxxxxxx'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'4676xxxxxxx'}
                        </MenuItem>
                        <MenuItem
                            key={3}
                            value={3}>
                                {'+4676xxxxxxx'}
                        </MenuItem>
                        <MenuItem
                            key={4}
                            value={4}>
                                {'004676xxxxxxx'}
                        </MenuItem>

                    </Select>
                </Box>
            </Box>
        );
    }

    const renderKeepInputDataFormat = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Behåll format på orginaldata</Box>
                <Box>
                    <Checkbox
                        checked={keepInputDataFormat}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setKeepInputDataFormat(event.target.checked);
                            sessionStorage.setItem("keepInputDataFormat", JSON.stringify(event.target.checked))
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderFileFormat = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>Filformat</Box>
                <Box>
                    <Select
                        value={fileFormat}
                        onChange={(event) => {
                            if (typeof event.target.value === 'string') {
                                setFileFormat(event.target.value);
                            }
                            sessionStorage.setItem("fileFormat", JSON.stringify(event.target.value))
                        }}>
                        <MenuItem
                            key={1}
                            value={'xlsx'}>
                                {'xlsx'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={'csv'}>
                                {'csv'}
                        </MenuItem>
                    </Select>
                </Box>
            </Box>
        );
    }

    const renderButtonDownload = () : JSX.Element => {
        return (
            <Box style={styleInfoItem}>
                <Box>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={(event) => {
                            event.preventDefault();
                            actionDownload();
                        }}>
                            Ladda ner
                    </Button>
                </Box>
            </Box>
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '528px',
        height: '828px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        width={'500px'}
                        padding={'1.5rem 14px 14px 14px'}>
                        {renderTitle()}
                        {renderInputDataOption()}
                        {renderKeepInputDataFormat()}
                        {renderFileFormat()}
                        <Accordion style={{outline: "none", boxShadow: "none", border: "none", position: "static", margin: "0rem -0.5rem 0 -0.5rem"}} variant='elevation'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>Advancerat</AccordionSummary>
                            <AccordionDetails>
                                {renderExcludeNoMatch()}
                                {renderExcludeCleanStopfile()}
                                {
                                    populateFileObj.params.clean_low_income === ApiObjPopulateFile.CLEAN_SETTING_MARK && (
                                        <>{renderExcludeCleanLowIncome()}</>
                                    )
                                }
                                {
                                    populateFileObj.params.clean_nix === ApiObjPopulateFile.CLEAN_SETTING_MARK && (
                                        <>{renderExcludeCleanNix()}</>
                                    )
                                }
                                {
                                    populateFileObj.params.clean_hlr === ApiObjPopulateFile.CLEAN_SETTING_MARK && (
                                        <>{renderExcludeCleanHlr()}</>
                                    )
                                }
                                {renderFormatPersonalId()}
                                {renderFormatBirthDate()}
                                {renderFormatPhoneNumber()}
                            </AccordionDetails>
                        </Accordion>
                        {renderButtonDownload()}
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}