import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_create = async (file: any) : Promise<ApiObjPopulateFile> => {
    
    const url = API_URL+'/populate_file';

    const fd = new FormData();
    fd.append('file', new Blob([file], { type: 'text/csv' }), file.name);
    
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true,
        headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data;`
        }
    }

    const resp = await Axios.post(
        url,
        fd,
        config
    );
        
    return new ApiObjPopulateFile(resp.data);
}
