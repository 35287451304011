import { ApiObjFilter } from '../../api/object/filter/ApiObjFilter';

export class FilterTreeMiniItem {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    filter: ApiObjFilter;
    children: Array<FilterTreeMiniItem>;

    // ==========================================================================
    // === Create
    // ==========================================================================

    constructor(filter: ApiObjFilter, children : Array<FilterTreeMiniItem>) {
        this.filter = filter;
        this.children = children;
    }
}