import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserPrice } from '../../../../object/admin/ApiObjAdminUserPrice';

export const api_admin_user_save_prices = async (userId: number, prices: Array<{price_object_id: number, price: number}>) : Promise<Array<ApiObjAdminUserPrice>> => {

    const url = API_URL+'/admin/user/'+userId+'/price';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    
    const resp = await Axios.post(
        url,
        prices,
        config
    );
        
    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserPrice(item));
    }
    return ret;
}