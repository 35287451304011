export class ApiObjFacebookTargetGroup {

    // ==============================================================
    // === Fields
    // ==============================================================

    id: number;
    user_id: number;
    is_auto_update: boolean;
    state: number;
    ad_account_ids: Array<string>;
    created_at: Date;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);
        this.is_auto_update = parseInt(data.is_auto_update) === 1;
        this.state = parseInt(data.state);
        this.ad_account_ids = data.ad_account_ids;
        this.created_at = new Date(data.created_at);
    }
}