export class ApiObjStopfilePreview {

    // ==============================================================
    // === Constants
    // ==============================================================

    public static readonly COLUMN_TYPE_0_NONE = 0;
    public static readonly COLUMN_TYPE_1_PERSONAL_ID = 1;
    public static readonly COLUMN_TYPE_2_PHONE_MOBILE = 2;
    public static readonly COLUMN_TYPE_3_PHONE_FIXED = 3;

    // ==============================================================
    // === Fields
    // ==============================================================
    
    column_types: Array<number>;
    rows: Array<Array<string>>;
    

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.column_types = [];
        for (const colType of data.column_types) {
            this.column_types.push(parseInt(colType));
        }
        this.rows = data.rows;
    }
}