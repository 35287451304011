import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjLinkOrderData } from '../../object/link/ApiObjLinkOrderData';

export const api_get_link_order_data = async (code: string) : Promise<ApiObjLinkOrderData> => {
    
    const url = API_URL+'/link/order_data/'+code;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjLinkOrderData(resp.data);
}
