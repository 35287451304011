import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';

const CustomersTableComponent = ({ customers, editUser }) => {

    // ==========================================================
    // === Table data
    // ==========================================================

    const columns = [

        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            editable: false,
        },
        {
            field: 'first_name',
            headerName: 'Förnamn',
            width: 150,
            editable: false,
        },
        {
            field: 'last_name',
            headerName: 'Efternamn',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'price',
            headerName: 'Pris',
            width: 80,
            editable: false,
        },
        {
            field: 'invoice',
            headerName: 'Faktura',
            width: 80,
            editable: false,
        },
        {
            field: 'subscription',
            headerName: 'Abonnemang',
            width: 120,
            editable: false,
        },
        {
            field: 'max_leads',
            headerName: 'Max leads',
            width: 100,
            editable: false,
        },
        {
            field: 'total_leads',
            headerName: 'Total leads',
            width: 100,
            editable: false,
        },
    ];

    const getRows = () => {
        const ret = [];

        for (const item of customers) {

            let subscriptionType = 'Ingen';

            switch (item[5]) {
                case 0: subscriptionType = 'Ingen'; break;
                case 1: subscriptionType = 'Månad'; break;
                case 2: subscriptionType = 'År'; break;
                case 3: subscriptionType = 'Kvartal'; break;
            }

            ret.push({
                id: item[8],        // Real crappy data structure on api response :)
                first_name: item[0],
                last_name: item[1],
                email: item[2],
                price: item[3],
                invoice: (item[4]) ? 'Ja' : 'Nej',
                subscription: subscriptionType,
                max_leads: item[6],
                total_leads: item[7],
            });
        }
        return ret;
    }

    return (
        <Paper style={{height: '670px'}}>
            <DataGrid
                rows={getRows()}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
                disableSelectionOnClick
                isRowSelectable={() => {return false;}}
            />
        </Paper>
    );
};

export default CustomersTableComponent;
