import { combineReducers, createStore } from 'redux';
import SelectionDataReducer from './SelectionDataReducer';
import UsersReducer from './UsersReducer';

// TODO ERIK rensa bort det som inte används. Målet är allt!

export default createStore(
  combineReducers({
    SelectionDataReducer,
    UsersReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
