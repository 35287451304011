import { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Button, Box, IconButton } from '@mui/material';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { API_URL } from '../../../../constants/Constants';
import { api_get_exports } from '../../../../api/endpoint/export/api_get_exports';
import { ApiObjStopfile } from '../../../../api/object/stopfile/ApiObjStopfile';
import { ApiObjExport } from '../../../../api/object/export/ApiObjExport';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { api_get_stopfiles } from '../../../../api/endpoint/stopfile/api_get_stopfiles';
import { ExportInfoModal } from '../../../shared_components/ExportInfoModal';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { FacebookTargetGroupModal } from '../../../shared_components/FacebookTargetGroupModal';
import { util_format_datetime } from '../../../../util/format/util_format';

type Props = {
    search: string
    baseData: ApiObjBaseData,
    userData: UserData,
    selectedDate: dayjs.Dayjs | null
    orderManager: OrderManager,
}

export const ExportList: FunctionComponent <Props> = ({
    search,
    baseData,
    userData,
    selectedDate,
    orderManager
}) => {
  
    const [stopfileObjs, setStopfileObjs] = useState <undefined|Array<ApiObjStopfile>> (undefined);
    const [exportObjs, setExportObjs] = useState <undefined|Array<ApiObjExport>> (undefined);
    const [infoModalExportObj, setInfoModalExportObj] = useState <undefined|ApiObjExport> (undefined);
    const [facebookModalExportObj, setFacebookModalExportObj] = useState <undefined|ApiObjExport> (undefined);
    const navigate = useNavigate();

    useEffect(() => {
        fetchExports();
        fetchStopfiles();
    }, []);
  
    const fetchExports = async () => {
        api_get_exports().then((resp) => {
            setExportObjs(resp);
        });
    }
    const fetchStopfiles = async () => {
        api_get_stopfiles().then((resp) => {
            setStopfileObjs(resp);
        });
    }

    const actionPopulateOrder = (id: number) => {
        if (exportObjs === undefined) {
            console.error("Exports not loaded.");
            return;
        }
        let exportObj = undefined;
        for (const item of exportObjs) {
            if (item.id === id) {
                exportObj = item;
            }
        }
        if (exportObj === undefined) {
            console.error("Unexpected, export not found here.");
            return;
        }
        if (stopfileObjs === undefined) {
            console.error("Unexpected, stopfiles not loaded here.");
            return;
        }
        orderManager.actionPopulateFromExport(exportObj, stopfileObjs);
        navigate('/order_leads/1');
    }

    const actionOpenModal = (exportId: number) => {

        if (exportObjs === undefined) {
            return;
        }

        let foundExport = undefined;
        for (const item of exportObjs) {
            if (item.id === exportId) {
                foundExport = item;
                break;
            }
        }
        setInfoModalExportObj(foundExport);
    }

    const actionOpenFacebookModal = (exportId: number) => {
        if (exportObjs === undefined) {
            return;
        }

        let foundExport = undefined;
        for (const item of exportObjs) {
            if (item.id === exportId) {
                foundExport = item;
                break;
            }
        }
        setFacebookModalExportObj(foundExport);
    }
    
    const columns = [

        {
            field: 'name',
            headerName: 'Namn',
            width: 250,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Datum',
            width: 200,
            editable: false,
        },
        {
            field: 'count',
            headerName: 'Antal',
            width: 120,
            editable: false,
        },
        {
            field: 'details',
            headerName: 'Detaljer',
            width: 100,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                
                const exportId = params.row.id;
                
                return (
                    <Box>
                        <IconButton
                            color={'secondary'}
                            onClick={(e) => {
                                actionOpenModal(exportId);
                            }}>
                                <ArticleIcon />
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 100,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
        },
        {
            field: 'download',
            headerName: 'Val',
            width: 450,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {

                const downloadDisabled = params.row.download === -1;
                const url = API_URL+'/export/'+params.row.download+'/download';

                let facebookButton = undefined;

                if (userData.isFacebookEnabled()) {
                    facebookButton = (
                        <Button
                            variant={'contained'}
                            disabled={stopfileObjs === undefined}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            onClick={(e) => {
                                e.preventDefault();
                                actionOpenFacebookModal(params.row.id);
                            }}>
                                {'Dela till Meta'}
                        </Button>
                    );
                }

                return (
                    <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
                        <Button
                            disabled={downloadDisabled}
                            variant={'contained'}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            startIcon={<CloudDownload />}
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(url);
                            }}>
                                {'Ladda ned'}
                        </Button>
                        <Button
                            variant={'contained'}
                            disabled={stopfileObjs === undefined}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            onClick={(e) => {
                                e.preventDefault();
                                actionPopulateOrder(params.row.id)
                            }}>
                                {'Beställ igen'}
                        </Button>
                        {facebookButton}
                    </Box>
                );
            }
        },
    ];

    const getRows = () => {

        if (exportObjs === undefined) {
            return [];
        }

        const ret = [];

        const nameSearchList = [...exportObjs].filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
        const dateSearchList = nameSearchList.filter((item) => dayjs(item.created_at).format("DD/MM/YYYY") === dayjs(selectedDate).format("DD/MM/YYYY"))

        for (const item of selectedDate ? dateSearchList : nameSearchList) {

            if (![5,6,7].includes(item.state)) {
                continue;
            }

            ret.push({
                id: item.id,
                name: item.name,
                date: util_format_datetime(item.created_at),
                count: item.item_count.total,
                state: item.getStateName(),
                download: (item.state == 7) ? item.id : -1,
            });
        }
        return ret;
    }

    const tryRenderModal = () : undefined|JSX.Element => {

        if (infoModalExportObj === undefined) {
            return undefined;
        }

        const actionClose = () => {
            setInfoModalExportObj(undefined);
        }

        return (
            <ExportInfoModal
                baseData={baseData}
                userData={userData}
                exportObj={infoModalExportObj}
                onClose={actionClose}
            />
        );
    }

    const tryRenderFacebookModal = () : undefined|JSX.Element => {

        if (facebookModalExportObj === undefined) {
            return undefined;
        }

        const actionClose = () => {
            setFacebookModalExportObj(undefined);
        }

        const exp = facebookModalExportObj;

        return (
            <FacebookTargetGroupModal
                target_group={undefined}
                new_target_group_data={{
                    name: exp.name,
                    source_id: exp.params.source_id,
                    nix_option_id: exp.params.nix_option_id,
                    filter_ids: exp.params.filter_ids,
                    map_areas: exp.params.map_areas,
                    export_field_ids: exp.params.export_field_ids,
                    stopfile_ids: [],           // Do not add stopfiles.
                    stopfile_days: 0,
                    household_option_id: exp.params.household_option_id
                }}
                baseData={baseData}
                userData={userData}
                onClose={actionClose}
             />
        );
    }

    return (
        <Paper style={{height: '670px'}}>
            <DataGrid
                rows={getRows()}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
                disableSelectionOnClick
                isRowSelectable={() => {return false;}}
            />
            {tryRenderModal()}
            {tryRenderFacebookModal()}
        </Paper>
    );
};
