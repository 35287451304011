import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_api_key = async (apiKeyId: number) : Promise<void> => {

    const url = API_URL+'/api_key/'+apiKeyId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    await Axios.delete(
        url,
        config
    );
}
