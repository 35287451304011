import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { UpdateMyUserData } from '../../../api/endpoint/my_user/api_update_my_user_details';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    userManager: UserManager
}

export const MyUserTabDetails: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
}) => {

    const [firstName, setFirstName] = useState <string> ('');
    const [lastName, setLastName] = useState <string> ('');
    const [companyName, setCompanyName] = useState <string> ('');
    const [companyNumber, setCompanyNumber] = useState <string> ('');
    const [address, setAddress] = useState <string> ('');
    const [zipcode, setZipcode] = useState <string> ('');
    const [city, setCity] = useState <string> ('');
    const [phoneNumber, setPhoneNumber] = useState <string> ('');
    
    const [hasData, setHasData] = useState <boolean> (false);

    // ======================================================================
    // === Set form data
    // ======================================================================

    useEffect(() => {

        if (!userData.isLoggedIn()) {
            return;
        }

        setFirstName(userData.getFirstName());
        setLastName(userData.getLastName());
        setCompanyName(userData.getCompanyName());
        setCompanyNumber(userData.getCompanyOrgNumber());
        setAddress(userData.getAddress());
        setZipcode(userData.getZipcode());
        setCity(userData.getCity());
        setPhoneNumber(userData.getPhoneNumber());

        setHasData(true);

    }, [userData]);
    
    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSave = () => {

        const data : UpdateMyUserData = {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            company_number: companyNumber,
            address: address,
            zipcode: zipcode,
            city: city,
            phone_number: phoneNumber,
        };

        userManager.actionUpdateUserData(data);
    }

    // ======================================================================
    // === Field Validation
    // ======================================================================

    const getErrorPhoneNumber = () => {
        if (phoneNumber.length === 0) {
            return "Telefonnummer krävs";
        } else if (phoneNumber.length < 8) {
            return "Telefonnummer för kort";
        } else if (phoneNumber.length > 14) {
            return "Telefonnummer för långt";
        }
        return undefined;
    }

    const getErrorFirstName = () => {
        if (firstName.length > 50) {
            return "Förnamn är för långt";
        }
        return undefined;
    }

    const getErrorLastName = () => {
        if (lastName.length > 50) {
            return "Efternamn är för långt";
        }
        return undefined;
    }

    const getErrorAddress = () => {
        if (address.length > 100) {
            return "Adress är för lång";
        }
        return undefined;
    }

    const getErrorZipcode = () => {
        if (zipcode.length > 0) {
            const zipCodeInt = parseInt(zipcode);
            const isValid = zipcode.length === 5
                && !isNaN(zipCodeInt)
                && zipCodeInt >= 10000
                && zipCodeInt <= 99999;
            if (!isValid) {
                return "Ogiltigt postnummer";
            }
        }
        return undefined;
    }

    const getErrorCity = () => {
        if (city.length > 100) {
            return "Namn är för långt.";
        }
        return undefined;
    }

    const getErrorCompanyName = () => {
        if (companyName.length > 50) {
            return "Företagsnamn är för långt."
        }
        return undefined;
    }

    const getErrorCompanyNumber = () => {
        if (companyNumber.length > 50) {
            return "Organisationsnummer är för långt";
        }
        return undefined;
    }

    // ======================================================================
    // === Checks
    // ======================================================================

    const isSavePossible = () : boolean => {
        return (
            isAllValuesValid()
            && userData.isLoggedIn()
            && userData.isIdle()
        )
    }

    const isSaving = () : boolean => {
        return (
            !userData.isIdle()
        )
    }

    const isAllValuesValid  = () => {

        const allCheckerFunctions = [
            getErrorPhoneNumber,
            getErrorFirstName,
            getErrorLastName,
            getErrorAddress,
            getErrorZipcode,
            getErrorCity,
            getErrorCompanyName,
            getErrorCompanyNumber,
        ];

        for (const item of allCheckerFunctions) {
            if (item() !== undefined) {
                return false;
            }
        }
        return true;
    }

    // ======================================================================
    // === Render elements
    // ======================================================================

    const styleFormItemBox = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px',
        alignItems: 'start',
        gap: '12px'
    } as React.CSSProperties;

    const renderPhoneNumber = () : JSX.Element => {

        const errorText = getErrorPhoneNumber();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Telefonnummer'}
                    required
                    disabled={isSaving()}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    const renderName = () : JSX.Element => {

        const errorTextFirst = getErrorFirstName();
        const errorTextLast = getErrorLastName();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Förnamn'}
                    disabled={isSaving()}
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                    error={errorTextFirst !== undefined}
                    helperText={errorTextFirst}
                    style = {{width: '300px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Efternamn'}
                    disabled={isSaving()}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                    error={errorTextLast !== undefined}
                    helperText={errorTextLast}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderAddress = () : JSX.Element => {

        const errorText = getErrorAddress();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Adress'}
                    disabled={isSaving()}
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value)
                    }}
                    error={errorText !== undefined}
                    helperText={errorText}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderZipAndCity = () : JSX.Element => {

        const errorTextZip = getErrorZipcode();
        const errorTextCity = getErrorCity();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Postnummer'}
                    disabled={isSaving()}
                    value={zipcode}
                    onChange={(e) => {
                        setZipcode(e.target.value)
                    }}
                    error={errorTextZip !== undefined}
                    helperText={errorTextZip}
                    style = {{width: '150px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Ort'}
                    disabled={isSaving()}
                    value={city}
                    onChange={(e) => {
                        setCity(e.target.value)
                    }}
                    error={errorTextCity !== undefined}
                    helperText={errorTextCity}
                    style = {{width: '300px'}}
                />
            </Box>
        );
    }

    const renderCompany = () : JSX.Element => {

        const errorTextName = getErrorCompanyName();
        const errorTextNumber = getErrorCompanyNumber();

        return (
            <Box style={styleFormItemBox}>
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Företagsnamn'}
                    disabled={isSaving()}
                    value={companyName}
                    onChange={(e) => {
                        setCompanyName(e.target.value)
                    }}
                    error={errorTextName !== undefined}
                    helperText={errorTextName}
                    style = {{width: '300px'}}
                />
                <TextField
                    variant="outlined"
                    size={'medium'}
                    label={'Organisationsnummer'}
                    disabled={isSaving()}
                    value={companyNumber}
                    onChange={(e) => {
                        setCompanyNumber(e.target.value)
                    }}
                    error={errorTextNumber !== undefined}
                    helperText={errorTextNumber}
                    style = {{width: '200px'}}
                />
            </Box>
        );
    }

    if (!hasData) {
        return (
            <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
                    <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box
                component="form"
                noValidate
                autoComplete="off">

                {renderName()}
                {renderCompany()}
                {renderPhoneNumber()}
                {renderAddress()}
                {renderZipAndCity()}

            </Box>
            <Box paddingTop={'25px'} paddingBottom={'10px'}>
                <Button
                    variant='contained'
                    color='secondary'
                    size={'large'}
                    onClick={actionSave}
                    disabled={!isSavePossible()}>
                        Spara
                </Button>
            </Box>
        </Box>
    );
}
