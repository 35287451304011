import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_change_my_user_password = async (currentPassword: string, newPassword: string) : Promise<void> => {

    const url = API_URL+'/my_user/password/change'
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const data = {
        old_password: currentPassword,
        new_password: newPassword
    };
    await Axios.put(
        url,
        data,
        config
    );
}
