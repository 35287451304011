import { Paper, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

export const ErrorPageNoAdmin: FunctionComponent = () => {

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Paper style={{padding: '20px'}}>
            <Typography align='center'>Du behöver adminrättigheter för att se denna sidan.</Typography>
        </Paper>
    );
}
