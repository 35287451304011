import { ApiObjBaseData } from "../../api/object/base_data/ApiObjBaseData";
import { UtilArray } from "../../util/code/UtilArray";
import { UserData } from "../user/UserData";

export const clean_filters = (filterIds: Array<number>, baseData: ApiObjBaseData, userData: UserData) : Array<number> => {

    const cleanFilterIds = [];
    for (const filterId of filterIds) {
        const tempFilter = baseData.filter_map.get(filterId);
        if (tempFilter !== undefined && tempFilter.isSelectable()) {
            if (
                !userData.isFilterEnabledForExport(tempFilter)
                && !userData.isFilterEnabledForMarketing(tempFilter)
            ) {
                continue;
            }
            cleanFilterIds.push(filterId);
        }
    }
    return UtilArray.createUniqueSortedIntArray(cleanFilterIds);
}