import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_get_stopfile = async (stopfileId: number) : Promise<ApiObjStopfile> => {
    
    const url = API_URL+'/stopfile/'+stopfileId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjStopfile(resp.data);
}