import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import TextFieldComponent from '../../../components/TextFieldComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import makeT from '../../../translations/t.js';
import { useIntl } from 'react-intl';
import { errorMaxDescription, required } from '../../../functions/validationFunctions';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { POST } from '../../../functions/axiosSending';
import { USER_PASSWORD } from '../../../ApiRoutes';
import { useSnackbar } from 'notistack';

export const ResetPasswordPage = () => {
  const intl = useIntl();
  const T = makeT(intl);
  const [repeatPassword, setRepeatPassword] = useState(false);
  const [token, setToken] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();

  const action = (key) => (
    <Button
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      {'Ok'}
    </Button>
  );
  const getValidationSchema = (values) => {
    return Yup.object().shape({
      password: Yup.string()
        .min(6, T('short_password', 'form_validation'))
        .required(required(T, T('password', 'form_validation'), 'e'))
        .max(128, errorMaxDescription(T, T('password', 'form_validation')))
    });
  };
  useEffect(() => {
    const url = window.location.href.toString();
    setToken(url.substring(url.lastIndexOf('token=') + 6));
  }, []);
  return (

    <Box display="flex" alignItems="center" justifyContent="center">

      <Box mr={5} className="fixed-element padding-large-top">
          <Typography variant="h4">{'Skapa nytt lösenord'}</Typography>
          <Box mt={2}>
            <Typography variant="h6" className="light-text">
              {'Skriv in ett nytt lösenord för ditt konto och klicka på "Skapa nytt lösenord"'}
            </Typography>
          </Box>
      </Box>

      <Paper style={{padding: '20px', width: '400px'}}>
      <Formik
        initialValues={{ repeatPassword: '', password: '' }}
        validationSchema={getValidationSchema('values')}
        onSubmit={(values, { setSubmitting }) => {
          setSubmit(true);
          POST(USER_PASSWORD, {
            password: values.password,
            token: token
          })
            .then((success) => navigate('/account/reset_password/success'))
            .catch((error) => {
              setSubmit(false);
              enqueueSnackbar(T('reset_password_error', 'errors'), {
                action,
                variant: 'error'
              });
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} className="login-form">
            <TextFieldComponent
              formikProps={formikProps}
              id="password"
              type="password"
              label={T('new_password_input', 'forgot_password')}
              fullWidth
            />
            <TextFieldComponent
              formikProps={formikProps}
              id="repeatPassword"
              type="password"
              label={T('repeat_password', 'register')}
              onBlur={() => setRepeatPassword(formikProps.values['password'] !== formikProps.values['repeatPassword'])}
              fullWidth
              customHelperText={repeatPassword ? T('do_not_agree_with_password', 'form_validation') : ''}
            />
            <Box mt={2}>
              <ButtonComponent
                type="submit"
                onClick={formikProps.handleSubmit}
                disabled={submit}
                title={T('create_new_passowrd', 'buttons')}
              />
            </Box>
          </form>
        )}
      </Formik>
      </Paper>
    </Box>
  );
};
