import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { UtilArray } from '../../../util/code/UtilArray';
import { ApiObjCountFilters } from '../../object/filter/ApiObjCountFilters';

type CountFiltersParams = {
    filter_ids_selected: Array<number>,
    filter_ids_to_check: Array<number>,
    nix_option_id: number,
    source_id: number,
    map_areas: Array<MapAreaCircle|MapAreaPolygon>,
}

export const api_get_filter_count_filter = async (params: CountFiltersParams) : Promise<ApiObjCountFilters> => {

    const outFilterIdsSelected = [...params.filter_ids_selected];
    const outNixOptionId = params.nix_option_id;
    const outSourceId = params.source_id;
    let tempArray = [];
    for (const item of params.map_areas) {
        tempArray.push(item.id);
    }
    tempArray = UtilArray.createUniqueSortedIntArray(tempArray);
    const outAreaIds = tempArray;

    const url = API_URL+'/data/count-filters';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const payloadMapAreas = [];
    for (const mapArea of params.map_areas) {
        if (mapArea instanceof MapAreaCircle) {
            payloadMapAreas.push({
                type: 'circle',
                center_lat: mapArea.centerPoint.latitude,
                center_long: mapArea.centerPoint.longitude,
                radius: mapArea.radius
            });

        } else if (mapArea instanceof MapAreaPolygon) {
            const tempPoints = [];
            for (const point of mapArea.points) {
                tempPoints.push({
                    lat: point.latitude,
                    long: point.longitude,
                });
            }
            payloadMapAreas.push({
                type: 'polygon',
                points: tempPoints
            });
        } else {
            throw new Error("Unexpected map area type");
        }
    }

    const data = {
        nix_option_id: params.nix_option_id,
        source_id: params.source_id,
        filter_ids_selected: params.filter_ids_selected,
        filter_ids_to_check:  params.filter_ids_to_check,
        map_areas: payloadMapAreas
    }
    const resp = await Axios.post(url, data, config);
        
    const items = [];
    for (const item of resp.data) {
        items.push(
            {
                filter_id: parseInt(item.filter_id),
                count: parseInt(item.count)
            }
        );
    }
    return {
        param_selected_filter_ids: outFilterIdsSelected,
        param_nix_option_id: outNixOptionId,
        param_source_id: outSourceId,
        param_map_area_ids: outAreaIds,
        result: items
    };
}
