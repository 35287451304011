import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';
import { api_populate_file_order_get_step_4 } from '../../../api/endpoint/populate_file/api_populate_file_order_get_step_4';
import { api_populate_file_order_post_step_4 } from '../../../api/endpoint/populate_file/api_populate_file_order_post_step_4';
import { ApiObjPopulateFileOrderStep4 } from '../../../api/object/populate_file/ApiObjPopulateFileOrderStep4';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import InjectedCheckoutForm from '../order_leads/components/CheckoutForm';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    populateFile: ApiObjPopulateFile
    parentSetPopulateFile: (populateFile: ApiObjPopulateFile) => void
}

export const OrderStep4Payment: FunctionComponent <Props> = ({
    baseData,
    userData,
    populateFile,
    parentSetPopulateFile
}) => {

    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>()
    const [orderStep4Obj, setOrderStep4Obj] = useState <undefined|ApiObjPopulateFileOrderStep4> (undefined);
    const [isStripePaymentComplete, setIsStripePaymentComplete] = useState(false)
    const [formPaymentMethod, setFormPaymentMethod] = useState <number> (2);
    const [stripePublicKey, setStripePublicKey] = useState <string|undefined> (undefined);
    const [stripeClientSecret, setStripeClientSecret] = useState <string|undefined> (undefined);

    const navigate = useNavigate();

    // TODO ERIK ändra till default payment method subscription om användaren har subscription.

    useEffect(() => {

        api_populate_file_order_get_step_4(populateFile.id, formPaymentMethod === 2)
            .then((result) => {
                setOrderStep4Obj(result);
                setIsLoading(false);

            }).catch((err) => {
                // TODO feedback till användaren.
            });
    }, []);

    useEffect(() => {
        if(populateFile.state === ApiObjPopulateFile.STATE_13_READY) navigate(`/my_files?key=2`)
    }, [populateFile])

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSetPaymentMethod = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            setFormPaymentMethod(newValue);
            setIsLoading(true);
            api_populate_file_order_get_step_4(populateFile.id, newValue === 2)
                .then((result) => {
                    setIsLoading(false);
                    setOrderStep4Obj(result);
                }).catch((err) => {
                    console.error(err);
                });
        }
    }

    const actionNext = () => {
        if (isLoading || isSaving) {
            return;
        }
        setIsSaving(true);
        let priceTotalCheck = 0;
        if (orderStep4Obj !== undefined) {
            priceTotalCheck = orderStep4Obj.price.total;
        }
        api_populate_file_order_post_step_4(populateFile.id, formPaymentMethod, priceTotalCheck)
            .then((result) => {
                if (result.payment_method === 1) {
                    setIsSaving(false);
                    parentSetPopulateFile(result.populate_file);
                    console.log("Save sucessful");
                } else if (result.payment_method === 2) {
                    setIsSaving(false)
                    parentSetPopulateFile(result.populate_file);
                    setStripePublicKey(result.stripe_public_key);
                    setStripeClientSecret(result.stripe_client_secret);
                } else {
                    setErrorMsg("Unexpected case.")
                    console.log("Unexpected case.");
                }
            }).catch((err) => {
                setIsSaving(false);
                setErrorMsg(err.response.data.message)
            });
    }
    
    const actionStripeSubmit = (paymentIntent: any) => {
        if (paymentIntent && paymentIntent.status === 'succeeded') {
            setIsSaving(false);
            setIsStripePaymentComplete(true)
            console.log("Stripe Successful");
        } else {
            setIsSaving(false);
            setErrorMsg("Stripe Payment failed")
            console.log("Stripe Payment failed");
        }
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderPriceDetails = () : JSX.Element|undefined => {

        if (orderStep4Obj === undefined || populateFile.state === ApiObjPopulateFile.STATE_13_READY) {
            return undefined;
        }

        return (
            <Box>
                {
                    orderStep4Obj.price.spec_items.map((item) => (
                        <Box key={item.price_object.id} paddingY="0.25rem" display="flex" alignItems="center" justifyContent="space-between" width="35rem"><Typography>{item.price_object.name} ({item.items_count}):</Typography> <Typography>{item.price_total.toFixed(2)} kr</Typography></Box>
                    ))
                }
                <Box paddingY="0.25rem" display="flex" alignItems="center" justifyContent="space-between" width="35rem"><Typography>Pris totalt exkl moms:</Typography> <Typography>{orderStep4Obj.price.excl_vat.toFixed(2)} kr</Typography></Box>
                <Box paddingY="0.25rem" display="flex" alignItems="center" justifyContent="space-between" width="35rem"><Typography>Moms:</Typography> <Box style={{fontSize: "20px", fontWeight: "bold"}}>{orderStep4Obj.price.vat.toFixed(2)} kr</Box></Box>
                <Box paddingY="0.25rem" display="flex" alignItems="center" justifyContent="space-between" width="35rem"><Typography>Totala beloppet:</Typography> <Box style={{fontSize: "20px", fontWeight: "bold"}}>{orderStep4Obj.price.total.toFixed(2)} kr</Box></Box>
            </Box>
        );
    }

    const renderSelectPaymentMethod = () : JSX.Element|undefined => {
        
        if (orderStep4Obj === undefined ||             
            populateFile.state === ApiObjPopulateFile.STATE_10_PAYMENT_STARTED || 
            populateFile.state === ApiObjPopulateFile.STATE_13_READY || 
            populateFile.state === ApiObjPopulateFile.STATE_11_PAYMENT_FAILED || 
            populateFile.state === ApiObjPopulateFile.STATE_12_PAYMENT_COMPLETE ||
            isStripePaymentComplete
        ) {
            return undefined;
        }
        return (
            <Box mt={4}>
                <Typography variant="h6">{'Betalmetod'}</Typography>
                <Select
                    value={formPaymentMethod}
                    onChange={(event) => {actionSetPaymentMethod(event)}}
                    disabled={isLoading || isSaving}>
                        <MenuItem
                            key={1}
                            value={1}>
                                {'Prenumeration'}
                        </MenuItem>
                        <MenuItem
                            key={2}
                            value={2}>
                                {'Betalkort'}
                        </MenuItem>
                </Select>
            </Box>
        );
    }

    const renderOrderFinished = () : JSX.Element|undefined => {
        if (orderStep4Obj === undefined || populateFile.state !== ApiObjPopulateFile.STATE_13_READY) {
            return undefined;
        }
        <Box mt={4}>
            Köpet genomfört
        </Box>
    }

    const renderButtonNext = () :  JSX.Element|undefined => {

        if (orderStep4Obj === undefined || 
            populateFile.state === ApiObjPopulateFile.STATE_10_PAYMENT_STARTED || 
            populateFile.state === ApiObjPopulateFile.STATE_13_READY || 
            populateFile.state === ApiObjPopulateFile.STATE_11_PAYMENT_FAILED || 
            populateFile.state === ApiObjPopulateFile.STATE_12_PAYMENT_COMPLETE || 
            isStripePaymentComplete
        ) {
            return undefined;
        }

        const isDisabled = (
            isLoading 
            || isSaving
            || (formPaymentMethod === 1 && userData.getPopulatePotLeft() < orderStep4Obj.price.total)
        )

        return (
            <Button
                variant='contained'
                disabled={isDisabled}
                onClick={actionNext}>
                    Bekräfta
            </Button>
        );
    }

    const tryRenderProcessingOrder = () => {
        if(!isStripePaymentComplete) return undefined

        return(
            <Box display={"flex"} alignItems={"center"}>
                <CircularProgress size={"1.15rem"}/>
                <Typography marginLeft={"0.5rem"} fontWeight={"bold"} fontSize={"18px"}>Hanterar beställningen...</Typography>
            </Box>
        )
    }

    const tryCreateStripeCheckout = () => {
        const isStripeActive = stripePublicKey !== undefined && stripeClientSecret !== undefined;
        if (isStripeActive && populateFile.state === ApiObjPopulateFile.STATE_10_PAYMENT_STARTED && !isStripePaymentComplete) {
            const stripePromise = loadStripe(stripePublicKey); // Might need to live outside render.
            return (
                <Box marginTop={5}>
                    <Typography variant="h6">{'Kortbetalning'}</Typography>
                    <Box width={'600px'}>
                        <Elements stripe={stripePromise}>
                            <InjectedCheckoutForm
                                paymentIntentClientSecret={stripeClientSecret}
                                handleSubmit={actionStripeSubmit} /> 
                        </Elements>
                    </Box>
                </Box>
            );
        }
        return undefined;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <>
            {
                isLoading ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}><CircularProgress/></Box>
                ) : (
                    <Box mt={2} display={'flex'} flexDirection={'column'}>
                        <Box marginTop={'8px'}>
                            {renderPriceDetails()}
                        </Box>
                        <Box marginTop={'8px'}>
                            {renderSelectPaymentMethod()}
                            {renderOrderFinished()}
                            {tryCreateStripeCheckout()}
                        </Box>
                        <Box mt={2} style={{overflowX: 'auto'}}>
                            {renderButtonNext()}
                            {tryRenderProcessingOrder()}
                        </Box>
                        {!!errorMsg && <Typography variant="h6" marginTop="1rem" color="red">{errorMsg}</Typography>}
                    </Box>
                )
            }
        </>
    );
};
