import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdAccount } from '../../object/facebook/ApiObjAdAccount';

export type ApiParamsCreateAdAccount = {
    name: string,
    remote_id: string,
}

export const api_create_ad_account = async (data: ApiParamsCreateAdAccount) : Promise<ApiObjAdAccount> => {
    
    const url = API_URL+'/facebook/ad_account';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.post(
        url,
        data,
        config
    );
        
    return new ApiObjAdAccount(resp.data);
}
