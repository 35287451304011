import { FunctionComponent } from 'react';
import { Box } from '@mui/system';
import { TargetGroupEditData } from '../../../data_layer/target_group/TargetGroupEditData';
import { TargetGroupEditManager } from '../../../data_layer/target_group/TargetGroupEditManager';
import { Checkbox, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';

type Props = {
    stopfiles: Array<ApiObjStopfile>
    targetGroupData: TargetGroupEditData,
    targetGroupManager: TargetGroupEditManager,
}

export const TargetGroupEditStep4: FunctionComponent <Props> = ({
    stopfiles,
    targetGroupData,
    targetGroupManager,
}) => {

    // =====================================================================
    // === Actions
    // =====================================================================

    const actionCheckboxClicked = (id: number, isChecked: boolean) => {
        if (isChecked) {
            targetGroupManager.actionAddStopfiles([id]);
        } else {
            targetGroupManager.actionRemoveStopfiles([id]);
        }
    }

    const actionSelectDays = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            targetGroupManager.actionSetStopfileDays(newValue);
        }
    }

    // =====================================================================
    // === Render Elements
    // =====================================================================

    const renderSelect = () : JSX.Element => {

        const options = [
            { value: 0, name: 'Av' },
            { value: 30, name: 'upp till 30 dagar' },
            { value: 60, name: 'upp till 2 månader' },
            { value: 90, name: 'upp till 3 månader' },
            { value: 120, name: 'upp till 4 månader' },
            { value: 150, name: 'upp till 5 månader' },
            { value: 180, name: 'upp till 6 månader' },
            { value: 210, name: 'upp till 7 månader' },
            { value: 240, name: 'upp till 8 månader' },
            { value: 270, name: 'upp till 9 månader' },
            { value: 300, name: 'upp till 10 månader' },
            { value: 330, name: 'upp till 11 månader' },
            { value: 365, name: 'upp till 12 månader' },
        ];

        const menuItems = [];
        for (const op of options) {
            menuItems.push(
                <MenuItem
                    key={op.value}
                    value={op.value}>
                        {op.name}
                </MenuItem>
            )
        }

        return (
            
                <Select
                    style={{minWidth: '150px'}}
                    value={targetGroupData.stopfile_days}
                    onChange={(event) => {actionSelectDays(event)}}
                    disabled={targetGroupData.state_is_saving}>

                    {menuItems}

                </Select>
        );
    }

    const renderListElements = () : Array<JSX.Element> => {

        const fields : Array<JSX.Element> = [];

        for (const item of stopfiles) {

            const isValid = item.create_method === 'manual' && item.state === ApiObjStopfile.STATE_3_SUCCESS;
            if (!isValid) {
                continue;
            }

            const isSelected = targetGroupData.stopfile_ids.includes(item.id);

            fields.push(
                <Box display="flex" key={item.id} justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={isSelected}
                        disabled={targetGroupData.state_is_saving}
                        color={'secondary'}
                        onClick={(e) => { e.stopPropagation(); }}
                        onChange={(e) => { actionCheckboxClicked(item.id, e.target.checked) }}/>
                    <Typography>{item.name}</Typography>
                    </Box>
                </Box>
            );
        }
        return fields;
    }

    // ======================================================================
    // === Render Main
    // ======================================================================

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box>
                <Box>
                    <Typography variant='h6'>Autoval vid beställning</Typography>
                </Box>
                <Box marginTop={1}>
                    {renderSelect()}
                </Box>
            </Box>
            <Box marginTop={4}>
                <Box>
                    <Typography variant='h6'>Manuellt skapade stoppfiler</Typography>
                </Box>
                <Box marginTop={1} display={'flex'} flexDirection={'column'}>
                    {renderListElements()}
                </Box>
            </Box>
        </Box>
    );
}
