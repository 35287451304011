import { Box, Button, Checkbox } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { api_admin_user_get_sources } from '../../../api/endpoint/admin/user/source/api_admin_user_get_sources';
import { api_admin_user_save_sources } from '../../../api/endpoint/admin/user/source/api_admin_user_save_sources';
import { ApiObjSource } from '../../../api/object/source/ApiObjSource';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    otherUserId: number
}

export const AdminUserTabSources: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [mapUserSources, setMapUserSources] = useState <Map<number, number>> (new Map());
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        api_admin_user_get_sources(otherUserId)
            .then((result) => {
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item, item);
                }
                setMapUserSources(newMap);
                setIsLoading(false);
            });

    }, [otherUserId]);

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSaveSources = () => {

        setIsSaving(true);

        const sourceIds : Array<number> = [];
        mapUserSources.forEach((value: number, key: number) => {
            sourceIds.push(key);
        });

        api_admin_user_save_sources(otherUserId, sourceIds)
            .then((result) => {
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item, item);
                }
                setMapUserSources(newMap);
                setIsSaving(false);

            }).catch((err) => {
                console.error("Could not save sources");
                console.error(err);
                // Skip to set isSaving to fails to make error obvious.
            });
    }

    const actionOnCheckboxChange = (sourceId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const newMap = new Map(mapUserSources);
        if (isChecked) {
            newMap.set(sourceId, sourceId);
        } else {
            newMap.delete(sourceId);
        }
        setMapUserSources(newMap);
    }
    
    // ======================================================================
    // === Render
    // ======================================================================

    const renderList = () : JSX.Element => {

        const elements : Array<JSX.Element> = [];
        for (const item of adminBaseData.sources) {
            elements.push(renderItem(item));
        }

        return (
            <Box>
                {elements}
            </Box>
        )
    }

    const renderItem = (source: ApiObjSource) : JSX.Element => {

        const isSelected = mapUserSources.has(source.id);

        return (
            <Box
                key={source.id}
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}>
                    <Box>
                        <Checkbox
                            checked={isSelected}
                            disabled={!isActive}
                            color={'secondary'}
                            onClick={(event) => { event.stopPropagation(); }}
                            onChange={(event) => { actionOnCheckboxChange(source.id, event) }}/>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {source.name + ' (' + source.person_count+')'}
                    </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            {renderList()}
            <Box paddingTop={'15px'}>
                <Button variant='contained' color='secondary' onClick={actionSaveSources} disabled={!isActive}>
                    Spara
                </Button>
            </Box>
        </Box>
    );
}
