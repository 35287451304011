import { Box, Button, Paper, TextField, } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjPriceObjectType } from '../../../api/object/price/ApiObjPriceObjectType';
import { api_admin_get_global_prices } from '../../../api/endpoint/admin/global_prices/api_admin_get_global_prices';
import { ApiObjAdminGlobalPrice } from '../../../api/object/admin/ApiObjAdminGlobalPrice';
import { api_admin_save_global_prices } from '../../../api/endpoint/admin/global_prices/api_admin_save_global_prices';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
}

export const AdminGlobalPricesPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [priceData, setPriceData] = useState <Array<ApiObjAdminGlobalPrice>> ();
    const [mapPrices, setMapPrices] = useState <Map<number, string>> (new Map());
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {
        api_admin_get_global_prices()
            .then((result) => {
                setPriceData(result);
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item.id, item.price + '');
                }
                setMapPrices(newMap);
                setIsLoading(false);
            });
    }, []);

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSavePrices = () => {

        setIsSaving(true);

        const prices : Array<{id: number, price: number}> = [];
        mapPrices.forEach((value: string, key: number) => {

            const includeValue = !isNaN(parseFloat(value)) && parseFloat(value) >= 0;
            if (includeValue) {
                prices.push({
                    id: key,
                    price: parseFloat(value)
                });
            }
        });

        api_admin_save_global_prices(prices)
            .then((result) => {

                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item.id, item.price + '');
                }
                setMapPrices(newMap);
                setIsSaving(false);

            }).catch((err) => {
                console.error("Could not save prices");
                console.error(err);
            });
    }

    const actionOnChangePrice = (priceObjectId: number, newValue: string) => {
        const newMap = new Map(mapPrices);
        newMap.set(priceObjectId, newValue);
        setMapPrices(newMap);
    }
    
    // ======================================================================
    // === Render
    // ======================================================================

    const renderPricing = () : JSX.Element => {

        const sectionElements = [];
        for (const item of baseData.price_object_types) {
            sectionElements.push(
                renderSection(item)
            );
        }

        return (
            <Box width={'600px'}>
                {sectionElements}
            </Box>
        );
    }

    const renderSection = (priceObjectType: ApiObjPriceObjectType) : JSX.Element|undefined => {

        if (priceData === undefined) {
            return undefined;
        }

        const itemElements = [];
        for (const item of priceData) {
            if (item.object_type_id === priceObjectType.id) {
                itemElements.push(renderItem(item));
            }
        }

        return (
            <Box key={priceObjectType.id} display={'flex'} flexDirection={'column'}>
                <Box paddingTop={'6px'} paddingBottom={'6px'}  style={{fontWeight: 'bold'}}>
                    {priceObjectType.name}
                </Box>
                <Box paddingLeft={'10px'}>
                    {itemElements}
                </Box>
            </Box>
        );
    }

    const renderItem = (priceObject: ApiObjAdminGlobalPrice) : JSX.Element => {

        let value = mapPrices.get(priceObject.id);
        if (value === undefined) {
            value = '';
        }
        const isValueValid = (
            value === ''
            || (
                !isNaN(parseFloat(value))
                && parseFloat(value) >= 0
            )
        );

        return (
            <Box
                key={priceObject.id}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignContent={'center'}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {priceObject.name}
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}>

                        <Box width={'80px'} marginLeft={'12px'}>
                            <TextField
                                type={'number'}
                                disabled={!isActive}
                                error={!isValueValid}
                                fullWidth={true}
                                value={value}
                                size='small'
                                onChange={(event) => {actionOnChangePrice(priceObject.id, event.target.value)}} />
                        </Box>
                    </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box>
            <Paper>
                <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
                    <Box paddingTop={'10px'} paddingBottom={'10px'}>
                        <Button variant='contained' color='secondary' onClick={actionSavePrices} disabled={!isActive}>
                            Spara
                        </Button>
                    </Box>
                    {renderPricing()}
                    <Box paddingTop={'10px'} paddingBottom={'10px'}>
                        <Button variant='contained' color='secondary' onClick={actionSavePrices} disabled={!isActive}>
                            Spara
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
