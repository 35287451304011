
// API URL
//export const API_URL = 'http://localhost:1337/api'; // For local dev.
export const API_URL = '/api';

// COLORS
export const MAIN_COLOR = '#3a428a';    // OLD 2F3676
export const SECONDARY_COLOR = '#faa63c'; // OLD fca333

// INTERCOM
//export const INTERCOM_TAG_NEW_CUSTOMER = 'kund';
//export const INTERCOM_TAG_EXPORT_DONE = 'export';
