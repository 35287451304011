import { Box, Button, Modal, Paper, Typography, } from '@mui/material';
import React, { FunctionComponent } from 'react';

type Props = {
    title: string,
    text: string,
    onYes: () => void,
    onCancel: () => void,
}

export const ConfirmModal: FunctionComponent<Props> = ({
    title,
    text,
    onYes,
    onCancel
}) => {

    // ===========================================================
    // === Render
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        p: 4,
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={() => {onCancel()}}
        >
            <Box style={style}>
                <Paper>
                    <Box style={{padding: '12px'}}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                        <Typography style={{marginTop: '2px'}}>
                            {text}
                        </Typography>
                        <Box
                            style={{marginTop: '16px'}}
                            display={'flex'}
                            flexDirection={'row'}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='medium'
                                onClick={onYes}>
                                    Ja
                            </Button>
                            <Box marginLeft={'14px'}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    size='medium'
                                    onClick={onCancel}>
                                        Avbryt
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}