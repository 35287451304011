import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjExport } from '../../object/export/ApiObjExport';

export const api_get_export = async (exportId: number) : Promise<ApiObjExport> => {
    
    const url = API_URL+'/export/'+exportId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );

    return new ApiObjExport(resp.data);
}
