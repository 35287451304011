import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFileOrderStep1 } from '../../object/populate_file/ApiObjPopulateFileOrderStep1';

export const api_populate_file_order_get_step_1 = async (populateFileId: number) : Promise<ApiObjPopulateFileOrderStep1> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_1';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );

    return new ApiObjPopulateFileOrderStep1(resp.data);
}
