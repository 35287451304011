import { FunctionComponent } from 'react';
import { AnimatedBlockComponent } from '../../../../components/AnimatedBlockComponent';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { UserData } from '../../../../data_layer/user/UserData';
import { Box, Button, Typography, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ApiObjPriceObjectType } from '../../../../api/object/price/ApiObjPriceObjectType';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    orderData: OrderData,
    orderManager: OrderManager,
}

export const Step1NixComp: FunctionComponent <Props> = ({
    baseData,
    userData,
    orderData,
    orderManager,
}) => {

    const navigate = useNavigate();

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSetNixOptionId = (nixOptionId: number) => {
        if (isNaN(nixOptionId)) {
            throw new Error("Nix option is NaN, should never happen.");
        }
        orderManager.actionSetNixOptionId(nixOptionId);
    }

    const actionHandleSourceChange = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            orderManager.actionSetSourceId(newValue);
        }
    }

    const actionNext = () => {
        navigate('/order_leads/2');
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const tryRenderSourceElement = () => {

        if (!userData.isLoggedIn()
            || userData.getSources().length === 0) {
            return undefined;
        }

        const options = [];
        options.push(
            <MenuItem
                key={0}
                value={0}>
                    {'Standard'}
            </MenuItem>
        );

        for (const item of userData.getSources()) {

            let priceLeads = '';

            const priceObjectLeads = userData.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_SOURCE, item.id);
            if (priceObjectLeads !== undefined && priceObjectLeads.getPriceFloat() > 0) {
                priceLeads = ' ('+priceObjectLeads.getPriceStringFull()+')';
            }
            const name = item.name + priceLeads;

            options.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {name}
                </MenuItem>
            );
        }

        return (
            <Box mt={4}>
                <Typography variant="h6">{'Källa'}</Typography>
                <Select
                    value={orderData.source_id}
                    onChange={(event) => {actionHandleSourceChange(event)}}
                    disabled={!orderData.canEditOrder()}>
                        {options}
                </Select>
            </Box>
        );
    }

    const renderNixOptions = () : Array<JSX.Element> => {

        let nixOptions = baseData.nix_options;
        if (userData.isLoggedIn()) {
            nixOptions = userData.getNixOptions();
        }

        const elements = [];
        for (const item of nixOptions) {

            let priceStringLeads = '';

            if (userData.isLoggedIn()) {
                const priceObjectLeads = userData.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_NIX_OPTION, item.id);
                if (priceObjectLeads !== undefined && priceObjectLeads.getPriceFloat() > 0) {
                    priceStringLeads = priceObjectLeads.getPriceStringFull();
                }
            }

            elements.push(
                <AnimatedBlockComponent
                    key={item.id}
                    isChecked={orderData.nix_option_id === item.id}
                    title={item.name}
                    subtitle={''}
                    onClick={() => {actionSetNixOptionId(item.id)}}
                    leadsPrice={priceStringLeads}
                />
            );
        }
        return elements;
    }

    return (
        <>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="h4">{'NIX-alternativ'}</Typography>
                <Typography variant="subtitle1">{'Välj vilken typ av urval du vill göra för att få rätt inställningar för NIX telefon och Nix adress.'}</Typography>
                {renderNixOptions()}
            </Box>
            {tryRenderSourceElement()}
            <Box
                marginTop={6}
                display={'flex'}
                flexDirection={'row'}
                style={{gap: '15px'}}
                justifyContent={'flex-start'}>

                <Button
                    onClick={actionNext}
                    variant={'contained'}
                    size={'large'}
                    color="secondary">
                        {'Nästa'}
                </Button>
            </Box>
        </>
    );
};
