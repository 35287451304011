import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import TextFieldComponent from '../../../components/TextFieldComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import makeT from '../../../translations/t.js';
import { useIntl } from 'react-intl';
import { errorDescription, errorMaxDescription, required } from '../../../functions/validationFunctions';
import * as Yup from 'yup';
import { UserData } from '../../../data_layer/user/UserData';

export const LoginPage = ({
    userData,
    userManager,
}) => {
  const intl = useIntl();
  const T = makeT(intl);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.isLoggedIn()) {
      navigate('/order_leads');
    }
  }, [userData]);

  const handleRoute = (url) => {
    navigate(url);
  };

  const getValidationSchema = (values) => {
    return Yup.object().shape({
      email: Yup.string()
        .email(T('check_email', 'form_validation'))
        .required(required(T, T('email', 'form_validation')))
        .min(2, errorDescription(T, T('email', 'form_validation')))
        .max(80, errorMaxDescription(T, T('email', 'form_validation'))),
      password: Yup.string()
        .min(6, T('short_password', 'form_validation'))
        .required(required(T, T('password', 'form_validation'), 'e'))
        .max(128, errorMaxDescription(T, T('password', 'form_validation')))
    });
  };

  const getErrorElement = () => {
    if (userData.state === UserData.STATE_LOGIN_FAILED) {
      return (
        <Box mt={2} display="flex" alignItems="center" flexWrap="wrap" style={{color: '#f44336'}}>
          {userData.lastErrorMessage}
        </Box>
      );
    }
    return undefined;
  }

  const actionSubmit = (email, password) => {
    userManager.actionLogIn(email, password);
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4">{'Logga in'}</Typography>
      </Box>
      <Paper style={{padding: '20px'}}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={getValidationSchema('values')}
        onSubmit={(values) => {
          actionSubmit(values.email, values.password);
        }}
      >

        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} className="login-form">
            <TextFieldComponent
              formikProps={formikProps}
              id="email"
              type="email"
              label={T('email_input', 'login')}
              fullWidth
            />
            <TextFieldComponent
              formikProps={formikProps}
              id="password"
              type="password"
              label={T('password_input', 'login')}
              fullWidth
            />
            {getErrorElement()}
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
              <ButtonComponent
                type="submit"
                onClick={formikProps.handleSubmit}
                disabled={!userData.isIdle()}
                title={T('login', 'buttons')}
                fullWidth
              />
              <ButtonComponent
                variant="outlined"
                type="submit"
                onClick={() => handleRoute('/account/register')}
                title={T('register', 'buttons')}
                fullWidth
              />
              <ButtonComponent
                variant="text"
                size="small"
                type="submit"
                onClick={() => handleRoute('/account/reset_password')}
                title={T('reset_password', 'buttons')}
                fullWidth
              />
            </Box>
          </form>
        )}
      </Formik>
      {!userData.isIdle() ? <LoaderComponent /> : ''}
      </Paper>

    </Box>
    
  );
};
