export class ApiObjApiKey {

    public readonly id : number;
    public readonly api_key : string;
    public readonly user_id : number;
    public readonly created_at : string;

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.api_key = data.api_key;
        this.user_id = parseInt(data.user_id);
        this.created_at = data.created_at;
    }
}