export class ApiObjAdminNixEntry {
    
    id: number;
    phone_number: string;
    updated_at: Date;

    constructor(data: any) {
        this.id = data.id;
        this.phone_number = data.phone_number;
        this.updated_at = new Date(data.updated_at);
    }
}
