import { useState } from 'react';
import makeT from '../../../translations/t';
import { useIntl } from 'react-intl';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Formik } from 'formik';
import { errorDescription, errorMaxDescription, required } from '../../../functions/validationFunctions';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import TextFieldComponent from '../../../components/TextFieldComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import { BackToLoginButtonComponent } from '../../../components/BackToLoginButtonComponent';
import { GET } from '../../../functions/axiosSending';
import { USER_PASSWORD } from '../../../ApiRoutes';
import { useSnackbar } from 'notistack';
import LoaderComponent from '../../../components/LoaderComponent';

export const ForgotPasswordPage = () => {
  const intl = useIntl();
  const T = makeT(intl);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const action = (key) => (
    <Button
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      {'Ok'}
    </Button>
  );
  const getValidationSchema = (values) => {
    return Yup.object().shape({
      email: Yup.string()
        .email(T('check_email', 'form_validation'))
        .required(required(T, T('email', 'form_validation')))
        .min(2, errorDescription(T, T('email', 'form_validation')))
        .max(80, errorMaxDescription(T, T('email', 'form_validation')))
    });
  };
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr={5} className="fixed-element padding-large-top">
          <Typography variant="h4">{'Skriv in din e-postadress'}</Typography>
          <Box mt={2}>
            <Typography variant="h6" className="light-text">
              {'Om e-posten har ett aktivt konto så skickas en länk för att återställa lösenordet till din inkorg.'}
            </Typography>
          </Box>
      </Box>

      <Paper style={{padding: '20px'}}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={getValidationSchema('values')}
        onSubmit={(values) => {
          setDisabled(true);
          GET(`${USER_PASSWORD}/?email=${values.email}`)
            .then((success) => {
              setDisabled(false);
              navigate('/account/reset_password/found_email');
            })
            .catch((error) => {
              setDisabled(false);
              console.log('disablex forg', disabled);

              enqueueSnackbar(T('email_error', 'errors'), { action, variant: 'error' });
            });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} className="login-form">
            <TextFieldComponent
              id="email"
              formikProps={formikProps}
              type="email"
              label={T('email_input', 'login')}
              fullWidth
            />
            <Box mt={2} display="flex" flexWrap="wrap">
              <ButtonComponent
                type="submit"
                onClick={formikProps.handleSubmit}
                disabled={disabled}
                title={'Skicka'}
              />
              <BackToLoginButtonComponent />
            </Box>
          </form>
        )}
      </Formik>
      {disabled ? <LoaderComponent /> : ''}
      </Paper>
    </Box>
  );
};