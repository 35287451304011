import { ApiObjCountOrder } from '../../../api/object/filter/ApiObjCountOrder';
import { UtilArray } from '../../../util/code/UtilArray';
import { MapArea } from '../../map/MapArea';
import { MapAreaCircle } from '../../map/MapAreaCircle';
import { MapAreaPolygon } from '../../map/MapAreaPolygon';

interface InParams {
    nixOptionId: number,
    sourceId: number,
    filterIds: Array<number>,
    mapAreas: Array<MapAreaCircle|MapAreaPolygon>,
    stopfileIds: Array<number>,
    householdOptionId: number,
}

/**
 * Holds count for a single filter
 * along current params the count is based on.
 */
export class CountOrderData {

    /** Waiting for request to be sent. */
    public static readonly STATE_WAITING = 1;

    /** API Request sent, waiting for response. */
    public static readonly STATE_REQUEST_SENT = 2;

    /** A api data ready to be shown in UI. */
    public static readonly STATE_READY = 3;

    public readonly param_nix_option_id: number;
    public readonly param_source_id: number;
    public readonly param_filter_ids: Array<number>;
    public readonly param_map_areas: Array<MapAreaCircle|MapAreaPolygon>;
    public readonly param_stopfile_ids: Array<number>;
    public readonly param_household_option_id: number;
    public api_data: ApiObjCountOrder|undefined;
    public state: number;
    
    // ================================================================
    // === Create
    // ================================================================

    public static createNew(params: InParams) : CountOrderData {
        return new CountOrderData(params, undefined);
    }

    public static createClone(original: CountOrderData) : CountOrderData {
        return new CountOrderData(undefined, original);
    }

    private constructor(params: InParams|undefined, original: CountOrderData|undefined) {

        if (params !== undefined) {
            this.param_nix_option_id = params.nixOptionId;
            this.param_source_id = params.sourceId;
            this.param_filter_ids = [...params.filterIds];
            this.param_map_areas = [...params.mapAreas];
            this.param_stopfile_ids = [...params.stopfileIds];
            this.param_household_option_id = params.householdOptionId;
            this.api_data = undefined;
            this.state = CountOrderData.STATE_WAITING;

        } else if (original !== undefined) {
            this.param_nix_option_id = original.param_nix_option_id;
            this.param_source_id = original.param_source_id;
            this.param_filter_ids = [...original.param_filter_ids];
            this.param_map_areas = [...original.param_map_areas];
            this.param_stopfile_ids = [...original.param_stopfile_ids];
            this.param_household_option_id = original.param_household_option_id;
            this.api_data = original.api_data;
            this.state = original.state;
        } else {
            throw Error("Unexpected case");
        }
    }

    // ================================================================
    // === State change
    // ================================================================

    isParamsEqual = (params: InParams) : boolean => {
        return params.nixOptionId === this.param_nix_option_id
            && params.sourceId === this.param_source_id
            && UtilArray.arrayEquals(params.filterIds, this.param_filter_ids)
            && MapArea.isArraysEqual(params.mapAreas, this.param_map_areas)
            && UtilArray.arrayEquals(params.stopfileIds, this.param_stopfile_ids)
            && params.householdOptionId === this.param_household_option_id
    }

    actionRequestSent = (): void => {
        this.state = CountOrderData.STATE_REQUEST_SENT;
    }

    actionReady = (apiData: ApiObjCountOrder) : void => {
        this.api_data = apiData;
        this.state = CountOrderData.STATE_READY;
    }
}