import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_get_export_fields = async (userId: number) : Promise<Array<number>> => {

    const url = API_URL+'/admin/user/'+userId+'/export_field';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    
    const resp = await Axios.get(
        url,
        config
    );

    const result = [];
    for (const item of resp.data) {
        result.push(parseInt(item));
    }
    return result;
}
