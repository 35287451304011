import '../sass/buttonComponent.sass';
import { Box, Button } from '@mui/material';

const ButtonComponent = ({
  title,
  type = 'button',
  color = 'secondary',
  variant = 'contained',
  fullWidth,
  onClick,
  disabled,
  size = 'medium'
}) => {
  return (
    <Box className="button-container" m={1}>
      <Button
        variant={variant}
        type={type}
        className="button"
        disabled={disabled}
        fullWidth={fullWidth}
        size={size}
        color={color}
        onClick={onClick}
      >
        {title}
      </Button>
    </Box>
  );
};

export default ButtonComponent;
