import '../sass/loaderComponent.sass';
import { Box } from '@mui/material';

const LoaderComponent = (props) => {
  return (
    <Box className="box-loader">
      <div className="loader"></div>
    </Box>
  );
};

export default LoaderComponent;
