import { Grid } from '@mui/material';
import { AnimatedBlockComponent } from '../../../../components/AnimatedBlockComponent';
import GuideContentComponent from '../../../../components/GuideContentComponent';

const BlockComponent = ({ title, content, img }) => {
  return (
    <Grid item md={6} sm={8} xs={12}>
      <AnimatedBlockComponent
        className="quide-animated-block"
        blockSubtitle
        align="center"
        title={title}
        subtitle={<GuideContentComponent content={content} img={img} />}
      />
    </Grid>
  );
};
const GuideComponent = ({ blocks }) => {
  return (
    <Grid container spacing={3} justify="center">
      {blocks.map((element, index) => (
        <BlockComponent key={index} title={element.title} content={element.content} img={element.img} />
      ))}
    </Grid>
  );
};

export default GuideComponent;
