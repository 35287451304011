import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import { GET } from '../../../functions/axiosSending';
import { USER_REGISTER } from '../../../ApiRoutes';
import moment from 'moment';
import { _SET_USERS } from '../../../actions';
import { connect } from 'react-redux';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';

const UsersPage = ({
    _SET_USERS,
    userData,
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [users, setUsers] = useState([]);
    const fetchUsers = () => {
        GET(`${USER_REGISTER}?limit=${5000}`)
        .then((success) => {
          setUsers(success.data.users);
          _SET_USERS(success.data.users);
        })
        .catch((error) => console.log('error', error));
      // eslint-disable-next-line
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    // ==========================================================
    // === Table data
    // ==========================================================

    const columns = [

        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            editable: false,
        },
        {
            field: 'first_name',
            headerName: 'Förnamn',
            width: 150,
            editable: false,
        },
        {
            field: 'last_name',
            headerName: 'Efternamn',
            width: 150,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            editable: false,
        },
        {
            field: 'user_type',
            headerName: 'Typ',
            width: 100,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 100,
            editable: false,
        },
        {
            field: 'create_date',
            headerName: 'Skapad',
            width: 200,
            editable: false,
        },
        {
            field: 'count_stopfiles',
            headerName: 'Stoppfiler',
            width: 120,
            editable: false,
        },
        {
            field: 'count_export_files',
            headerName: 'Exportfiler',
            width: 100,
            editable: false,
        },
    ];

    const getRows = () => {
        const ret = [];

        for (const item of users) {

            ret.push({
                id: item.id,
                first_name: item.firstName,
                last_name: item.lastName,
                email: item.email,
                user_type: (item.admin) ? 'Admin' : 'Användare',
                state: item.active ? 'Aktiv' : 'Inaktiv',
                create_date: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                count_stopfiles: '?',
                count_export_files: '?',
            });
        }
        return ret;
    }

    // ==========================================================
    // === Render
    // ==========================================================

    return (
        <Paper style={{height: '670px'}}>
            <DataGrid
                rows={getRows()}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
                disableSelectionOnClick
                isRowSelectable={() => {return false;}}
            />
        </Paper>
    );
};

export default connect(
  (state) => ({
    usersReducer: state.UsersReducer
  }),
  {
    _SET_USERS
  }
)(UsersPage);
