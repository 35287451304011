import React, { FunctionComponent, useState } from 'react';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { UserData } from '../../../../data_layer/user/UserData';
import { Box, Button, Popover } from '@mui/material';
import { api_create_link_order_data } from '../../../../api/endpoint/link/api_create_link_order_data';

type Props = {
    userData: UserData,
    orderData: OrderData,
}

export const CreateLinkOrderDataComp: FunctionComponent <Props> = ({
    userData,
    orderData,
}) => {

    const [isLoading, setIsLoading] = useState <boolean> (false);
    const [isDone, setIsDone] = useState <boolean> (false);
    const [isError, setIsError] = useState <boolean> (false);
    const [anchorEl, setAnchorEl] = useState <HTMLButtonElement | undefined> (undefined);
    
    // ======================================================================
    // === Actions
    // ======================================================================

    const actionLinkButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsLoading(true);
        setIsDone(false);
        setIsError(false);

        const indata = {
            nix_option_id: orderData.nix_option_id,
            source_id: orderData.source_id,
            filter_ids: [...orderData.filter_ids_selected],
            map_areas: [...orderData.map_areas],
            export_field_ids: [...orderData.export_field_ids],
            stopfile_ids: [...orderData.stopfile_ids],
            stopfile_days: 0,
            household_option_id: orderData.household_option_id
        }

        api_create_link_order_data(indata)
            .then((code) => {
                const link = window.location.origin+'/order_leads/link/'+code;
                navigator.clipboard.writeText(link)
                    .then(() => {
                        setIsDone(true);
                        setIsLoading(false);
                    }).catch((err) => {
                        console.error("Could not write to clipboard.");
                        setIsError(true);
                        setIsLoading(false);
                    });
            }).catch((err) => {
                console.error("Error from backend when creating link.");
                console.error(err);
                setIsError(true);
                setIsLoading(false);
            });
    }

    const actionClosePopper = () => {
        setAnchorEl(undefined);
        setIsLoading(false);
        setIsDone(false);
        setIsError(false);
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderPopover = () : JSX.Element|undefined => {

        if (anchorEl === undefined) {
            return undefined;
        }
        
        let popperElement = undefined;
        
        if (isError || isDone) {

            const popperText = isError ? 'Kunde inte skapa länk' : 'Länk kopierad';

            popperElement = (
                <Popover
                    open={true}
                    onClose={actionClosePopper}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                        
                        <Box display={'flex'} flexDirection={'row'} padding={'12px'} alignItems={'center'}>
                            {popperText}
                        </Box>
                        
                </Popover>
            );
            
        }

        return popperElement;
    }

    return (
        <Box>
            <Button
                variant={'contained'}
                disabled={isLoading}
                onClick={actionLinkButtonClicked}>
                    Skapa Länk
            </Button>
            {renderPopover()}
        </Box>
    );
};
