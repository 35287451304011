import { Box, Button, Checkbox } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { api_admin_user_get_filters } from '../../../api/endpoint/admin/user/filter/api_admin_user_get_filters';
import { api_admin_user_save_filters } from '../../../api/endpoint/admin/user/filter/api_admin_user_save_filters';
import { ApiObjFilter } from '../../../api/object/filter/ApiObjFilter';
import { ApiObjAdminUserFilter } from '../../../api/object/admin/ApiObjAdminUserFilter';

type FormData = {
    filter_id: number,
    is_selected_for_export: boolean,
    is_selected_for_marketing: boolean
}

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData,
    otherUserId: number,
}

export const AdminUserTabFilters: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [mapFormData, setMapFormData] = useState <Map<number, FormData>> (new Map());
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        api_admin_user_get_filters(otherUserId)
            .then((result) => {
                initFormData(result);
                setIsLoading(false);
            });

    }, [otherUserId]);

    const initFormData = (userFilters: Array<ApiObjAdminUserFilter>) => {
        const newMap = new Map<number, FormData>();
        for (const item of userFilters) {
            const tempItem = {
                filter_id: item.filter_id,
                is_selected_for_export: item.is_enabled_for_export,
                is_selected_for_marketing: item.is_enabled_for_marketing
            }
            newMap.set(item.filter_id, tempItem);
        }
        setMapFormData(newMap);
    }

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSaveFilters = () => {

        setIsSaving(true);

        const filterData : Array<{filter_id: number, is_enabled_for_export: boolean, is_enabled_for_marketing: boolean}> = [];
        mapFormData.forEach((value: FormData, key: number) => {
            filterData.push({
                filter_id: value.filter_id,
                is_enabled_for_export: value.is_selected_for_export,
                is_enabled_for_marketing: value.is_selected_for_marketing
            });
        });

        api_admin_user_save_filters(otherUserId, filterData)
            .then((result) => {
                initFormData(result);
                setIsSaving(false);

            }).catch((err) => {
                console.error("Could not save filters");
                console.error(err);
                // Skip to set isSaving to fails to make error obvious.
            });
    }

    const actionOnCheckboxChangeExport = (filterId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const newMap = new Map(mapFormData);
        const filterItem = newMap.get(filterId);
        newMap.set(
            filterId,
            {
                filter_id: filterId,
                is_selected_for_export: isChecked,
                is_selected_for_marketing: filterItem === undefined ? false : filterItem.is_selected_for_marketing,
            }
        );
        setMapFormData(newMap);
    }

    const actionOnCheckboxChangeMarketing = (filterId: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const newMap = new Map(mapFormData);
        const filterItem = newMap.get(filterId);
        newMap.set(
            filterId,
            {
                filter_id: filterId,
                is_selected_for_export:  filterItem === undefined ? false : filterItem.is_selected_for_export,
                is_selected_for_marketing: isChecked,
            }
        );
        setMapFormData(newMap);
    }

    // ======================================================================
    // === Helpers
    // ======================================================================

    const getPremiumFilters = (filter: ApiObjFilter, result: Array<ApiObjFilter>) => {
        if (filter.isPremiumForExport() || filter.isPremiumForMarketing()) {
            result.push(filter);
        }
        for (const child of filter.getChildren()) {
            getPremiumFilters(child, result);
        }
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderList = () : JSX.Element => {

        const premiumFilters: Array<ApiObjFilter> = [];
        for (const filter of baseData.filter_tree) {
            getPremiumFilters(filter, premiumFilters);
        }

        const elements : Array<JSX.Element> = [];
        for (const item of premiumFilters) {
            elements.push(renderItem(item));
        }

        return (
            <Box>
                {elements}
            </Box>
        );
    }

    const renderItem = (filter: ApiObjFilter) : JSX.Element => {

        const formData = mapFormData.get(filter.getId());

        let isSelectedForExport = false;
        let isSelectedForMarketing = false;

        if (formData !== undefined) {
            isSelectedForExport = formData.is_selected_for_export;
            isSelectedForMarketing = formData.is_selected_for_marketing;
        }

        return (
            <Box
                key={filter.getId()}
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}>
                    <Box>
                        <Checkbox
                            checked={isSelectedForExport}
                            disabled={!isActive || !filter.isPremiumForExport()}
                            color={'secondary'}
                            onClick={(event) => { event.stopPropagation(); }}
                            onChange={(event) => { actionOnCheckboxChangeExport(filter.getId(), event) }}/>
                    </Box>
                    <Box>
                        <Checkbox
                            checked={isSelectedForMarketing}
                            disabled={!isActive || !filter.isPremiumForMarketing()}
                            color={'secondary'}
                            onClick={(event) => { event.stopPropagation(); }}
                            onChange={(event) => { actionOnCheckboxChangeMarketing(filter.getId(), event) }}/>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {filter.getName()}
                    </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box paddingTop={'15px'} display={'flex'} flexDirection={'row'}>
                <Box>Export</Box>
                <Box paddingLeft={'10px'}>Marknadsföring</Box>
                <Box paddingLeft={'10px'}>Filter</Box>
            </Box>
            <Box paddingTop={'5px'}>
                {renderList()}
            </Box>
            <Box paddingTop={'20px'}>
                <Button variant='contained' color='secondary' onClick={actionSaveFilters} disabled={!isActive}>
                    Spara
                </Button>
            </Box>
        </Box>
    );
}
