import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_get_all = async () : Promise<Array<ApiObjPopulateFile>> => {
    
    const url = API_URL+'/populate_file';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjPopulateFile(item));
    }
    return ret;
}
