import { ApiObjFilter } from '../../../api/object/filter/ApiObjFilter';
import { UtilArray } from '../../../util/code/UtilArray';

/**
 * Holds count for a single filter
 * along current params the count is based on.
 */
export class CountFilterDataItem {

    /** Waiting for request to be sent. */
    public static readonly STATE_WAITING = 1;

    /** API Request sent, waiting for response. */
    public static readonly STATE_REQUEST_SENT = 2;

    /** A valid count can be shown in UI. */
    public static readonly STATE_READY = 3;

    public readonly filter_id: number;
    public readonly filter_select_group: number;
    public nix_option_id: number;
    public source_id: number;
    public filters_selected_from_other_group: Array<number>;
    public map_area_ids: Array<number>;
    public state : number;
    public count: number;

    // ================================================================
    // === Create
    // ================================================================

    public static createNew(filterObj: ApiObjFilter, nixOptionId: number, sourceId: number) : CountFilterDataItem {
        return new CountFilterDataItem(filterObj, nixOptionId, sourceId, undefined);
    }

    public static createClone(original: CountFilterDataItem) {
        return new CountFilterDataItem(undefined, -1, -1, original);
    }

    private constructor(filterObj: ApiObjFilter|undefined, nixOptionId: number, sourceId: number, original: CountFilterDataItem|undefined) {

        if (filterObj !== undefined) {
            this.filter_id = filterObj.getId();
            this.filter_select_group = filterObj.getFilterSelectGroup();
            this.nix_option_id = nixOptionId;
            this.source_id = sourceId;
            this.filters_selected_from_other_group = [];
            this.map_area_ids = [];
            this.state = CountFilterDataItem.STATE_WAITING;
            this.count = 0;

        } else if (original !== undefined) {
            this.filter_id = original.filter_id;
            this.filter_select_group = original.filter_select_group;
            this.nix_option_id = original.nix_option_id;
            this.source_id = original.source_id;
            this.filters_selected_from_other_group = [...original.filters_selected_from_other_group];
            this.map_area_ids = [...original.map_area_ids];
            this.state = original.state;
            this.count = original.count;

        } else {
            throw Error("Unexpected case");
        }
    }

    // ================================================================
    // === State change
    // ================================================================

    /**
     * Checks if params matches params of the item.
     * This is used to determine if the count returned by the api can be used.
     * 
     * @param nixOptionId 
     * @param sourceId 
     * @param selectedFilters 
     * @param mapAreaIds 
     * @returns 
     */
    isValidForParams = (nixOptionId: number, sourceId: number, selectedFilters: Array<ApiObjFilter>, mapAreaIds: Array<number>) : boolean => {

        if (this.nix_option_id != nixOptionId) {
            return false;
        }

        if (this.source_id != sourceId) {
            return false;
        }

        let testArray = [];
        for (const filter of selectedFilters) {
            if (filter.getFilterSelectGroup() != this.filter_select_group) {
                testArray.push(filter.getId());
            }
        }
        testArray = UtilArray.createUniqueSortedIntArray(testArray);
        if (!UtilArray.arrayEquals(testArray, this.filters_selected_from_other_group)) {
            return false;
        }

        if (!UtilArray.arrayEquals(this.map_area_ids, mapAreaIds)) {
            return false;
        }
        return true;
    }

    /**
     * Sets all params the count returned by the api needs to match.
     * 
     * @param nixOptionId Nix option Id
     * @param sourceId 
     * @param selectedFilters Selected filters. Filters in same select_group should be excluded.
     * @param mapAreaIds Ids of shapes on the map (frontend only, lives during one purchase ).
     */
    setParams = (nixOptionId: number, sourceId: number, selectedFilters: Array<ApiObjFilter>, mapAreaIds: Array<number>) : void => {
        let newSelectedFilters : Array<number> = [];
        for (const filter of selectedFilters) {
            if (this.filter_select_group != filter.getFilterSelectGroup()) {
                newSelectedFilters.push(filter.getId());
            }
        }
        newSelectedFilters = UtilArray.createUniqueSortedIntArray(newSelectedFilters);
        this.filters_selected_from_other_group = newSelectedFilters;
        this.nix_option_id = nixOptionId;
        this.source_id = sourceId;
        this.map_area_ids = mapAreaIds;
    }

    invalidateCount = () : void => {
        this.state = CountFilterDataItem.STATE_WAITING;
        this.count = 0;
    }

    setReady = (count: number) : void => {
        this.state = CountFilterDataItem.STATE_READY;
        this.count = count;
    }
}