import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFileOrderStep2 } from '../../object/populate_file/ApiObjPopulateFileOrderStep2';

export const api_populate_file_order_get_step_2 = async (populateFileId: number) : Promise<ApiObjPopulateFileOrderStep2> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_2';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );

    return new ApiObjPopulateFileOrderStep2(resp.data);
}
