export class MapPoint {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public latitude: number;
    public longitude: number;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}