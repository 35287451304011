import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_update_target_group_count = async (id: number, count: number) : Promise<boolean> => {
    
    const url = API_URL+'/target_group/'+id+'/count';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const bodyData = {
        count: count
    }

    Axios.post(
        url,
        bodyData,
        config
    );

    return true;
}
