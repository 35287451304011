import React, { FunctionComponent } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemButton, ListItemIcon, Divider, ListItemText, Paper } from '@mui/material';
import {
    ArrowBack,
    Business,
    Groups,
    InsertDriveFile,
    Looks3,
    Looks4,
    Looks5,
    LooksOne,
    LooksTwo,
    MenuBook,
    PanTool,
    People,
    ShoppingCart,
    SvgIconComponent,
    AccessibilityNew,
    MonetizationOn,
    PhoneDisabled,
    InsertPageBreak
} from '@mui/icons-material';
import packageJson from '../../../../package.json';
import { UserData } from '../../../data_layer/user/UserData';
type LocalMenuItem = {
    key: number,
    text: string
    icon: SvgIconComponent
    url: string
    children: Array<LocalMenuItem>
}

type Props = {
    userData: UserData,
}

export const MainMenuComp: FunctionComponent <Props> = ({
    userData,
}) => {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    // ==============================================================
    // === Menuitems default
    // ==============================================================

    const menuItemsAlways = [
        {
            key: 1,
            text: 'Beställ leads',
            icon: ShoppingCart,
            url: '/order_leads',
            children: [
                {
                    key: 2,
                    text: 'NIX-alternativ',
                    icon: LooksOne,
                    url: '/order_leads/1',
                    children: []
                },
                {
                    key: 3,
                    text: 'Urval',
                    icon: LooksTwo,
                    url: '/order_leads/2',
                    children: []
                },
                {
                    key: 4,
                    text: 'Leveransfält',
                    icon: Looks3,
                    url: '/order_leads/3',
                    children: []
                },
                {
                    key: 5,
                    text: 'Välj stoppfiler',
                    icon: Looks4,
                    url: '/order_leads/4',
                    children: []
                },
                {
                    key: 6,
                    text: 'Erbjudande / Köp',
                    icon: Looks5,
                    url: '/order_leads/5',
                    children: []
                }
            ],
        },
        {
            key: 10,
            text: 'Nummersättning',
            icon: InsertPageBreak,
            url: '/populate_file',
            children: []
        },
        {
            key: 11,
            text: 'Snabbguide',
            icon: MenuBook,
            url: '/quick_guide',
            children: []
        },
    ];

    const menuItemsUser = [
        {
            key: 11,
            text: 'Mina filer',
            icon: InsertDriveFile,
            url: '/my_files',
            children: []
        },
        {
            key: 12,
            text: 'Mina Målgrupper',
            icon: Groups,
            url: '/my_target_groups',
            children: []
        },
        {
            key: 13,
            text: 'Skapa stoppfil',
            icon: PanTool,
            url: '/create_stopfile',
            children: []
        },
    ];

    const menuItemsAdmin = [
        {
            key: 14,
            text: 'Användare',
            icon: People,
            url: '/admin/users',
            children: []
        },
        {
            key: 15,
            text: 'Kunder',
            icon: Business,
            url: '/admin/customers',
            children: []
        },
        {
            key: 16,
            text: 'Användare Ny',
            icon: AccessibilityNew,
            url: '/admin/users_new',
            children: []
        },
        {
            key: 17,
            text: 'Globala Priser',
            icon: MonetizationOn,
            url: '/admin/global_prices',
            children: []
        },
        {
            key: 18,
            text: 'Spärrade nummer',
            icon: PhoneDisabled,
            url: '/admin/blocked_numbers',
            children: []
        },
    ];

    // ==============================================================
    // === Render section 1
    // ==============================================================

    const createListItems = (menuItems: Array<LocalMenuItem>, indents: number) : Array<JSX.Element> => {

        const listItems = [];
        for (const item of menuItems) {

            const isSelected = matchPath(item.url + '/*', pathname) !== null;

            const iconElement = React.createElement(
                item.icon,
                {color: isSelected ? 'secondary': 'inherit'}
            )

            const paddingLeft = (indents * 2) + 2;

            listItems.push(
                <ListItem key={item.key} disablePadding>
                    <ListItemButton sx={{ pl: paddingLeft }}
                        selected={isSelected}
                        onClick={() => { navigate(item.url) }}>

                        <ListItemIcon style={{minWidth: 0, marginRight: '14px'}}>
                            {iconElement}
                        </ListItemIcon>

                        <ListItemText style={{paddingLeft: '0'}} primary={item.text} />

                    </ListItemButton>
                </ListItem>
            );

            if (isSelected && item.children.length > 0) {
                const childItems = createListItems(item.children, indents + 1);
                listItems.push(
                    <List key={item.key + 1000} disablePadding >
                        {childItems}
                    </List>
                );
            }
        }

        return listItems;
    }

    // ==============================================================
    // === Render sections
    // ==============================================================

    const renderSectionAlways = () : JSX.Element => {
        return (
            <List key={1}>
                {createListItems(menuItemsAlways, 0)}
            </List>
        );
    }

    const tryRenderSectionUser = () : JSX.Element|undefined => {
        if (userData.isLoggedIn()) {
            return (
                <>
                    <Divider key={2} />
                    <List key={3}>
                        {createListItems(menuItemsUser, 0)}
                    </List>
                </>
            );
        }
        return undefined;
    }

    const tryRenderSectionAdmin = () : JSX.Element|undefined => {
        if (userData.isLoggedInAndAdmin()) {
            return (
                <>
                    <Divider key={4} />
                    <List key={5}>
                        {createListItems(menuItemsAdmin, 0)}
                    </List>
                </>
            );
            
        }
        return undefined;
    }

    const renderSectionBack = () => {
        return (
            <>
                <Divider key={100} />
                <List key={101}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => { window.location.href = 'https://www.marknadsurval.se/' }}>

                            <ListItemIcon style={{minWidth: 0, marginRight: '14px'}}>
                                <ArrowBack color="secondary" />
                            </ListItemIcon>

                            <ListItemText style={{paddingLeft: '0'}} primary={'Marknadsurval'} />

                        </ListItemButton>
                    </ListItem>
                </List>
            </>
        )
    }

    // ==============================================================
    // === Render main
    // ==============================================================

    return (
        <Box width={'250px'}>
            <Paper>
                <Box display={'flex'} flexDirection={'column'}>
                    {renderSectionAlways()}
                    {tryRenderSectionUser()}
                    {tryRenderSectionAdmin()}
                </Box>
            </Paper>
            <Box marginTop={'14px'}>
                <Paper>
                    <Box display={'flex'} flexDirection={'column'}>
                        {renderSectionBack()}
                    </Box>
                </Paper>
            </Box>
            <Box style={{position: 'fixed', left: '10px', bottom: '10px' }}>
                <Typography variant="caption">
                    {'v '+packageJson.version}
                </Typography>
            </Box>
        </Box>
    );
};
