export class ApiObjAdminGlobalPrice {
    
    id : number;
    object_type_id : number;
    object_id : number;
    name : string;
    private_comment : string;
    price : number;
    
    constructor(data: any) {
        this.id = parseInt(data.id);
        this.object_type_id = parseInt(data.object_type_id);
        this.object_id = parseInt(data.object_id);
        this.name = data.name;
        this.private_comment = data.private_comment;
        this.price = parseFloat(data.price);
    }
}