import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState } from 'react';
import { Box } from '@mui/system';
import { TargetGroupEditData } from '../../../data_layer/target_group/TargetGroupEditData';
import { TargetGroupEditManager } from '../../../data_layer/target_group/TargetGroupEditManager';
import { Typography } from '@mui/material';
import { MapDrawArea } from '../../shared_components/MapDrawArea';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { FilterTree } from '../../shared_components/FilterTree';

type Props = {
    baseData: ApiObjBaseData,
    targetGroupData: TargetGroupEditData,
    targetGroupManager: TargetGroupEditManager,
    userData: UserData,
}

export const TargetGroupEditStep2: FunctionComponent <Props> = ({
    baseData,
    targetGroupData,
    targetGroupManager,
    userData,
}) => {

    // ===========================================================
    // === State
    // ===========================================================

    const [mapExpanded, setMapExpanded] = useState <boolean> (false);

    // ===========================================================
    // === Actions
    // ===========================================================

    const onSelectedFilterIdsChanged = (filterIds: Array<number>) => {
        targetGroupManager.actionSetFiltersSelected(filterIds);
    }

    const onVisibleFilterIdsChanged = (filterIds: Array<number>) => {
        targetGroupManager.actionSetFiltersVisible(filterIds);
    }

    const actionAddArea = (area: MapAreaCircle|MapAreaPolygon) => {
        targetGroupManager.actionAddMapArea(area);
    }

    const actionRemoveArea = (areaId: number) => {
        targetGroupManager.actionRemoveMapArea(areaId);
    }

    const actionToggleMapExpanded = () => {
        setMapExpanded(!mapExpanded);
    }

    // ===========================================================
    // === Render
    // ===========================================================

    const renderMap = (): JSX.Element => {
        // Need this to force 2 different instances of map as resize of map seems buggy.
        if (mapExpanded) {
            return (
                <MapDrawArea
                    key={1}
                    editEnabled={!targetGroupData.state_is_saving}
                    expanded={mapExpanded}
                    areas={targetGroupData.map_areas}
                    onAddArea={actionAddArea}
                    onRemoveArea={actionRemoveArea}
                    onToggleMapExpanded={actionToggleMapExpanded}
                />
            );
        } else {
            return (
                <MapDrawArea
                    key={2}
                    editEnabled={false}
                    expanded={mapExpanded}
                    areas={targetGroupData.map_areas}
                    onAddArea={actionAddArea}
                    onRemoveArea={actionRemoveArea}
                    onToggleMapExpanded={actionToggleMapExpanded}
                />
            );
        }
    }

    return (
        <Box>
            <Typography mb={2} variant="h6">{'Välj område'}</Typography>
            
            {renderMap()}

            <Typography mt={4} variant="h6">{'Specificera din målgrupp'}</Typography>
            <Box mt={2}>
                <FilterTree
                    userData={userData}
                    baseData={baseData}
                    selectedFilterIds={targetGroupData.filter_ids_selected}
                    visibleFilterIds={targetGroupData.filter_ids_visible}
                    filterCounts={targetGroupData.filter_count}
                    nixOptionId={targetGroupData.nix_option_id}
                    onSelectedFilterIdsChanged={onSelectedFilterIdsChanged}
                    onVisibleFilterIdsChanged={onVisibleFilterIdsChanged}
                    disablePrices={true}
                    disabled={targetGroupData.state_is_saving}
                />
            </Box>
        </Box>
    );
}
