import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { HeaderComp } from './components/HeaderComp';
import { SpecificationComp } from '../page_frame/components/SpecificationComp';
import { OrderData } from '../../data_layer/order/OrderData';
import { OrderManager } from '../../data_layer/order/OrderManager';
import { UserData } from '../../data_layer/user/UserData';
import { UserManager } from '../../data_layer/user/UserManager';
import { MainMenuComp } from './components/MainMenuComp';
import { useLocation } from 'react-router-dom';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    userManager: UserManager,
    orderData: OrderData,
    orderManager: OrderManager
}

export const PageFrameMain: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
    orderData,
    orderManager
}) => {

    // ======================================================================
    // === Header
    // ======================================================================

    // ======================================================================
    // === Menu
    // ======================================================================

    // ======================================================================
    // === PageContent
    // ======================================================================

    // ======================================================================
    // === Order spec
    // ======================================================================

    const tryRenderOrderSpec = () : JSX.Element|undefined => {
        if (orderData !== undefined && baseData !== undefined) {
            return (
                <SpecificationComp
                    baseData={baseData}
                    userData={userData}
                    orderData={orderData}
                    orderManager={orderManager}
                />
            );
        }
        return undefined;
    }

    // ======================================================================
    // === Footer
    // ======================================================================

    // ======================================================================
    // === Render
    // ======================================================================

    const location = useLocation()

    return (
        <Box display={'flex'} flexDirection={'column'}>
        
            <HeaderComp
                title={"TODO title"}
                userData={userData}
                userManager={userManager} />

            <Box 
                style={{
                    width: '250px',
                    height: '100%',
                    position: 'fixed',
                    left: '10px',
                    top: '80px',
                    zIndex: '1100',
                }}>
                <MainMenuComp userData={userData} />
            </Box>

            <Box 
                display={'flex'}
                flexDirection={'row'}
                marginLeft={'270px'}
                marginTop={'80px'}>
                {
                    location.pathname.includes("populate") || location.pathname.includes("admin/users_new") || location.pathname.includes("my_target_groups") || location.pathname.includes("create_stopfile") || location.pathname.includes("my_files") ? (
                        <Box width={'100%'}>
                            <Outlet />
                        </Box>
                    ) : (
                        <>
                            <Box width={'1000px'}>
                                <Outlet />
                            </Box>
                            <Box width={'500px'} marginLeft={'20px'}>
                                {tryRenderOrderSpec()}
                            </Box>
                        </>
                    )
                }

            </Box>

        </Box>
    );
}
