import { UserData } from "../user/UserData";

export const clean_source = (sourceId: number, userData: UserData) : number => {
    if (sourceId === 0) {
        return 0;
    }
    if (userData.getSource(sourceId) === undefined) {
        return 0;
    }
    return sourceId;
}
