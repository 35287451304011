import { CardElement } from '@stripe/react-stripe-js';
import '../sass/cardSectionComponent.sass';

// TODO ERIK Ta bort användning och ta bort fil.

const style = {
  base: {
    color: '#32325d',
    fontFamily: "Suisse Int'l",
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const CardSelectionComponent = ({ handleChange }) => {
  return <CardElement className="MyCardElement" style={style} onChange={handleChange} />;
};

export default CardSelectionComponent;
