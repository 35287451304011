export class ApiObjExportField {

    id: number;
    name: string;
    is_premium: boolean;

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.is_premium = parseInt(data.is_premium) === 1;
    }
}