import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_transfer_target_group = async (currentUserId: number, targetGroupId: number, newUserId: number) : Promise<void> => {

    const url = API_URL+'/admin/user/'+currentUserId+'/target_group/'+targetGroupId+'/transfer';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    const payload = {
        new_user_id: newUserId
    };

    await Axios.post(
        url,
        payload,
        config
    );
}