import { Paper, Box, Tabs, Tab, IconButton, } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { api_admin_get_user } from '../../../api/endpoint/admin/api_admin_get_user';
import { AdminUserTabTargetGroups } from './AdminUserTabTargetGroups';
import { ApiObjAdminUser } from '../../../api/object/admin/ApiObjAdminUser';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { AdminUserTabPricing } from './AdminUserTabPricing';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { api_admin_get_base_data } from '../../../api/endpoint/admin/base_data/api_admin_get_base_data';
import { AdminUserTabSources } from './AdminUserTabSources';
import { AdminUserTabUserDetails } from './AdminUserTabUserDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AdminUserTabExports } from './AdminUserTabExports';
import { AdminUserTabStopfiles } from './AdminUserTabStopfiles';
import { AdminUserTabExportFields } from './AdminUserTabExportFields';
import { AdminUserTabNixOptions } from './AdminUserTabNixOptions';
import { AdminUserTabFilters } from './AdminUserTabFilters';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
}

export const AdminUserMainPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    // ======================================================================
    // === No admin
    // ======================================================================

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    // ======================================================================
    // === Setup
    // ======================================================================

    const navigate = useNavigate();
    const [userObj, setUserObj] = useState <ApiObjAdminUser|undefined> (undefined);
    const [adminBaseData, setAdminBaseData] = useState <ApiObjAdminBaseData|undefined> (undefined);

    const { user_id, tab_name } = useParams();

    useEffect(() => {
        actionReloadUser();
    }, [user_id]);

    useEffect(() => {
        api_admin_get_base_data()
            .then((result) => {
                setAdminBaseData(result);
            }
        );
    }, []);

    // ======================================================================
    // === Render loading
    // ======================================================================
  
    const actionReloadUser = () => {
        if (user_id !== undefined) {
            api_admin_get_user(parseInt(user_id))
                .then((result) => {
                    setUserObj(result);

                }).catch((err) => {
                    console.error("Could not fetch user");
                    console.error(err);
                });
        }
    }

    // ======================================================================
    // === Render loading
    // ======================================================================

    if (userObj === undefined || adminBaseData === undefined) {
        return (<Box>Laddar...</Box>);
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderUserInfo = () : JSX.Element => {
        
        return (
            <Box padding={'14px'} display={'flex'} alignItems={'center'} flexDirection={'row'} fontSize={'18px'}>
                <IconButton onClick={() => { navigate('/admin/users_new') }}>
                    <ArrowBackIcon />
                </IconButton>
                <Box marginLeft={'14px'}>{userObj.email}</Box>
                <Box marginLeft={'14px'}>{userObj.first_name}</Box>
                <Box marginLeft={'14px'}>{userObj.last_name}</Box>
            </Box>
        );
    }

    const renderTabs = () : undefined|JSX.Element => {

        let curTab = tab_name;
        if (curTab === undefined) {
            curTab = 'overview';
        }

        return (
            <Box>
                <Tabs value={curTab} onChange={(event, value) => { navigate('/admin/users_new/'+user_id+'/'+value) }}>
                    <Tab value={'overview'} label="Översikt" />
                    <Tab value={'user_details'} label="Info" />
                    <Tab value={'pricing'} label="Priser" />
                    <Tab value={'nix_options'} label="Nix" />
                    <Tab value={'sources'} label="Källor" />
                    <Tab value={'filters'} label="Filter" />
                    <Tab value={'export_fields'} label="Exportfält" />
                    <Tab value={'target_groups'} label="Målgrupper" />
                    <Tab value={'stopfiles'} label="Stoppfiler" />
                    <Tab value={'exports'} label="Exporter" />
                </Tabs>
            </Box>
        );
    }

    const renderCurrentPage = (): JSX.Element|undefined => {
        switch(tab_name) {
            case undefined:
            case 'overview':
                return (
                    <Box padding={'14px'}>TODO User overview</Box>
                );
            case 'user_details':
                return (
                    <AdminUserTabUserDetails
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUser={userObj}
                        parentReloadUser={actionReloadUser}
                    />
                );
            case 'pricing':
                return (
                    <AdminUserTabPricing 
                        baseData={baseData}
                        userData={userData}
                        otherUserId={userObj.id}
                    />
                );
            case 'nix_options':
                return (
                    <AdminUserTabNixOptions
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id}
                    />
                );
            case 'sources':
                return (
                    <AdminUserTabSources
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id}
                    />
                );
            case 'filters':
                return (
                    <AdminUserTabFilters
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id}
                    />
                );
            case 'export_fields':
                return (
                    <AdminUserTabExportFields
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id}
                    />
                );
            case 'target_groups':
                return (
                    <AdminUserTabTargetGroups
                        userData={userData}
                        otherUserId={userObj.id}
                    />
                );
            case 'stopfiles':
                return (
                    <AdminUserTabStopfiles
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id} />
                );
            case 'exports':
                return (
                    <AdminUserTabExports
                        baseData={baseData}
                        userData={userData}
                        adminBaseData={adminBaseData}
                        otherUserId={userObj.id} />
                );
        }
        return undefined;
    }

    return (
        <Box>
            <Box>
                <Paper>
                    {renderUserInfo()}
                </Paper>
            </Box>
            <Box marginTop={'20px'}>
                <Paper>
                    {renderTabs()}
                </Paper>
            </Box>
            <Box marginTop={'20px'}>
                <Paper>
                    {renderCurrentPage()}
                </Paper>
            </Box>
        </Box>
    );
}
