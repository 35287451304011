import { ApiObjExportField } from "../export/ApiObjExportField";
import { ApiObjFilter } from "../filter/ApiObjFilter";
import { ApiObjHouseholdOption } from "../household/ApiObjHouseholdOption";
import { ApiObjNixOption } from "../nix_option/ApiObjNixOption";
import { ApiObjPriceObject } from "../price/ApiObjPriceObject";
import { ApiObjPriceObjectType } from "../price/ApiObjPriceObjectType";
import { ApiObjSource } from "../source/ApiObjSource";

/**
 * This object holds all data that changes rarely and is independent of user.
 */
export class ApiObjBaseData {

    filter_tree: Array<ApiObjFilter>;
    filter_map: Map<number, ApiObjFilter>;
    sources: Array<ApiObjSource>;
    export_fields: Array<ApiObjExportField>;
    price_object_types: Array<ApiObjPriceObjectType>;
    price_objects: Array<ApiObjPriceObject>;
    household_options: Array<ApiObjHouseholdOption>;
    nix_options: Array<ApiObjNixOption>;

    constructor(data: any) {

        this.filter_tree = [];
        const filterTreeDataVersion = parseInt(data.filter_tree.data_version);
        for (const item of data.filter_tree.items) {
            this.filter_tree.push(new ApiObjFilter(item, filterTreeDataVersion));
        }
        const filterMap = new Map();
        for (const filter of this.filter_tree) {
            insertFilterIntoMap(filter, filterMap);
        }
        this.filter_map = filterMap;

        this.sources = [];
        for (const item of data.sources) {
            this.sources.push(new ApiObjSource(item));
        }

        this.export_fields = [];
        for (const item of data.export_fields) {
            this.export_fields.push(new ApiObjExportField(item));
        }

        this.price_object_types = [];
        for (const item of data.price_object_types) {
            this.price_object_types.push(new ApiObjPriceObjectType(item));
        }

        this.price_objects = [];
        for (const item of data.price_objects) {
            this.price_objects.push(new ApiObjPriceObject(item));
        }

        this.household_options = [];
        for (const item of data.household_options) {
            this.household_options.push(new ApiObjHouseholdOption(item));
        }

        this.nix_options = [];
        for (const item of data.nix_options) {
            this.nix_options.push(new ApiObjNixOption(item));
        }
    }

    getFilter (filterId: number) : ApiObjFilter|undefined {
        return this.filter_map.get(filterId);
    }

    getFilterParent (filterId: number) : ApiObjFilter|undefined {
        const filter = this.filter_map.get(filterId);
        if (filter === undefined || filter.getParentId() === 0) {
            return undefined;
        }
        return this.filter_map.get(filter.getParentId());
    }

    getFilterAllParents (filterId: number) : Array<ApiObjFilter> {
        const result = [];
        let failsafe = 0;
        let tempFilter = this.getFilterParent(filterId);
        while (tempFilter !== undefined) {
            result.push(tempFilter);
            tempFilter = this.filter_map.get(tempFilter.getParentId());
            failsafe ++;
            if (failsafe > 20) {
                break;
            }
        }
        return result;
    }

    getFilterAllChildren (filterId: number, result: Array<ApiObjFilter>) : void {
        const filter = this.filter_map.get(filterId);
        if (filter === undefined) {
            return;
        }
        this.getFilterAllChildrenFromFilter(filter, result);
    }

    getFilterAllChildrenFromFilter (filter: ApiObjFilter, result: Array<ApiObjFilter>) : void {
        for (const child of filter.getChildren()) {
            result.push(child);
            this.getFilterAllChildrenFromFilter(child, result);
        }
    }

    getSource (sourceId: number) : ApiObjSource|undefined {
        for (const item of this.sources) {
            if (item.id === sourceId) {
                return item;
            }
        }
        return undefined;
    }

    getExportField (exportFieldId: number) : ApiObjExportField|undefined {
        for (const item of this.export_fields) {
            if (item.id === exportFieldId) {
                return item;
            }
        }
        return undefined;
    }

    getPriceObjectType (priceObjectTypeId: number) : ApiObjPriceObjectType|undefined {
        for (const item of this.price_object_types) {
            if (item.id === priceObjectTypeId) {
                return item;
            }
        }
        return undefined;
    }

    getPriceObject (priceObjectTypeId: number, objectId: number) : ApiObjPriceObject|undefined {
        for (const item of this.price_objects) {
            if (item.object_type_id === priceObjectTypeId && item.object_id === objectId) {
                return item;
            }
        }
        return undefined;
    }

    getPriceObjectFromId (priceObjectId: number) : ApiObjPriceObject|undefined {
        for (const item of this.price_objects) {
            if (item.id === priceObjectId) {
                return item;
            }
        }
        return undefined;
    }

    getNixOption = (nixOptionId: number) : ApiObjNixOption|undefined => {
        for (const item of this.nix_options) {
            if (item.id === nixOptionId) {
                return item;
            }
        }
        return undefined;
    }
}

const insertFilterIntoMap = (filter : ApiObjFilter, map : Map<number, ApiObjFilter>) => {
    map.set(filter.getId(), filter);
    for (const child of filter.getChildren()) {
        insertFilterIntoMap(child, map);
    }
}