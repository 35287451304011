import { MapAreaCircle } from "../../../data_layer/map/MapAreaCircle";
import { MapAreaPolygon } from "../../../data_layer/map/MapAreaPolygon";
import { MapPoint } from "../../../data_layer/map/MapPoint";

export class ApiObjLinkOrderData {

    public readonly code : string;
    public readonly nix_option_id: number;
    public readonly source_id: number;
    public readonly filter_ids: Array<number>;
    public readonly map_areas: Array<MapAreaCircle|MapAreaPolygon>;
    public readonly export_field_ids: Array<number>;
    public readonly stopfile_ids: Array<number>;
    public readonly stopfile_days: number;
    public readonly household_option_id: number;

    constructor(data: any) {
        this.code = data.code;
        this.nix_option_id = parseInt(data.nix_option_id);
        this.source_id = parseInt(data.source_id);
        
        this.filter_ids = [];
        for (const item of data.filter_ids) {
            this.filter_ids.push(parseInt(item));
        }

        this.map_areas = [];

        let tempId = 1;
        for (const item of data.map_areas) {
            if (item.type === 'circle') {
                this.map_areas.push(
                    MapAreaCircle.createNew(
                        tempId,
                        parseFloat(item.center_lat),
                        parseFloat(item.center_long),
                        parseFloat(item.radius)
                    )
                );
            } else if (item.type === 'polygon') {

                const tempPoints = [];
                for (const p of item.points) {
                    tempPoints.push(new MapPoint(p.lat, p.long))
                }

                this.map_areas.push(
                    MapAreaPolygon.createNew(
                        tempId,
                        tempPoints
                    )
                );

            } else {
                throw new Error("Unknown map area type")
            }
            tempId ++;
        }

        this.export_field_ids = [];
        for (const item of data.export_field_ids) {
            this.export_field_ids.push(parseInt(item));
        }

        this.stopfile_ids = [];
        for (const item of data.stopfile_ids) {
            this.stopfile_ids.push(parseInt(item));
        }
        this.household_option_id = parseInt(data.household_option_id);

        this.stopfile_days = parseInt(data.stopfile_days);
    }
}