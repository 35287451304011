import { ApiObjPriceObjectType } from "./ApiObjPriceObjectType";

export class ApiObjPriceObject {

    // ==============================================================
    // === Fields
    // ==============================================================

    id: number;
    object_type_id: number;
    object_id: number;
    name: string;
    price_global: number;
    price_user: number;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.object_type_id = parseInt(data.object_type_id);
        this.object_id = parseInt(data.object_id);
        this.name = data.name;
        this.price_global = parseFloat(data.price_global);
        this.price_user = (data.price_user !== undefined) ? parseFloat(data.price_user) : -1;
    }

    // ==============================================================
    // === Convenience
    // ==============================================================

    hasUserPrice() : boolean {
        return this.price_user === -1;
    }

    getPriceFloat() : number {
        return (this.price_user !== -1) ? this.price_user : this.price_global;
    }

    getPriceString() : string {
        const priceFloat = this.getPriceFloat();
        const priceParts = priceFloat.toFixed(2).split('.');
        if (priceParts[1] === '00' && priceFloat >= 10) {
            return priceParts[0];
        } else {
            return priceParts[0]+','+priceParts[1];
        }
    }

    getPriceStringFull() : string {
        const priceString = this.getPriceString();

        switch (this.object_type_id) {

            case ApiObjPriceObjectType.ID_BASE_PRICE_NIX_OPTION:
            case ApiObjPriceObjectType.ID_BASE_PRICE_SOURCE:
                return priceString + ' kr per beställning';

            case ApiObjPriceObjectType.ID_LEAD_PRICE_NIX_OPTION:
            case ApiObjPriceObjectType.ID_LEAD_PRICE_SOURCE:
                return priceString + ' kr per lead';

            
            case ApiObjPriceObjectType.ID_LEAD_PRICE_FILTER:
            case ApiObjPriceObjectType.ID_LEAD_PRICE_MAP:
            case ApiObjPriceObjectType.ID_LEAD_PRICE_EXPORT_FIELD:
            case ApiObjPriceObjectType.ID_LEAD_PRICE_STOPFILES:
                return '+ '+priceString + ' kr per lead';
            
            case ApiObjPriceObjectType.ID_POPULATE_FILE_NEW_DATA:
            case ApiObjPriceObjectType.ID_POPULATE_FILE_CLEAN:
                return '+ '+priceString + ' kr per matchning';

            default:
                return priceString; // Fallback, should never happen.
        }
        
    }
}