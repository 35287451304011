import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfilePreview } from '../../object/stopfile/ApiObjStopfilePreview';

export const api_get_stopfile_preview = async (stopfileId: number) : Promise<ApiObjStopfilePreview> => {
    
    const url = API_URL+'/stopfile/'+stopfileId+'/preview';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    );
    
    return new ApiObjStopfilePreview(resp.data);
}