import { GetExportPriceParams } from "../../endpoint/export/api_get_export_price";
import { ApiObjPriceObject } from "../price/ApiObjPriceObject";

export class ApiObjExportPrice {
    
    currency: string;
    price_base: number;                 // float
    price_per_item: number;             // float
    item_count: number;                 // int
    price_all_items: number;            // float
    price_total_excl_vat: number;       // float
    price_total_vat: number;            // float
    price_total: number;                // float
    spec_items: Array<ApiObjPriceObject>;

    request_params: GetExportPriceParams;
    
    constructor(data: any, requestParams: GetExportPriceParams) {

        this.currency = data.currency;
        this.price_base = parseFloat(data.price_base);
        this.price_per_item = parseFloat(data.price_per_item);
        this.item_count = parseInt(data.item_count);
        this.price_all_items = parseFloat(data.price_all_items);
        this.price_total_excl_vat = parseFloat(data.price_total_excl_vat);
        this.price_total_vat = parseFloat(data.price_total_vat);
        this.price_total = parseFloat(data.price_total);

        this.spec_items = [];
        for (const item of data.spec_items) {
            this.spec_items.push(new ApiObjPriceObject(item));
        }
        this.request_params = requestParams;
    }
}