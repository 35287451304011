import ButtonComponent from './ButtonComponent';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MakeT from '../translations/t.js';

export const BackToLoginButtonComponent = ({ history }) => {
  const intl = useIntl();
  const T = MakeT(intl);
  const navigate = useNavigate();
  return (
    <ButtonComponent
      title={T('back_to_login', 'buttons')}
      onClick={(e) => navigate('/account/login')}
      variant="outlined"
      color="inherit"
    />
  );
};
