import React, { FunctionComponent, useState } from 'react';
import { Checkbox, Box, Typography, Button, Chip } from '@mui/material';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjPriceObjectType } from '../../../../api/object/price/ApiObjPriceObjectType';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData
    orderData: OrderData,
    orderManager: OrderManager
}

export const Step3ExportFieldsComp: FunctionComponent<Props> = ({
    baseData,
    userData,
    orderData,
    orderManager
}) => {

    const navigate = useNavigate();
    const [mouseOverFieldId, setMouseOverFieldId] = useState <number> (0);

    // =====================================================================
    // === Actions
    // =====================================================================

    const actionExportFieldBodyClicked = (id: number) => {
        const isSelected = orderData.export_field_ids.includes(id);
        if (isSelected) {
            orderManager.actionRemoveExportField(id);
        } else {
            orderManager.actionAddExportField(id);
        }
    }

    const actionCheckboxClicked = (id: number, isChecked: boolean) => {
        if (isChecked) {
            orderManager.actionAddExportField(id);
        } else {
            orderManager.actionRemoveExportField(id);
        }
    }

    const actionItemBodyMouseEnter = (fieldId: number) => {
        setMouseOverFieldId(fieldId);
    }

    const actionItemBodyMouseExit = () => {
        setMouseOverFieldId(0);
    }

    const actionNext = () => {
        navigate('/order_leads/4');
    }

    const actionBack = () => {
        navigate('/order_leads/2');
    }

    // =====================================================================
    // === Render
    // =====================================================================

    const createFields = () : Array<JSX.Element> => {

        const fields : Array<JSX.Element> = [];

        let exportFieldsData = baseData.export_fields;
        if (userData.isLoggedIn()) {
            exportFieldsData = userData.getExportFields();
        }

        for (const item of exportFieldsData) {

            const isSelected = orderData.export_field_ids.includes(item.id);

            let priceBox = undefined;
            if (userData.isLoggedIn()) {
                const priceObject = userData.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_EXPORT_FIELD, item.id);
                if (priceObject !== undefined) {
                    const price = priceObject.getPriceFloat();
                    if (price > 0) {
                        
                        priceBox = (
                            <Box marginRight={'12px'}>
                                <Chip label={priceObject.getPriceStringFull()} variant={'filled'}  />
                            </Box>
                        );
                    }
                }
            }

            const isHover = item.id === mouseOverFieldId;

            fields.push(
                <Box
                    display={'flex'}
                    sx={{cursor:'pointer', backgroundColor: isHover ? '#f5f5f5' : ''}}
                    key={item.id}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    onClick={() => { actionExportFieldBodyClicked(item.id) }}
                    onMouseEnter={() => {actionItemBodyMouseEnter(item.id)}}
                    onMouseLeave={() => {actionItemBodyMouseExit()}}>
                
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={isSelected}
                            disabled={!orderData.canEditOrder()}
                            color={'secondary'}
                            onClick={(e) => { e.stopPropagation(); }}
                            onChange={(e) => { actionCheckboxClicked(item.id, e.target.checked) }} />
                        <Typography>{item.name}</Typography>
                    </Box>
                    {priceBox}
                </Box>
            );
        }
        return fields;
    }

    return (
        <>
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="h4">{'Välj leverans'}</Typography>
                <Typography variant="subtitle1">{'information att inkludera i leveransfilen.'}</Typography>
                <Box marginTop={'12px'}>
                    {createFields()}
                </Box>
            </Box>
            <Box
                marginTop={4}
                display={'flex'}
                flexDirection={'row'}
                style={{gap: '15px'}}
                justifyContent={'flex-start'}>

                <Button
                    onClick={actionBack}
                    variant={'outlined'}
                    color={"secondary"}
                    size={'large'}>
                        {'Tillbaka'}
                </Button>
                <Button
                    onClick={actionNext}
                    variant={'contained'}
                    color={"secondary"}
                    size={'large'}>
                        {'Nästa'}
                </Button>
            </Box>
        </>
    );
};
