
interface InParams {
    data: any,
    requestCounter: number,
}

export class ApiObjCountOrder {

    public readonly request_counter: number;
    public readonly counts: {
        base: number,
        removed_by_stopfiles: number,
        removed_by_households: number,
        final_count: number
    };

    constructor(inParams: InParams) {
        this.request_counter = inParams.requestCounter;
        const data = inParams.data;
        this.counts = {
            base: parseInt(data.counts.base),
            removed_by_stopfiles: parseInt(data.counts.removed_by_stopfiles),
            removed_by_households: parseInt(data.counts.removed_by_households),
            final_count: parseInt(data.counts.final_count),
        };
    }
}