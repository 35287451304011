import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_2 = async (populateFileId: number, dataOptionIds: Array<number>, cleanLowIncome: number, cleanNix: number, cleanHlr: number) : Promise<ApiObjPopulateFile> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_2';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const body = {
        data_option_ids: dataOptionIds,
        clean_low_income: cleanLowIncome,
        clean_nix: cleanNix,
        clean_hlr: cleanHlr 
    }

    const resp = await Axios.post(
        url,
        body,
        config
    );
        
    return new ApiObjPopulateFile(resp.data);
}