import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdminUserCompact } from '../../object/admin/ApiObjAdminUserCompact';

export const api_admin_get_users = async () : Promise<Array<ApiObjAdminUserCompact>> => {
    
    const url = API_URL+'/admin/user';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserCompact(item));
    }
    return ret;
}
