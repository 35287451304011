export class ApiObjAdAccount {

    // ==============================================================
    // === Fields
    // ==============================================================

    id: number;
    user_id: number;
    name: string;
    remote_id: string;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);
        this.name = data.name;
        this.remote_id = data.remote_id;
    }
}