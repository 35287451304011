export function errorDescription(T, name) {
  return `${name} ${T('too_short', 'form_validation')}`;
}

export function errorMaxDescription(T, name) {
  return `${name} ${T('too_long', 'form_validation')}`;
}

export function required(T, name) {
  return `${name}  ${T('is_required', 'form_validation')}`;
}

export function errorMatches(T, name) {
  return `${name} ${T('contains_forbidden_characters', 'form_validation')}`;
}

export const checkIfOnlySpaces = {
  name: 'isEmpty',
  message: 'Cannot be empty',
  test: (value) => {
    if (value && value.length > 0) return value.replace(/\s/g, '').length;
    else return true;
  }
};
