export class ApiObjPopulateFileOrderStep1 {

    // ==============================================================
    // === Column types
    // ==============================================================

    public static readonly COLUMN_1_PERSONAL_ID = 1;
    public static readonly COLUMN_2_BIRTH_DATE = 2;
    public static readonly COLUMN_3_FIRST_NAME = 3;
    public static readonly COLUMN_4_LAST_NAME = 4;
    public static readonly COLUMN_5_ADDRESS_STREET = 5;
    public static readonly COLUMN_6_ADDRESS_CITY = 6;
    public static readonly COLUMN_7_ZIPCODE = 7;
    public static readonly COLUMN_8_PHONE_NUMBER = 8;

    public static readonly COLUMN_NAMES : Array<{column: number, name: string}> = [
        {column: this.COLUMN_1_PERSONAL_ID, name: 'Personnummer'},
        {column: this.COLUMN_2_BIRTH_DATE, name: 'Födelsedag'},
        {column: this.COLUMN_3_FIRST_NAME, name: 'Förnamn'},
        {column: this.COLUMN_4_LAST_NAME, name: 'Efternamn'},
        {column: this.COLUMN_5_ADDRESS_STREET, name: 'Gatuadress'},
        {column: this.COLUMN_6_ADDRESS_CITY, name: 'Ort'},
        {column: this.COLUMN_7_ZIPCODE, name: 'Postnummer'},
        {column: this.COLUMN_8_PHONE_NUMBER, name: 'Telefonnummer'},
    ];

    // ==============================================================
    // === Fields
    // ==============================================================

    column_types: Array<number>;
    match_mode: number;
    rows: Array<Array<string>>;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.column_types = data.column_types;
        this.match_mode = data.match_mode;
        this.rows = data.rows;
    }
}