import { Box, LinearProgress, Typography, } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';

type Props = {
    startMillis: number,
    estimatedMillis: number,
}

export const EstimatedProgressBar: FunctionComponent<Props> = ({
    startMillis,
    estimatedMillis
}) => {

    // ===========================================================
    // === Update
    // ===========================================================

    const [progress, setProgress] = useState <number > (0);

    useEffect(() => {

        const delay = 500;
        const timer = setInterval(() => {
            setProgress(calculateProgress);
        }, delay);

        return () => {
            if(timer) clearInterval(timer);
        };
    }, []);

    // ===========================================================
    // === Helper
    // ===========================================================

    const calculateProgress = () : number => {
        if (estimatedMillis == 0) {
            return 0;
        }
        const now = Date.now();
        let result = ((now - startMillis) / estimatedMillis) * 100;
        if (result > 100) {
            result = 100;
        }
        return Math.round(result);
    }

    // ===========================================================
    // === Render
    // ===========================================================

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{progress} %</Typography>
            </Box>
        </Box>
    );
}