import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { api_get_api_keys } from '../../../api/endpoint/api_key/api_get_api_keys';
import { ApiObjApiKey } from '../../../api/object/api_key/ApiObjApiKey';
import { api_create_api_key } from '../../../api/endpoint/api_key/api_create_api_key';
import { api_delete_api_key } from '../../../api/endpoint/api_key/api_delete_api_key';
import { ConfirmModal } from '../../shared_components/ConfirmModal';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    userManager: UserManager
}

export const MyUserTabApiKey: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
}) => {

    const [apiKeys, setApiKeys] = useState <Array<ApiObjApiKey>> ([]);
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [visibleModal, setVisibleModal] = useState <string> ('');
    const [selectedKeyId, setSelectedKeyId] = useState <number> (0);

    // ======================================================================
    // === Set form data
    // ======================================================================

    useEffect(() => {
        fetchApiKeys();
    }, []);

    const fetchApiKeys = () => {
        setIsLoading(true);

        api_get_api_keys()
            .then((result) => {
                setApiKeys(result);
                setIsLoading(false);

            }).catch((err) => {
                console.error("Error fetching api keys");
                console.error(err);
            });
    }
    
    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionShowDialogCreate = () => {
        setVisibleModal('create_key');
    }

    const actionShowDialogDelete = (keyId: number) => {
        setSelectedKeyId(keyId);
        setVisibleModal('delete_key');
    }

    const actionCreate = () => {
        setVisibleModal('');
        setIsLoading(true);

        api_create_api_key()
            .then((result) => {
                fetchApiKeys();

            }).catch((err) => {
                setIsLoading(false);
                console.error("Error when creating api key");
                console.error(err);
            });
    }

    const actionDelete = () => {
        setVisibleModal('');
        setIsLoading(true);

        api_delete_api_key(selectedKeyId)
            .then(() => {
                fetchApiKeys();

            }).catch((err) => {
                setIsLoading(false);
                console.error("Error when deleting api key");
                console.error(err);
            })
    }

    const actionCloseModal = () => {
        setVisibleModal('');
    }

    const actionLinkDocumentation = () => {
        const url = 'https://documenter.getpostman.com/view/6024830/TVzVjGUD';
        window.open(url,'_blank');
    }

    // ======================================================================
    // === Render elements
    // ======================================================================

    const renderTableRows = () : Array<JSX.Element> => {
        const elements = [];
        for (const item of apiKeys) {

            elements.push(
                <TableRow
                    key={item.id}>
                    <TableCell>{item.api_key}</TableCell>
                    <TableCell>{item.created_at}</TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            color='secondary'
                            size={'medium'}
                            onClick={() => {actionShowDialogDelete(item.id)}}
                            disabled={isLoading}>
                                Radera
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }
        return elements;
    }

    const tryRenderDialogCreate = () : undefined|JSX.Element => {

        if (visibleModal !== 'create_key') {
            return undefined;
        }
        
        return (
            <ConfirmModal
                title={'Skapa ny API-nyckel?'}
                text={''}
                onYes={actionCreate}
                onCancel={actionCloseModal} />
        );
    }

    const tryRenderDialogDelete = () : undefined|JSX.Element => {

        if (visibleModal !== 'delete_key') {
            return undefined;
        }

        return (
            <ConfirmModal
                title={'Radera API-nyckel?'}
                text={'Detta går inte att ångra.'}
                onYes={actionDelete}
                onCancel={actionCloseModal} />
        );
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} gap={'12px'}>
                
                <Button
                    variant='contained'
                    color='secondary'
                    size={'medium'}
                    onClick={actionShowDialogCreate}
                    disabled={isLoading}>
                    Skapa API-nyckel
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    size={'medium'}
                    onClick={actionLinkDocumentation}>
                    Visa API-dokumentation
                </Button>
            </Box>
            <Box marginTop={'12px'} paddingBottom={'12px'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>API-nyckel</TableCell>
                            <TableCell>Skapad</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </Box>
            {tryRenderDialogCreate()}
            {tryRenderDialogDelete()}
        </Box>
    );
}
