import { ApiObjExportPrice } from '../../api/object/export/ApiObjExportPrice';
import { OrderPriceParams, orderPriceParamsClone, orderPriceParamsIsEqual } from './OrderPriceParams';

/**
 * Holds data for one price calculation for one set or order params.
 */
export class OrderPriceData {

    /** User is not logged in, no price should be fetched. */
    public static readonly STATE_USER_LOGIN_NEEDED = 1;

    /** Item count is not provided yet, cannot make request at this time. */
    public static readonly STATE_ITEM_COUNT_NEEDED = 2;

    /** Waiting for request to be sent. */
    public static readonly STATE_WAITING_FOR_REQUEST_START = 3;

    /** API Request sent, waiting for response. */
    public static readonly STATE_REQUEST_SENT = 4;

    /** A api data ready to be shown in UI. */
    public static readonly STATE_READY = 5;

    public readonly params: OrderPriceParams;

    public api_data: ApiObjExportPrice|undefined;
    public state: number;
    
    // ================================================================
    // === Create
    // ================================================================

    public static createNew(params: OrderPriceParams) : OrderPriceData {
        return new OrderPriceData(params, undefined);
    }

    public static createClone(original: OrderPriceData) : OrderPriceData {
        return new OrderPriceData(undefined, original);
    }

    private constructor(params: OrderPriceParams|undefined, original: OrderPriceData|undefined) {

        if (params !== undefined) {
            this.params = orderPriceParamsClone(params);
            this.api_data = undefined;

            if (!params.has_user) {
                this.state = OrderPriceData.STATE_USER_LOGIN_NEEDED;
            } else if (params.item_count >= 0) {
                this.state = OrderPriceData.STATE_WAITING_FOR_REQUEST_START;
            } else {
                this.state = OrderPriceData.STATE_ITEM_COUNT_NEEDED;
            }
            
        } else if (original !== undefined) {
            this.params = orderPriceParamsClone(original.params);
            this.api_data = original.api_data;
            this.state = original.state;
        } else {
            throw Error("Unexpected case");
        }
    }

    // ================================================================
    // === Checks
    // ================================================================

    isParamsEqual = (params: OrderPriceParams) : boolean => {
        return orderPriceParamsIsEqual(this.params, params);
    }

    isReady = () : boolean => {
        return this.state === OrderPriceData.STATE_READY && this.api_data !== undefined;
    }

    isWaiting = () : boolean => {
        return this.state === OrderPriceData.STATE_ITEM_COUNT_NEEDED
        || this.state === OrderPriceData.STATE_WAITING_FOR_REQUEST_START
        || this.state === OrderPriceData.STATE_REQUEST_SENT;
    }

    // ================================================================
    // === State change
    // ================================================================

    actionRequestSent = (): void => {
        this.state = OrderPriceData.STATE_REQUEST_SENT;
    }

    actionReady = (apiData: ApiObjExportPrice) : void => {
        this.api_data = apiData;
        this.state = OrderPriceData.STATE_READY;
    }
}