import { Box, Typography } from '@mui/material';

const FindEmailPage = () => {
  return (
      <Box>
        <Box mb={2}>
          <Typography variant="h4">{'Kolla din e-post'}</Typography>
        </Box>
        <Typography variant="body1">{'Vi hittade din e-postadress och har skickat dig en länk för att nollställa lösenordet'}</Typography>
      </Box>
  );
};

export default FindEmailPage;