import { MapPoint } from "./MapPoint";

/**
 * A circle shape on the map.
 */
export class MapAreaCircle {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public id: number;
    public centerPoint: MapPoint;
    public radius: number;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = (id: number, centerLat: number, centerLong: number, radius: number) : MapAreaCircle => {
        return new MapAreaCircle(id, centerLat, centerLong, radius);
    }

    public static createClone = (original: MapAreaCircle) : MapAreaCircle => {
        return new MapAreaCircle(original.id, original.centerPoint.latitude, original.centerPoint.longitude, original.radius);
    }

    private constructor(id: number, centerLat: number, centerLong: number, radius: number) {
        this.id = id;
        this.centerPoint = new MapPoint(centerLat, centerLong);
        this.radius = radius;
    }

    public isEqual(other: MapAreaCircle) : boolean {
        return this.centerPoint.latitude === other.centerPoint.latitude
            && this.centerPoint.longitude === other.centerPoint.longitude
            && this.radius === other.radius;
    }
}