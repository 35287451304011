import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminNixEntry } from '../../../object/admin/ApiObjAdminNixEntry';

export const api_admin_get_nix_entry = async (number: string) : Promise<undefined|ApiObjAdminNixEntry> => {
    
    const url = API_URL+'/admin/nix?phone_number='+number;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    );
        
    if (resp.data == undefined || resp.data == '') {
        return undefined;
    }

    return new ApiObjAdminNixEntry(resp.data);
}
