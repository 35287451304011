import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjExportPrice } from '../../object/export/ApiObjExportPrice';

export interface GetExportPriceParams {
    has_user: boolean,
    item_count: number
    nix_option_id: number,
    source_id: number,
    filter_ids: Array<number>,
    uses_map: boolean,
    export_field_ids: Array<number>,
    uses_stopfiles: boolean,
    household_option_id: number,
}

export const api_get_export_price = async (params: GetExportPriceParams) : Promise<ApiObjExportPrice> => {
    
    const url = API_URL+'/export/price';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const data = {
        item_count: params.item_count,
        nix_option_id: params.nix_option_id,
        source_id: params.source_id,
        filter_ids: params.filter_ids,
        uses_map: params.uses_map ? 1 : 0,
        export_field_ids: params.export_field_ids,
        uses_stopfiles: params.uses_stopfiles ? 1 : 0,
        household_option_id: params.household_option_id
    }

    const resp = await Axios.post(
        url,
        data,
        config
    );
        
    return new ApiObjExportPrice(resp.data, params);
}
