import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjExport } from '../../object/export/ApiObjExport';

export const api_get_exports = async () : Promise<Array<ApiObjExport>> => {
       
    const url = API_URL+'/export';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );
        
    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjExport(item));
    }
    return ret;
}
