export class ApiObjSource {

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    name: string;
    sort_order: number;
    is_premium: boolean;
    person_count: number;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.sort_order = parseInt(data.sort_order);
        this.is_premium = parseInt(data.is_premium) === 1;
        this.person_count = parseInt(data.person_count);
    }
}