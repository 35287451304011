import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFileOrderStep4 } from '../../object/populate_file/ApiObjPopulateFileOrderStep4';

export const api_populate_file_order_get_step_4 = async (populateFileId: number, includeBasePrice: boolean) : Promise<ApiObjPopulateFileOrderStep4> => {
    
    const queryParams = '?include_base_price='+(includeBasePrice ? '1':'0');
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_4'+queryParams;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjPopulateFileOrderStep4(resp.data, includeBasePrice);
}
