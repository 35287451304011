import { Box, Paper, } from '@mui/material';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { FunctionComponent } from 'react';
import { UserData } from '../../../data_layer/user/UserData';
import { CompCreateStopfile } from './CompCreateStopfile';

type Props = {
    userData: UserData,
}

export const CreateStopfilePage: FunctionComponent <Props> = ({
    userData,
}) => {

    // ======================================================================
    // === Not logged in
    // ======================================================================

    if (!userData.isLoggedIn()) {
        return <ErrorPageNoUser />;
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Paper>
            <Box p={2}>
                <CompCreateStopfile />
            </Box>
        </Paper>
    );
};
