import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminGlobalPrice } from '../../../object/admin/ApiObjAdminGlobalPrice';

export const api_admin_get_global_prices = async () : Promise<Array<ApiObjAdminGlobalPrice>> => {
        
    const url = API_URL+'/admin/global_price';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );
        
    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminGlobalPrice(item));
    }
    return ret;
}
