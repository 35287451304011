import { ApiObjStopfile } from "../../api/object/stopfile/ApiObjStopfile";

export const clean_stopfiles = (
    ids: Array<number>,
    allMyStopfiles: Array<ApiObjStopfile>,
    includeManual: boolean,
    includeFromExport: boolean,
    includeNotReady: boolean,
) : Array<number> => {

    const stopfilesMap = new Map <number, ApiObjStopfile> ();
    for (const item of allMyStopfiles) {
        stopfilesMap.set(item.id, item);
    }

    const cleanIds = [];
    for (const stopfileId of ids) {

        const tempItem = stopfilesMap.get(stopfileId);
        const include = tempItem !== undefined
            && (includeNotReady || tempItem.state === ApiObjStopfile.STATE_3_SUCCESS)
            && (
                (includeManual && tempItem.create_method === "manual")
                || (includeFromExport && tempItem.create_method === "from_export")
            );

        if (include) {
            cleanIds.push(stopfileId);
        }
    }

    return cleanIds;
}