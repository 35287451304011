import React from 'react';
import '../sass/guideContentComponent.sass';
import { Box, Typography } from '@mui/material';

const GuideContentComponent = ({ img, content }) => {
  return (
    <Box height={300} className="guide-content-container">
      <Box className="content-block">
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: content }}></Typography>
      </Box>
    </Box>
  );
};

export default GuideContentComponent;
