import { Box, Button, InputLabel, MenuItem, Modal, Paper, Select, Typography, } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { ApiObjHouseholdOption } from '../../api/object/household/ApiObjHouseholdOption';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData
    householdOptionId: number,
    onClose: () => void,
    onChange: (householdOptionId: number) => void,
}

export const HouseholdOptionModal: FunctionComponent<Props> = ({
    baseData,
    userData,
    householdOptionId,
    onClose,
    onChange,
}) => {

    
    // ===========================================================
    // === Constants
    // ===========================================================

    const MAIN_1_OFF = 1;
    const MAIN_2_RANDOM = 2;
    const MAIN_3_AGE_MAX = 3;
    const MAIN_4_AGE_MIN = 4;
    const MAIN_5_INCOME_MAX = 5;
    const MAIN_6_INCOME_MIN = 6;
    const MAIN_7_CAPITAL_INCOME_MAX = 7;
    const MAIN_8_CAPITAL_INCOME_MIN = 8;
    const MAIN_9_CAR_COUNT_MAX = 9;

    const GENDER_1_NO_PREFERENCE = 1;
    const GENDER_2_MALE = 2;
    const GENDER_3_FEMALE = 3;

    // ===========================================================
    // === Options map
    // ===========================================================

    const MAPPING_TO_HOUSEHOLD_OPTIONS : Array<{household_id: number, main: number, gender: number}> = [
        {
            household_id: 0,
            main: MAIN_1_OFF,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_RANDOM,
            main: MAIN_2_RANDOM,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_RANDOM,
            main: MAIN_2_RANDOM,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_RANDOM,
            main: MAIN_2_RANDOM,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_AGE_MAX,
            main: MAIN_3_AGE_MAX,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_AGE_MAX,
            main: MAIN_3_AGE_MAX,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_AGE_MAX,
            main: MAIN_3_AGE_MAX,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_AGE_MIN,
            main: MAIN_4_AGE_MIN,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_AGE_MIN,
            main: MAIN_4_AGE_MIN,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_AGE_MIN,
            main: MAIN_4_AGE_MIN,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_INCOME_MAX,
            main: MAIN_5_INCOME_MAX,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_INCOME_MAX,
            main: MAIN_5_INCOME_MAX,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_INCOME_MAX,
            main: MAIN_5_INCOME_MAX,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_INCOME_MIN,
            main: MAIN_6_INCOME_MIN,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_INCOME_MIN,
            main: MAIN_6_INCOME_MIN,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_INCOME_MIN,
            main: MAIN_6_INCOME_MIN,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_CAPITAL_INCOME_MAX,
            main: MAIN_7_CAPITAL_INCOME_MAX,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_CAPITAL_INCOME_MAX,
            main: MAIN_7_CAPITAL_INCOME_MAX,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_CAPITAL_INCOME_MAX,
            main: MAIN_7_CAPITAL_INCOME_MAX,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_CAPITAL_INCOME_MIN,
            main: MAIN_8_CAPITAL_INCOME_MIN,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_CAPITAL_INCOME_MIN,
            main: MAIN_8_CAPITAL_INCOME_MIN,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_CAPITAL_INCOME_MIN,
            main: MAIN_8_CAPITAL_INCOME_MIN,
            gender: GENDER_3_FEMALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_PERSON_CAR_COUNT_MAX,
            main: MAIN_9_CAR_COUNT_MAX,
            gender: GENDER_1_NO_PREFERENCE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_MALE_CAR_COUNT_MAX,
            main: MAIN_9_CAR_COUNT_MAX,
            gender: GENDER_2_MALE,
        },
        {
            household_id: ApiObjHouseholdOption.ID_FEMALE_CAR_COUNT_MAX,
            main: MAIN_9_CAR_COUNT_MAX,
            gender: GENDER_3_FEMALE,
        }
    ];

    // ===========================================================
    // === Conversion
    // ===========================================================

    const getMainOptionFromHouseholdId = (householdId: number) : number => {
        for (const item of MAPPING_TO_HOUSEHOLD_OPTIONS) {
            if (item.household_id === householdId) {
                return item.main;
            }
        }
        console.error("Householdoption modal getMainOptionFromHouseholdId(): case not handled, household id: "+householdId);
        return -1;
    }

    const getGenderFromHouseholdId = (householdId: number) : number => {
        for (const item of MAPPING_TO_HOUSEHOLD_OPTIONS) {
            if (item.household_id === householdId) {
                return item.gender;
            }
        }
        console.error("Householdoption modal getGenderFromHouseholdId(): case not handled, household id: "+householdId);
        return -1;
    }

    // ===========================================================
    // === State
    // ===========================================================

    const [mainOption, setMainOption] = useState <number> (getMainOptionFromHouseholdId(householdOptionId));
    const [gender, setGender] = useState <number> (getGenderFromHouseholdId(householdOptionId));

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionSetMainOption = (newOption: number) => {
        setMainOption(newOption);
    }

    const actionSetGenderOption = (newOption: number) => {
        setGender(newOption);
    }

    const actionCancel = () => {
        onClose();
    }

    const actionConfirm  = () => {

        if (mainOption === MAIN_1_OFF) {
            onChange(0);    // Special case, selected gender irrelevant.
            return;
        }
        
        let foundItem = undefined;
        for (const item of MAPPING_TO_HOUSEHOLD_OPTIONS) {
            if (item.main === mainOption && item.gender === gender) {
                foundItem = item;
            }
        }
        if (foundItem === undefined) {
            console.error("Household Option modal: Unexpected: Could not convert to household_option_id.");
            onChange(0);
            return;
        }

        onChange(foundItem.household_id);
    }

    // ===========================================================
    // === Enabled options
    // ===========================================================

    const getEnabledMainOptions = () : Array<{value: number, name: string}> => {

        const options : Array<{value: number, name: string}> = [
            {value: MAIN_1_OFF, name: 'Ingen hushållsbegränsning'},
            {value: MAIN_2_RANDOM, name: 'Slumpvis person'},
            {value: MAIN_3_AGE_MAX, name: 'Högst ålder'},
            {value: MAIN_4_AGE_MIN, name: 'Lägst ålder'},
            {value: MAIN_5_INCOME_MAX, name: 'Högst inkomst'},
            {value: MAIN_6_INCOME_MIN, name: 'Lägst inkomst'},
            {value: MAIN_7_CAPITAL_INCOME_MAX, name: 'Högst kapitalinkomst'},
            {value: MAIN_8_CAPITAL_INCOME_MIN, name: 'Lägst kapitalinkomst'},
            {value: MAIN_9_CAR_COUNT_MAX, name: 'Flest bilar'},
        ];

        let enabledHouseholdOptions = baseData.household_options;
        if (userData.isLoggedIn()) {
            enabledHouseholdOptions = userData.getHouseholdOptions();
        }
        const mapEnabledHouseholdOptions = new Map<number, boolean> ();
        for (const item of enabledHouseholdOptions) {
            mapEnabledHouseholdOptions.set(item.id, true);
        }

        const finalOptions = [];
        for (const option of options) {
            let enabledFound = false;
            for (const hhOption of MAPPING_TO_HOUSEHOLD_OPTIONS) {
                if (hhOption.main === option.value) {

                    if (hhOption.household_id === 0) {
                        enabledFound = true;
                        break;
                    }
                    if (mapEnabledHouseholdOptions.has(hhOption.household_id)) {
                        enabledFound = true;
                        break;
                    }
                }
            }
            if (enabledFound) {
                finalOptions.push(option);
            }
        }
        return finalOptions;
    }

    const getEnabledGenderOptions = () : Array<{value: number, name: string}> => {

        const options = [
            {value: GENDER_1_NO_PREFERENCE, name: 'Spelar ingen roll'},
            {value: GENDER_2_MALE, name: 'Man i första hand (kvinna om ingen man finns i hushållet).'},
            {value: GENDER_3_FEMALE, name: 'Kvinna i första hand (man om ingen kvinna finns i hushållet)'},
        ];

        let enabledHouseholdOptions = baseData.household_options;
        if (userData.isLoggedIn()) {
            enabledHouseholdOptions = userData.getHouseholdOptions();
        }
        const mapEnabledHouseholdOptions = new Map<number, boolean> ();
        for (const item of enabledHouseholdOptions) {
            mapEnabledHouseholdOptions.set(item.id, true);
        }

        const relevantMappings = [];
        for (const item of MAPPING_TO_HOUSEHOLD_OPTIONS) {
            if (item.main === mainOption) {
                relevantMappings.push(item);
            }
        }

        const finalOptions = [];
        for (const option of options) {
            let enabledFound = false;
            for (const hhOption of relevantMappings) {
                if (hhOption.gender === option.value) {
                    if (mapEnabledHouseholdOptions.has(hhOption.household_id)) {
                        enabledFound = true;
                        break;
                    }
                }
            }
            if (enabledFound) {
                finalOptions.push(option);
            }
        }
        return finalOptions;
    }
    
    // ===========================================================
    // === Render
    // ===========================================================

    const renderMainOption = () : JSX.Element => {

        const options = getEnabledMainOptions();

        const optionElements = [];
        for (const item of options) {
            optionElements.push(
                <MenuItem
                    key={item.value}
                    value={item.value}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box
                marginTop={'12px'}
                display={'flex'}
                flexDirection={'column'}>
                
                <InputLabel id="label_column">Alternativ</InputLabel>
                <Select
                    value={mainOption}
                    onChange={(event) => {
                        if (typeof event.target.value === 'string') {
                            actionSetMainOption(parseInt(event.target.value));
                        } else {
                            actionSetMainOption(event.target.value);
                        }
                    }}>
                    {optionElements}
                </Select>
                
            </Box>
        );
    }

    const tryRenderSelectGender = () : undefined|JSX.Element => {

        if (mainOption <= 1) {
            return undefined;
        }

        const options = getEnabledGenderOptions();

        const optionElements = [];
        for (const item of options) {
            optionElements.push(
                <MenuItem
                    key={item.value}
                    value={item.value}>
                        {item.name}
                </MenuItem>
            );
        }

        return (
            <Box
                marginTop={'12px'}
                display={'flex'}
                flexDirection={'column'}>

                <InputLabel id="label_gender">Kön</InputLabel>
                <Select
                    value={gender}
                    onChange={(event) => {
                        if (typeof event.target.value === 'string') {
                            actionSetGenderOption(parseInt(event.target.value));
                        } else {
                            actionSetGenderOption(event.target.value);
                        }
                    }}>
                    {optionElements}
                </Select>
            </Box>
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        p: 4,
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={actionCancel}
        >
            <Box style={style}>
                <Paper>
                    <Box style={{padding: '12px'}}>
                        <Typography variant="h6">
                            Hushållsbegränsning:
                        </Typography>
                        <Typography style={{marginTop: '2px'}}>
                            Här kan du välja att begränsa urvalet till en person per hushåll.
                        </Typography>
                        <Box display={'flex'} flexDirection={'column'}>
                            {renderMainOption()}
                            {tryRenderSelectGender()}
                        </Box>
                        <Box
                            marginTop={'16px'}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}>
                            
                            <Button
                                variant='contained'
                                color='secondary'
                                size='large'
                                onClick={actionConfirm}>
                                    Ok
                            </Button>

                            <Button
                                variant='outlined'
                                color='secondary'
                                size='large'
                                onClick={actionCancel}>
                                    Avbryt
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}