import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';

export const api_admin_add_number_blacklist_entry = async (number: string) : Promise<void> => {
    
    const url = API_URL+'/admin/number_blacklist?phone_number='+number;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.post(
        url,
        config
    );

    if (resp.data.success !== true) {
        throw new Error("Unexpected response.");
    }
}