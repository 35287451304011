import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFileOrderStep3 } from '../../object/populate_file/ApiObjPopulateFileOrderStep3';

export const api_populate_file_order_get_step_3 = async (populateFileId: number) : Promise<ApiObjPopulateFileOrderStep3> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_3';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjPopulateFileOrderStep3(resp.data);
}
