import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent } from 'react';
import { Box } from '@mui/system';
import { TargetGroupEditData } from '../../../data_layer/target_group/TargetGroupEditData';
import { TargetGroupEditManager } from '../../../data_layer/target_group/TargetGroupEditManager';
import { Checkbox, Typography } from '@mui/material';

type Props = {
    baseData: ApiObjBaseData,
    targetGroupData: TargetGroupEditData,
    targetGroupManager: TargetGroupEditManager,
    userData: UserData,
}

export const TargetGroupEditStep3: FunctionComponent <Props> = ({
    baseData,
    targetGroupData,
    targetGroupManager
}) => {

    // =====================================================================
    // === Actions
    // =====================================================================

    const actionCheckboxClicked = (id: number, isChecked: boolean) => {
        if (isChecked) {
            targetGroupManager.actionAddExportField(id);
        } else {
            targetGroupManager.actionRemoveExportField(id);
        }
    }

    // =====================================================================
    // === Render Elements
    // =====================================================================

    const renderListElements = () : Array<JSX.Element> => {

        const fields : Array<JSX.Element> = [];

        for (const item of baseData.export_fields) {

            const isSelected = targetGroupData.export_field_ids.includes(item.id);

            fields.push(
                <Box display="flex" key={item.id} justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={isSelected}
                        disabled={targetGroupData.state_is_saving}
                        color={'secondary'}
                        onClick={(e) => { e.stopPropagation(); }}
                        onChange={(e) => { actionCheckboxClicked(item.id, e.target.checked) }}/>
                    <Typography>{item.name}</Typography>
                    </Box>
                </Box>
            );
        }
        return fields;
    }

    // ======================================================================
    // === Render Main
    // ======================================================================

    return (
        <Box display={'flex'} flexDirection={'column'}>
            {renderListElements()}
        </Box>
    );
}
