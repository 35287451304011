import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_get_stopfiles = async () : Promise<Array<ApiObjStopfile>> => {
    
    const url = API_URL+'/stopfile';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );
        
    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjStopfile(item));
    }
    return ret;
}