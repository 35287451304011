import { Box, Button, TextField, } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjPriceObjectType } from '../../../api/object/price/ApiObjPriceObjectType';
import { ApiObjPriceObject } from '../../../api/object/price/ApiObjPriceObject';
import { api_admin_user_save_prices } from '../../../api/endpoint/admin/user/price/api_admin_user_save_prices';
import { api_admin_user_get_prices } from '../../../api/endpoint/admin/user/price/api_admin_user_get_prices';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    otherUserId: number
}

export const AdminUserTabPricing: FunctionComponent <Props> = ({
    baseData,
    userData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [mapUserPrices, setMapUserPrices] = useState <Map<number, string>> (new Map());
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [isSaving, setIsSaving] = useState <boolean> (false);
    const isActive = !isLoading && !isSaving;

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        api_admin_user_get_prices(otherUserId)
            .then((result) => {
                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item.price_object_id, item.price + '');
                }
                setMapUserPrices(newMap);
                setIsLoading(false);
            });

    }, [otherUserId]);

    // ======================================================================
    // === Actions
    // ======================================================================
  
    const actionSavePriceObjects = () => {

        setIsSaving(true);

        const prices : Array<{price_object_id: number, price: number}> = [];
        mapUserPrices.forEach((value: string, key: number) => {

            const includeValue = !isNaN(parseFloat(value)) && parseFloat(value) >= 0;
            if (includeValue) {
                prices.push({
                    price_object_id: key,
                    price: parseFloat(value)
                });
            }
        });

        api_admin_user_save_prices(otherUserId, prices)
            .then((result) => {

                const newMap = new Map();
                for (const item of result) {
                    newMap.set(item.price_object_id, item.price + '');
                }
                setMapUserPrices(newMap);
                setIsSaving(false);

            }).catch((err) => {
                console.error("Could not save prices");
                console.error(err);
            });
    }

    const actionOnChangePrice = (priceObjectId: number, newValue: string) => {
        newValue = newValue.replace(',','.');
        const newMap = new Map(mapUserPrices);
        newMap.set(priceObjectId, newValue);
        setMapUserPrices(newMap);
    }
    
    // ======================================================================
    // === Render
    // ======================================================================

    const renderPricing = () : JSX.Element => {

        const sectionElements = [];
        for (const item of baseData.price_object_types) {
            sectionElements.push(
                renderSection(item)
            );
        }

        return (
            <Box width={'600px'}>
                {sectionElements}
            </Box>
        );
    }

    const renderSection = (priceObjectType: ApiObjPriceObjectType) : JSX.Element => {

        const itemElements = [];
        for (const item of baseData.price_objects) {
            if (item.object_type_id === priceObjectType.id) {
                itemElements.push(renderItem(item));
            }
        }

        return (
            <Box key={priceObjectType.id} display={'flex'} flexDirection={'column'}>
                <Box paddingTop={'6px'} paddingBottom={'6px'}  style={{fontWeight: 'bold'}}>
                    {priceObjectType.name}
                </Box>
                <Box paddingLeft={'10px'}>
                    {itemElements}
                </Box>
            </Box>
        );
    }

    const renderItem = (priceObject: ApiObjPriceObject) : JSX.Element => {

        let value = mapUserPrices.get(priceObject.id);
        if (value === undefined) {
            value = '';
        }

        const isValueValid = (
            value === ''
            || (
                !isNaN(parseFloat(value))
                && parseFloat(value) >= 0
            )
        );

        return (
            <Box
                key={priceObject.id}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignContent={'center'}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {priceObject.name}
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}>

                        <Box>
                            {priceObject.price_global}
                        </Box>
                        <Box width={'80px'} marginLeft={'12px'}>
                            <TextField
                                type={'number'}
                                disabled={!isActive}
                                error={!isValueValid}
                                fullWidth={true}
                                value={value}
                                size='small'
                                onChange={(event) => {actionOnChangePrice(priceObject.id, event.target.value)}} />
                        </Box>
                    </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box paddingTop={'10px'} paddingBottom={'10px'}>
                <Button variant='contained' color='secondary' onClick={actionSavePriceObjects} disabled={!isActive}>
                    Spara
                </Button>
            </Box>
            {renderPricing()}
            <Box paddingTop={'10px'} paddingBottom={'10px'}>
                <Button variant='contained' color='secondary' onClick={actionSavePriceObjects} disabled={!isActive}>
                    Spara
                </Button>
            </Box>
        </Box>
    );
}
