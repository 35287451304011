import { Paper, Box, Tabs, Tab } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';

import { UserManager } from '../../../data_layer/user/UserManager';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { MyUserTabDetails } from './MyUserTabDetails';
import { MyUserTabApiKey } from './MyUserTabApiKey';
import { MyUserTabChangePassword } from './MyUserTabChangePassword';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    userManager: UserManager
}

export const MyUserMainPage: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
}) => {

    // ======================================================================
    // === Not logged in
    // ======================================================================

    if (!userData.isLoggedIn()) {
        return <ErrorPageNoUser />;
    }

    // ======================================================================
    // === Setup
    // ======================================================================

    const navigate = useNavigate();
    const { tab_name } = useParams();

    // ======================================================================
    // === Render
    // ======================================================================

    const renderTabs = () : undefined|JSX.Element => {

        let curTab = tab_name;
        if (curTab === undefined) {
            curTab = 'details';
        }

        return (
            <Box>
                <Tabs value={curTab} onChange={(event, value) => { navigate('/my_user/'+value) }}>
                    <Tab value={'details'} label="Användarinformation" />
                    <Tab value={'change_password'} label="Ändra lösenord" />
                    <Tab value={'api_key'} label="API-nyckel" />
                </Tabs>
            </Box>
        );
    }

    const renderCurrentPage = (): JSX.Element|undefined => {
        switch(tab_name) {
            case undefined:
            case 'details':
                return (
                    <MyUserTabDetails
                        baseData={baseData}
                        userData={userData}
                        userManager={userManager}
                    />
                );
            case 'change_password':
                return (
                    <MyUserTabChangePassword
                        baseData={baseData}
                        userData={userData}
                        userManager={userManager}
                    />
                );
            case 'api_key':
                return (
                    <MyUserTabApiKey
                        baseData={baseData}
                        userData={userData}
                        userManager={userManager}
                    />
                );
        }
        return undefined;
    }

    return (
        <Box>
            <Box>
                <Paper>
                    {renderTabs()}
                </Paper>
            </Box>
            <Box marginTop={'20px'}>
                <Paper>
                    {renderCurrentPage()}
                </Paper>
            </Box>
        </Box>
    );
}
