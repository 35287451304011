import { ErrorPageNoAdmin } from "../error/ErrorPageNoAdmin";

export const AdminDashboardPage = ({
  userData
}) => {

  if (!userData.isLoggedInAndAdmin()) {
    return <ErrorPageNoAdmin />;
  }

  return (
    <div>
      Admin Panel
    </div>
  );
};
