import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SuccessPasswordPage = () => {
  const navigate = useNavigate();
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h4">{'Ditt lösenord har ändrats!'}</Typography>
      <Typography variant="body1" align="center">
        {'Nu kan du logga in med ditt nya lösenord.'}
      </Typography>
      <Box mt={5} display={'flex'} justifyContent={'center'}>
        <Button
          type={'submit'}
          variant={'contained'}
          color={'secondary'}
          size={'large'}
          onClick={() => {navigate('/account/login')}}>
            {'Logga in'}
        </Button>
      </Box>
    </Box>
  );
};
