import { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Button, Box, IconButton } from '@mui/material';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { UserData } from '../../../../data_layer/user/UserData';
import { ApiObjBaseData } from '../../../../api/object/base_data/ApiObjBaseData';
import ArticleIcon from '@mui/icons-material/Article';
import { ApiObjPopulateFile } from '../../../../api/object/populate_file/ApiObjPopulateFile';
import { api_populate_file_get_all } from '../../../../api/endpoint/populate_file/api_populate_file_get_all';
import { PopulateFileDownloadModal } from '../../../shared_components/PopulateFileDownloadModal';
import dayjs from 'dayjs';
import { PopulateInfoModal } from '../../../shared_components/PopulateInfoModal';
import { util_format_datetime } from '../../../../util/format/util_format';

type Props = {
    search: string;
    baseData: ApiObjBaseData,
    userData: UserData,
    selectedDate: dayjs.Dayjs | null
}

export const PopulateFileList: FunctionComponent <Props> = ({
    search,
    baseData,
    userData,
    selectedDate
}) => {
  
    const [populateFileObjs, setPopulateFileObjs] = useState <undefined|Array<ApiObjPopulateFile>> (undefined);
    const [downloadModalObj, setDownloadModalObj] = useState <undefined|ApiObjPopulateFile> (undefined);
    const [infoModalPopulateObj, setInfoModalPopulateObj] = useState <undefined|ApiObjPopulateFile> (undefined);

    useEffect(() => {
        fetchPopulateFile();
        const intervalId = setInterval(() => {
            fetchPopulateFile();
        }, 5000)
        return () => clearInterval(intervalId)
    }, []);
  
    const fetchPopulateFile = async () => {
        api_populate_file_get_all().then((resp) => {
            const result = [];
            for (const item of resp) {
                if (item.state === ApiObjPopulateFile.STATE_4_PARSE_STARTED || 
                    item.state === ApiObjPopulateFile.STATE_7_BUILD_STARTED ||
                    item.state === ApiObjPopulateFile.STATE_13_READY) {
                    result.push(item);
                }
            }
            setPopulateFileObjs(result);
        });
    }

    const actionOpenInfoModal = (populateFileId: number) => {

        if (populateFileObjs === undefined) {
            return;
        }

        let foundPopulate = undefined;
        for (const item of populateFileObjs) {
            if (item.id === populateFileId) {
                foundPopulate = item;
                break;
            }
        }
        setInfoModalPopulateObj(foundPopulate);
    }
    
    const actionOpenDownloadModal = (populateFileId: number) => {

        if (populateFileObjs === undefined) {
            return;
        }

        let foundObj = undefined;
        for (const item of populateFileObjs) {
            if (item.id === populateFileId) {
                foundObj = item;
                break;
            }
        }
        setDownloadModalObj(foundObj);
    }
    
    const columns = [

        {
            field: 'name',
            headerName: 'Namn',
            width: 250,
            editable: false,
        },
        {
            field: 'date',
            headerName: 'Datum',
            width: 200,
            editable: false,
        },
        {
            field: 'count',
            headerName: 'Antal',
            width: 120,
            editable: false,
        },
        {
            field: 'details',
            headerName: 'Detaljer',
            width: 100,
            editable: false,
            renderCell: (params: any) => {
                
                return (
                    <Box>
                        <IconButton
                            color={'secondary'}
                            onClick={(e) => {
                                actionOpenInfoModal(params.row.download)
                            }}>
                                <ArticleIcon />
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 100,
            editable: false,
        },
        {
            field: 'download',
            headerName: 'Val',
            width: 200,
            editable: false,
            renderCell: (params: any) => {

                return (
                    <Box display={'flex'} flexDirection={'row'}>
                        <Button
                            variant={'contained'}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            startIcon={<CloudDownload />}
                            onClick={(e) => {
                                actionOpenDownloadModal(params.row.download)
                            }}>
                                {'Ladda ned'}
                        </Button>
                    </Box>
                );
            }
        },
    ];

    const getRows = () => {

        if (populateFileObjs === undefined) {
            return [];
        }

        const ret = [];
        
        const nameSearchList = [...populateFileObjs].filter((item) => item.params.name.toLowerCase().includes(search.toLowerCase()))
        const dateSearchList = nameSearchList.filter((item) => dayjs(item.created_at).format("DD/MM/YYYY") === dayjs(selectedDate).format("DD/MM/YYYY"))

        for (const item of selectedDate ? dateSearchList : nameSearchList) {
            ret.push({
                id: item.id,
                name: item.params.name,
                date: util_format_datetime(item.created_at),
                count: item.count.matches,
                state: item.getStateName(),
                download: item.id
            });
        }
        return ret;
    }

    const tryRenderInfoModal = () : undefined|JSX.Element => {

        if (infoModalPopulateObj === undefined) {
            return undefined;
        }

        const actionClose = () => {
            setInfoModalPopulateObj(undefined);
        }
        
        return (
            <PopulateInfoModal
                baseData={baseData}
                userData={userData}
                populateObj={infoModalPopulateObj}
                onClose={actionClose}
            />
        );
    }

    const tryRenderDownloadModal = () : undefined|JSX.Element => {

        if (downloadModalObj === undefined) {
            return undefined;
        }

        const actionClose = () => {
            setDownloadModalObj(undefined);
        }

        return (
            <PopulateFileDownloadModal
                baseData={baseData}
                userData={userData}
                populateFileObj={downloadModalObj}
                useAdminUrl={false}
                onClose={actionClose}
            />
        );
    }

    return (
        <Paper style={{height: '670px'}}>
            <DataGrid
                rows={getRows()}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection={false}
                disableSelectionOnClick
                isRowSelectable={() => {return false;}}
            />
            {tryRenderInfoModal()}
            {tryRenderDownloadModal()}
        </Paper>
    );
};
