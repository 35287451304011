import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdminUser } from '../../object/admin/ApiObjAdminUser';

export const api_admin_get_user = async (userId: number) : Promise<ApiObjAdminUser> => {

    const url = API_URL+'/admin/user/'+userId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjAdminUser(resp.data);
}
