import { Box, Modal, Paper, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { UserData } from '../../data_layer/user/UserData';
import { CompCreateStopfile } from '../main/create_stopfile/CompCreateStopfile';

type Props = {
    userData: UserData
    onClose: () => void
}

export const CreateStopFileModal: FunctionComponent<Props> = ({
    onClose,
    userData
}) => {

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '1028px',
        height: '728px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    if (!userData.isLoggedIn()) {
        return(
            <Paper style={{padding: '20px'}}>
                <Typography align='center'>Du måste vara inloggad för att kunna ladda upp</Typography>
            </Paper>
        );
    }

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        maxHeight={'728px'}
                        padding={'14px'}>
                            <CompCreateStopfile/>
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}