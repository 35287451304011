export class ApiObjPriceObjectType {

    // ==============================================================
    // === Hardcoded ids
    // ==============================================================

    public static readonly ID_BASE_PRICE_NIX_OPTION = 1;
    public static readonly ID_BASE_PRICE_SOURCE = 2;
    public static readonly ID_LEAD_PRICE_NIX_OPTION = 3;
    public static readonly ID_LEAD_PRICE_SOURCE = 4;
    public static readonly ID_LEAD_PRICE_FILTER = 5;
    public static readonly ID_LEAD_PRICE_MAP = 6;
    public static readonly ID_LEAD_PRICE_EXPORT_FIELD = 7;
    public static readonly ID_LEAD_PRICE_STOPFILES = 8;
    public static readonly ID_LEAD_PRICE_HOUSEHOLD = 9;
    public static readonly ID_POPULATE_FILE_BASE_PRICE = 10;
    public static readonly ID_POPULATE_FILE_NEW_DATA = 11;
    public static readonly ID_POPULATE_FILE_CLEAN = 12;

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    name: string;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
    }
}