import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';

export const api_admin_get_number_blacklist_entry = async (number: string) : Promise<undefined|string> => {
    
    const url = API_URL+'/admin/number_blacklist?phone_number='+number;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );
        
    if (resp.data == undefined || resp.data == '') {
        return undefined;
    }
    return resp.data.phone_number;
}
