import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, LinearProgress, LinearProgressProps, MenuItem, Select, SelectChangeEvent, Switch, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import { api_get_stopfiles } from '../../../api/endpoint/stopfile/api_get_stopfiles';
import { StaticPopulateFileMatchType } from '../../../api/constants/populate_file/StaticPopulateFileMatchType';
import { api_populate_file_order_post_step_3 } from '../../../api/endpoint/populate_file/api_populate_file_order_post_step_3';
import { api_populate_file_order_get_step_3 } from '../../../api/endpoint/populate_file/api_populate_file_order_get_step_3';
import { ApiObjPopulateFileOrderStep3 } from '../../../api/object/populate_file/ApiObjPopulateFileOrderStep3';
import moment from 'moment';
import { CreateStopFileModal } from '../../shared_components/CreateStopFileModal';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    populateFile: ApiObjPopulateFile,
    parentSetPopulateFile: (populateFile: ApiObjPopulateFile) => void
}

export const OrderStep3Stopfiles: FunctionComponent <Props> = ({
    baseData,
    userData,
    populateFile,
    parentSetPopulateFile
}) => {

    const STATE_LOADING = 1;
    const STATE_IDLE = 2;
    const STATE_SAVING = 3;
    const STATE_WAITING_FOR_COMPLETION = 4;

    const [state, setState] = useState <number> (STATE_LOADING);
    const [createdFileType, setCreatedFileType] = useState(0)
    const [stopfileMonthSelected, setStopFileMonthSelected] = useState(0)
    const [orderStep3Obj, setOrderStep3Obj] = useState <undefined|ApiObjPopulateFileOrderStep3> (undefined);
    const [stopfileObjs, setStopfileObjs] = useState <Array<ApiObjStopfile>> ([]);
    const [createStopFile, setCreateStopFile] = useState(false)
    const [formCleanStopfile, setFormCleanStopfile] = useState <number> (ApiObjPopulateFile.CLEAN_SETTING_MARK);
    const [formStopfileIds, setFormStopfileIds] = useState <Map<number, number>> (new Map());
    const navigate = useNavigate();

    const handleOnLoad = () => {
        setState(STATE_LOADING);

        const p1 = api_populate_file_order_get_step_3(populateFile.id);
        const p2 = api_get_stopfiles();

        Promise.all([p1, p2])
            .then((result) => {

                setOrderStep3Obj(result[0]);
                setStopfileObjs(result[1]);
                setState(STATE_IDLE);
                
                //setFormCleanStopfile(populateFile.params.clean_stopfile);

                const mapStopfiles = new Map();
                for (const item of populateFile.params.clean_stopfile_ids) {
                    mapStopfiles.set(item, item);
                }
                setFormStopfileIds(mapStopfiles);

            }).catch((err) => {
                // TODO feedback till användaren.
            });
    }

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const delay = populateFile.count.rows * 0.0013861 * 100;
        //const delay = populateFile.count.rows <= 10000 ? 500 : populateFile.count.rows <= 50000 ? 1000 : 1500;
        let timer: NodeJS.Timer | undefined;
        if(state === STATE_LOADING || state === STATE_SAVING || state === STATE_WAITING_FOR_COMPLETION){
            if(timer) clearInterval(timer)
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 90 ? 95 : prevProgress + 10));
            }, delay);
        }
        return () => {
        if(timer) clearInterval(timer);
        };
    }, [state]);

    useEffect(() => {
        handleOnLoad()
    }, [populateFile.id]);

    useEffect(() => {
        if (state === STATE_WAITING_FOR_COMPLETION) {
            if (populateFile.state === ApiObjPopulateFile.STATE_9_BUILD_COMPLETE) {
                setState(STATE_IDLE);
                navigate('/populate_file/'+populateFile.id+'/order_step_4');
            } else if (populateFile.state === ApiObjPopulateFile.STATE_8_BUILD_FAILED) {
                setState(STATE_IDLE);
                // TODO hantera fel.
            }
        }
    }, [populateFile.state]);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionNext = () => {
        if (state !== STATE_IDLE) {
            return;
        }
        setState(STATE_SAVING);
        
        const stopfileIds = Array.from(formStopfileIds, ([key, value]) => (key));

        const formCleanStopfile_option = stopfileIds.length ? formCleanStopfile : ApiObjPopulateFile.CLEAN_SETTING_OFF

        api_populate_file_order_post_step_3(populateFile.id, formCleanStopfile_option, stopfileIds)
            .then((result) => {
                setState(STATE_WAITING_FOR_COMPLETION);
                parentSetPopulateFile(result);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    // TODO ERIK byt denna mot EstimatedProgressBar
    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }

    const renderCleanOptions = () : undefined|JSX.Element => {
        return (
            <Box display={'flex'} flexDirection={'column'} width={"35rem"}>
                <Box marginBottom={"1rem"}><b>Tvätt</b></Box>
                <Select style={{marginBottom: "0.75rem"}} value={formCleanStopfile} onChange={(e) => {
                    if (typeof e.target.value === 'string') {
                        setFormCleanStopfile(parseInt(e.target.value));
                    } else {
                        setFormCleanStopfile(e.target.value);
                    }
                }}>
                        <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_MARK} value={ApiObjPopulateFile.CLEAN_SETTING_MARK}>Stoppfiler (markera)</MenuItem>
                        <MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_DELETE} value={ApiObjPopulateFile.CLEAN_SETTING_DELETE}>Stoppfiler (ta bort)</MenuItem>
                        {/*<MenuItem key={ApiObjPopulateFile.CLEAN_SETTING_OFF} value={ApiObjPopulateFile.CLEAN_SETTING_OFF}>Stoppfiler (ignorera)</MenuItem>*/}
                </Select>
            </Box>
        );
    }

    const renderStopfiles = () : undefined|JSX.Element => {
        const list = createdFileType === 1 ? [...stopfileObjs].filter((item) => !item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : createdFileType === 2 ? [...stopfileObjs].filter((item) => item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : [...stopfileObjs].filter((item) => item.state === ApiObjStopfile.STATE_3_SUCCESS)
        const checkBoxes = [];
        for (const item of list) {

            if (item.state !== ApiObjStopfile.STATE_3_SUCCESS) {
                continue;
            }

            checkBoxes.push(
                <Box
                    key={item.id}
                    display={'flex'}
                    flexDirection={'row'}
                    alignContent={'center'} marginLeft={"-0.6rem"}>
                        <Box>
                            <Checkbox
                                checked={formStopfileIds.has(item.id)}
                                disabled={state !== STATE_IDLE}
                                color={'secondary'}
                                onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    const newMap = new Map(formStopfileIds);
                                    if (isChecked) {
                                        newMap.set(item.id, item.id);
                                    } else {
                                        newMap.delete(item.id);
                                    }
                                    setFormStopfileIds(newMap);
                                }}/>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            {item.name}
                        </Box>
                </Box>
            );
        }

        const menuItemTimeSincCreate : Array<JSX.Element> = [];
        menuItemTimeSincCreate.push(
            <MenuItem
                key={0}
                value={0}>
                    {'Välj'}
            </MenuItem>
        );
        const options = [
            { value: 30, name: '30 dagar'},
            { value: 60, name: '2 månader'},
            { value: 90, name: '3 månader'},
            { value: 120, name: '4 månader'},
            { value: 150, name: '5 månader'},
            { value: 180, name: '6 månader'},
            { value: 210, name: '7 månader'},
            { value: 240, name: '8 månader'},
            { value: 270, name: '9 månader'},
            { value: 300, name: '10 månader'},
            { value: 330, name: '11 månader'},
            { value: 365, name: '12 månader'},
        ];
        for (const op of options) {
            menuItemTimeSincCreate.push(
                <MenuItem
                    key={op.value}
                    value={op.value}>
                        {op.name}
                </MenuItem>
            );
        }

        const isAllChecked = formStopfileIds.size > 0 
        && list.filter((item) => formStopfileIds.has(item.id)).length === list.length

        const actionCheckAllClicked = () => {
            const idsToAdd = new Map();
            if (list.filter((item) => formStopfileIds.has(item.id)).length === list.length) {
                formStopfileIds.clear()
                setFormStopfileIds(idsToAdd)
            } else {
                for (const item of list) {
                    idsToAdd.set(item.id, item.id);
                }
                setFormStopfileIds(idsToAdd)
            }
        }

        const actionHandleMonthsChange = (event: SelectChangeEvent<number>) => {
            setStopFileMonthSelected(Number(event.target.value))
            const maxDays = event.target.value;
            if (typeof maxDays !== 'number') {
                return;
            }
    
            const idsToSelect = new Map();
            const now = moment();
    
            for (const item of list) {
                const createdAt = moment(item.created_at);
                const diffDays = now.diff(createdAt, 'days');
                if (diffDays <= maxDays) {
                    idsToSelect.set(item.id, item.id);
                }
            }
            setFormStopfileIds(idsToSelect)
        }

        return (
            <Box display={'flex'} flexDirection={'column'}>
                <Box style={{width: "35rem"}}>
                    <Box>Stoppfiler</Box>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={createdFileType}
                        onChange={(e, value) => setCreatedFileType(value)}
                        aria-label="Platform"
                        style={{marginTop: "1rem"}}
                        >
                        <ToggleButton value={0}>Alla ({[...stopfileObjs].filter((item) => item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                        <ToggleButton value={1}>Egna ({[...stopfileObjs].filter((item) => !item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                        <ToggleButton value={2}>System ({[...stopfileObjs].filter((item) => item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                        </ToggleButtonGroup>
                    <Box 
                        mb={2}
                        display="flex"
                        flexDirection={'row'}
                        key={0}
                        justifyContent="space-between"
                        alignItems="center" marginLeft={"-0.6rem"}>

                        <Box display="flex" alignItems="center">
                            <Checkbox 
                                checked={isAllChecked}
                                disabled={state !== STATE_IDLE} 
                                color={'secondary'}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e) => { actionCheckAllClicked() }}
                            />
                            <Typography>Välj Alla</Typography>
                        </Box>
                        <Box display="flex" flexDirection={'row'} alignItems="center">
                            <Box>
                                <Typography>Skapad inom</Typography>
                            </Box>
                            <Box marginLeft={1}>
                                <Select
                                    value={stopfileMonthSelected}
                                    onChange={(event) => {actionHandleMonthsChange(event)}}
                                    >
                                        {menuItemTimeSincCreate}
                                </Select>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ height: "10rem", overflowY: "auto" }}>{checkBoxes}</Box>
                </Box>
            </Box>
        );
    }

    const renderButtonNext = () : JSX.Element => {
        return (
            <Button
                variant='contained'
                disabled={state !== STATE_IDLE}
                onClick={actionNext}>
                    Bekräfta
            </Button>
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <>
            {
                state === STATE_LOADING ? <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}><CircularProgress/></Box> :
                state === STATE_SAVING || state === STATE_WAITING_FOR_COMPLETION ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}>
                        <Box sx={{ width: '50%' }}>
                            <LinearProgressWithLabel variant="determinate" value={progress} />
                        </Box>
                    </Box>
                ) : (
                    <Box mt={2} display={'flex'} flexDirection={'column'}>
                        <Box>
                            {renderCleanOptions()}
                            <Button variant="contained" onClick={() => setCreateStopFile(true)}>Ladda upp fil</Button>
                        </Box>
                        <Box marginTop={'28px'}>
                            {renderStopfiles()}
                        </Box>
                        <Box marginTop={'28px'} style={{overflowX: 'auto'}}>
                            {renderButtonNext()}
                        </Box>
                        {
                            createStopFile && (
                                <CreateStopFileModal onClose={() => {setCreateStopFile(false); handleOnLoad()}} userData={userData}/>
                            )
                        }
                    </Box>
                )
            }
        </>
    );
};
