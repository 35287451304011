import { Box, Button, CircularProgress, IconButton, Paper, Popover, Tab, Tabs, Typography } from '@mui/material';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { TargetGroupEditManager } from '../../../data_layer/target_group/TargetGroupEditManager';
import { api_get_target_group } from '../../../api/endpoint/target_group/api_get_target_group';
import { TargetGroupEditData } from '../../../data_layer/target_group/TargetGroupEditData';
import { ArrowBack } from '@mui/icons-material';
import { TargetGroupEditStep1 } from './TargetGroupEditStep1';
import { TargetGroupEditStep2 } from './TargetGroupEditStep2';
import { TargetGroupEditStep3 } from './TargetGroupEditStep3';
import { TargetGroupEditStep4 } from './TargetGroupEditStep4';
import { api_get_stopfiles } from '../../../api/endpoint/stopfile/api_get_stopfiles';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import { CountOrderData } from '../../../data_layer/count/order/CountOrderData';
import { api_delete_target_group } from '../../../api/endpoint/target_group/api_delete_target_group';
import { util_format_number } from '../../../util/format/util_format';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
}

let targetGroupEditManager: TargetGroupEditManager|undefined = undefined;

export const TargetGroupEditPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    // ======================================================================
    // === Setup
    // ======================================================================

    if (!userData.isLoggedIn()) {
        targetGroupEditManager = undefined;
        return <ErrorPageNoUser />;
    }

    const navigate = useNavigate();
    const { target_group_id, tab_id } = useParams();
    const [targetGroupEditData, setTargetGroupEditData] = useState <TargetGroupEditData|undefined> (undefined);
    const [stopfiles, setStopfiles] = useState <Array<ApiObjStopfile>> ([]);

    const [deleteAnchorEl, setDeleteAnchorEl] = useState <undefined|EventTarget & HTMLButtonElement> (undefined);
    const [deleteIsOpen, setDeleteIsOpen] = useState <boolean> (false);

    if (target_group_id === undefined || isNaN(parseInt(target_group_id))) {
        throw new Error("Id is missing");
    }
    
    useEffect(() => {
        targetGroupEditManager = undefined;
        fetchData();
      }, [target_group_id]);

    const fetchData = () => {

        const promise1 = api_get_target_group(parseInt(target_group_id));
        const promise2 = api_get_stopfiles();

        Promise.all([promise1, promise2])
            .then((values) => {

                const tempTargetGroup = values[0];
                const tempStopfiles = values[1];

                targetGroupEditManager = new TargetGroupEditManager(baseData, userData, tempTargetGroup, tempStopfiles);
                targetGroupEditManager.parentSetCallback(callbackNewData);
                setStopfiles(tempStopfiles);
            })
            .catch((e) => {
                console.error("Could not fetch target group and/or stopfiles.");
            });
    }

    // ======================================================================
    // === Callbacks
    // ======================================================================

    const callbackNewData = (newData: TargetGroupEditData) : void => {
        setTargetGroupEditData(newData);
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSave = () => {
        if (targetGroupEditManager !== undefined) {
            targetGroupEditManager.actionSave();
        }
    }

    const actionDeleteOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDeleteAnchorEl(event.currentTarget);
        setDeleteIsOpen(true);
    }

    const actionDeleteClose = () => {
        setDeleteAnchorEl(undefined);
        setDeleteIsOpen(false);
    }

    const actionDeleteExceute = () => {
        api_delete_target_group(parseInt(target_group_id))
            .then(() => {
                setDeleteAnchorEl(undefined);
                setDeleteIsOpen(false);
                navigate('/my_target_groups');
            }).catch((err) => {
                console.error("Could not delete target group.");
                console.error(err);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const renderTitle = () : JSX.Element => {
        if (targetGroupEditData === undefined) {
            return <CircularProgress size={30} />
        } else {
            let titleName = targetGroupEditData.name;
            if (titleName.length > 25) {
                titleName = titleName.substring(0, 25) + '...';
            }
            return (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Typography variant='h6'>{titleName}</Typography>
                </Box>
            );
        }
    }

    const renderCountBox = () : undefined|JSX.Element => {

        if (targetGroupEditData === undefined) {
            return undefined;
        }

        let countElementSelection = undefined;
        let countElementWithStopfiles = undefined;

        if (targetGroupEditData.order_count === undefined || targetGroupEditData.order_count.state !== CountOrderData.STATE_READY) {
            countElementSelection = (
                <CircularProgress size={10} />
            );
            countElementWithStopfiles = (
                <CircularProgress size={10} />
            );

        } else {
            const apiData = targetGroupEditData.order_count.api_data;
            if (apiData !== undefined) {
                countElementSelection = (util_format_number(apiData.counts.base));
                countElementWithStopfiles = (util_format_number(apiData.counts.final_count));
            }
        }

        return (
            <Box display={'flex'} flexDirection={'row'}>
                <Box display={'flex'} flexDirection={'column'} alignContent={'center'}>
                    <Box>
                        Urval:
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
                        {countElementSelection}
                    </Box>
                </Box>
                <Box marginLeft={'30px'} display={'flex'} flexDirection={'column'} alignContent={'center'}>
                    <Box>
                        Slutligt antal:
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
                        {countElementWithStopfiles}
                    </Box>
                </Box>
            </Box>
        );
    }

    const renderActionButtons = () : JSX.Element => {

        const saveEnabled = targetGroupEditData !== undefined
            && !targetGroupEditData.state_is_saving
            && targetGroupEditData.has_unsaved_changes;

        return (
            <Box display={'flex'} flexDirection={'row'}>
                <Box>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        size={'large'}
                        disabled={targetGroupEditData === undefined || targetGroupEditData.state_is_saving}
                        onClick={(event) => {actionDeleteOpen(event)}}>
                            Radera målgrupp
                    </Button>
                    <Popover
                        id={'delete_popover'}
                        open={deleteIsOpen}
                        anchorEl={deleteAnchorEl}
                        onClose={actionDeleteClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>

                        <Box display={'flex'} flexDirection={'column'} padding={'14px'}>
                            <Typography>Vill du radera målgruppen?</Typography>
                            <Box display={'flex'} flexDirection={'row'} paddingTop={'14px'}>
                                <Button
                                    variant='outlined'
                                    onClick={actionDeleteExceute}>
                                    Ja
                                </Button>
                                <Button
                                    sx={{marginLeft: '14px'}}
                                    variant='outlined'
                                    onClick={actionDeleteClose}>
                                    Nej
                                </Button>
                            </Box>
                        </Box>
                        
                    </Popover>
                </Box>
                <Button
                    style={{marginLeft: '10px'}}
                    variant={'contained'}
                    color={'secondary'}
                    disabled={!saveEnabled}
                    onClick={actionSave}>
                        Spara
                </Button>
            </Box>
        );
    }

    const renderTabs = () : undefined|JSX.Element => {

        if (targetGroupEditData === undefined) {
            return undefined;
        }

        let currentValue = 1;
        if (tab_id !== undefined) {
            currentValue = parseInt(tab_id);
        }

        return (
            <Tabs value={currentValue} onChange={(event, value) => { navigate('/my_target_groups/'+target_group_id+'/'+value) }}>
                <Tab value={1} label="Bas" />
                <Tab value={2} label="Urval" />
                <Tab value={3} label="Leveransfält" />
                <Tab value={4} label="Stoppfiler" />
            </Tabs>
        );
    }

    const renderMainPage = () => {

        if (targetGroupEditData === undefined || targetGroupEditManager === undefined) {
            return (<CircularProgress size={30} />);
        }

        let element = undefined;
        if (tab_id === undefined || tab_id === '1') {
            element = (
                <TargetGroupEditStep1
                    baseData={baseData}
                    targetGroupData={targetGroupEditData}
                    targetGroupManager={targetGroupEditManager}
                    userData={userData} />
            );
        } else if (tab_id === '2') {
            element = (
                <TargetGroupEditStep2
                    baseData={baseData}
                    targetGroupData={targetGroupEditData}
                    targetGroupManager={targetGroupEditManager}
                    userData={userData} />
            );
        } else if (tab_id === '3') {
            element = (
                <TargetGroupEditStep3
                    baseData={baseData}
                    targetGroupData={targetGroupEditData}
                    targetGroupManager={targetGroupEditManager}
                    userData={userData} />
            );
        } else if (tab_id === '4') {
            element = (
                <TargetGroupEditStep4
                    stopfiles={stopfiles}
                    targetGroupData={targetGroupEditData}
                    targetGroupManager={targetGroupEditManager} />
            );
        }

        return (
            <Box paddingTop={'20px'}>
                {element}
            </Box>
        );
    }

    return (
        <Box>
            <Paper>
                <Box padding={'20px'} display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                    <Box display={'flex'} flexDirection={'row'} alignContent={'center'}>
                        <Box>
                            <IconButton onClick={() => {navigate('/my_target_groups')}}>
                                <ArrowBack />
                            </IconButton>
                        </Box>
                        <Box paddingLeft={'10px'} display={'flex'} flexDirection={'row'} alignContent={'center'} >
                            {renderTitle()}
                        </Box>
                    </Box>
                    {renderCountBox()}
                    {renderActionButtons()}
                </Box>
            </Paper>
            <Paper>
                <Box padding={'20px'}>
                    {renderTabs()}
                    {renderMainPage()}
                </Box>
            </Paper>
        </Box>
    );
}
