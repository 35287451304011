import { Paper } from '@mui/material';
import { Step1NixComp } from './steps/Step1NixComp';
import { Step2FiltersComp } from './steps/Step2FiltersComp';
import { Step3ExportFieldsComp } from './steps/Step3ExportFieldsComp';
import { Step4StopfilesComp } from './steps/Step4StopfilesComp';
import { Step5PurchaseComp } from './steps/Step5PurchaseComp';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { UserData } from '../../../data_layer/user/UserData';
import { OrderData } from '../../../data_layer/order/OrderData';
import { OrderManager } from '../../../data_layer/order/OrderManager';
import { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import { api_get_stopfiles } from '../../../api/endpoint/stopfile/api_get_stopfiles';
import { LinkLandingComp } from './steps/LinkLandingComp';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    userManager: UserManager,
    orderData: OrderData,
    orderManager: OrderManager
}

export const OrderLeadsPage: FunctionComponent <Props> = ({
    baseData,
    userData,
    userManager,
    orderData,
    orderManager
}) => {

    // ======================================================================
    // === States
    // ======================================================================

    const [stopfiles, setStopfiles] = useState <Array<ApiObjStopfile>> ([]);
    const [isDataLoaded, setIsDataLoaded] = useState <boolean> (false);

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        if (userData.isLoggedIn() && userData.isIdle() && !isDataLoaded) {

            api_get_stopfiles()
                .then((result) => {
                    const validStopfiles: Array<ApiObjStopfile> = [];
                    for (const item of result) {
                        if (item.state === ApiObjStopfile.STATE_3_SUCCESS) {
                            validStopfiles.push(item);
                        }
                    }
                    setStopfiles(validStopfiles);
                    setIsDataLoaded(true);

                }).catch((err) => {
                    console.error("Could not fetch stopfiles.");
                    console.error(err);
                });
        }
    
    }, [userData]);

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Paper style={{padding: '20px'}}>

            <Routes>
                <Route 
                    path='1'
                    element={
                        <Step1NixComp
                            baseData={baseData}
                            userData={userData}
                            orderData={orderData}
                            orderManager={orderManager} />
                    }
                />
                <Route 
                    path='2'
                    element={
                        <Step2FiltersComp
                            baseData={baseData}
                            userData={userData}
                            orderData={orderData}
                            orderManager={orderManager} />
                    }
                />
                <Route 
                    path='3'
                    element={
                        <Step3ExportFieldsComp
                            baseData={baseData}
                            userData={userData}
                            orderData={orderData}
                            orderManager={orderManager} />
                    }
                />
                <Route 
                    path='4'
                    element={
                        <Step4StopfilesComp
                            userData={userData}
                            orderData={orderData}
                            orderManager={orderManager}
                            stopfiles={stopfiles} />
                    }
                />
                <Route
                    path='5'
                    element={
                        <Step5PurchaseComp
                            baseData={baseData}
                            userData={userData}
                            userManager={userManager}
                            orderData={orderData}
                            orderManager={orderManager} />
                    }
                />
                <Route
                    path='link/:link_code'
                    element={
                        <LinkLandingComp
                            userData={userData}
                            orderData={orderData}
                            orderManager={orderManager} />
                    }
                />
                <Route
                    path='*'
                    element={
                        <Navigate to="/order_leads/1" replace />
                    }
                />
            </Routes>
        </Paper>
        
    );
}
