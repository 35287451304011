import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjBaseData } from '../../object/base_data/ApiObjBaseData';

export const api_get_base_data = async () : Promise<ApiObjBaseData> => {
    
    const url = API_URL+'/base_data';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );
        
    return new ApiObjBaseData(resp.data);
}
