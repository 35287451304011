import { createTheme } from '@mui/material/styles';
import { MAIN_COLOR, SECONDARY_COLOR } from './constants/Constants';

export const createAppTheme = () => {
  const theme = createTheme({
    components: {
      // MuiFormHelperText: {
      //   styleOverrides: {
      //     root: {
      //       color: '#f44336'
      //     }
      //   }
      // },
      // MuiPaper: {
      //   styleOverrides: {
      //     root: {
      //       color: '#191847'
      //     }
      //   }
      // },
      MuiButton: {
        styleOverrides: {
          // root: {
          //   backgroundColor: SECONDARY_COLOR
          // },
          // 'containedPrimary:hover': {
          //   backgroundColor: '#191847'
          // },
          // 'containedPrimary:focus': {
          //   backgroundColor: '#191847'
          // }
        }
      }
    },
    // typography: {
    //   fontFamily: ['Suisse Intl', 'sans-serif'].join(',')
    // },
    palette: {
      primary: {
        main: MAIN_COLOR,
        light: '#505ccc',
        dark: '#222754',
        contrastText: '#ffffff'
      },
      secondary: {
        main: SECONDARY_COLOR,
        light: '#ffd465',
        dark: '#b57416',          // OLD c47400
        contrastText: '#ffffff'
      }
    },
    // status: {
    //   thirdly: '#ffffff'
    // }
  });
  return {
    ...theme
  };
};
