import { Paper, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

export const ErrorPageNoUser: FunctionComponent = () => {

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Paper style={{padding: '20px'}}>
            <Typography align='center'>Du måste vara inloggad för att se denna sida.</Typography>
        </Paper>
    );
}
