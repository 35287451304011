import { MapAreaCircle } from "../../../data_layer/map/MapAreaCircle";
import { MapAreaPolygon } from "../../../data_layer/map/MapAreaPolygon";
import { MapPoint } from "../../../data_layer/map/MapPoint";
import { ApiObjFacebookTargetGroup } from "../facebook/ApiObjFacebookTargetGroup";

export class ApiObjTargetGroup {

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    user_id: number;
    name: string;
    description: string;
    sort_order: number;
    nix_option_id: number;
    source_id: number;
    filter_ids: Array<number>;
    map_areas: Array<MapAreaCircle|MapAreaPolygon>;
    export_field_ids: Array<number>;
    stopfile_ids: Array<number>;
    stopfile_days: number;
    household_option_id: number;
    created_at: Date;
    person_count: number;
    facebook_target_group: undefined|ApiObjFacebookTargetGroup;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);
        this.name = data.name;
        this.description = data.description;
        this.sort_order = parseInt(data.sort_order);
        this.nix_option_id = parseInt(data.nix_option_id);
        this.source_id = parseInt(data.source_id);

        this.filter_ids = [];
        for (const item of data.filter_ids) {
            this.filter_ids.push(parseInt(item));
        }

        this.map_areas = [];
        let tempId = 1;
        for (const item of data.map_areas) {
            if (item.type === 'circle') {
                this.map_areas.push(
                    MapAreaCircle.createNew(
                        tempId,
                        parseFloat(item.center_lat),
                        parseFloat(item.center_long),
                        parseFloat(item.radius)
                    )
                );
            } else if (item.type === 'polygon') {

                const tempPoints = [];
                for (const p of item.points) {
                    tempPoints.push(new MapPoint(p.lat, p.long))
                }

                this.map_areas.push(
                    MapAreaPolygon.createNew(
                        tempId,
                        tempPoints
                    )
                );

            } else {
                throw new Error("Unknown map area type")
            }
            tempId ++;
        }

        this.export_field_ids = [];
        for (const item of data.export_field_ids) {
            this.export_field_ids.push(parseInt(item));
        }

        this.stopfile_ids = [];
        for (const item of data.stopfile_ids) {
            this.stopfile_ids.push(parseInt(item));
        }

        this.stopfile_days = parseInt(data.stopfile_days);
        this.household_option_id = parseInt(data.household_option_id);
        this.created_at = new Date(data.created_at);
        this.person_count = parseInt(data.person_count);

        if (data.facebook_target_group == null) {
            this.facebook_target_group = undefined;
        } else {
            this.facebook_target_group = new ApiObjFacebookTargetGroup(data.facebook_target_group);
        }
    }
}