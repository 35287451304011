import Axios from 'axios';
import { API_URL } from '../../../../constants/Constants';
import { ApiObjAdminBaseData } from '../../../object/admin/ApiObjAdminBaseData';

export const api_admin_get_base_data = async () : Promise<ApiObjAdminBaseData> => {
        
    const url = API_URL+'/admin/base_data';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.get(
        url,
        config
    );

    return new ApiObjAdminBaseData(resp.data);
}
