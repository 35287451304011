import React from 'react';
import DialogChildBoxComponent from '../../../../components/DialogChildBoxComponent';
import makeT from '../../../../translations/t';
import { useIntl } from 'react-intl';
import ButtonComponent from '../../../../components/ButtonComponent';

const TermsDialogComp = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const intl = useIntl();
  const T = makeT(intl);
  const formattedText = (
    <div>
      <h4>{T('inledning', 'purchase')}</h4>
      <p>{T('inledningBody', 'purchase')}</p>
      <h4>{T('leverans', 'purchase')}</h4>
      <p>{T('leveransBody', 'purchase')}</p>
      <h4>{T('nyttjanderatt', 'purchase')}</h4>
      <p>{T('nyttjanderattBody', 'purchase')}</p>
      <h4>{T('forkop', 'purchase')}</h4>
      <p>{T('forkopBody', 'purchase')}</p>
      <h4>{T('fel', 'purchase')}</h4>
      <p>{T('felBody', 'purchase')}</p>
      <h4>{T('betalning', 'purchase')}</h4>
      <p>{T('betalningBody', 'purchase')}</p>
      <h4>{T('nix', 'purchase')}</h4>
      <p>{T('nixBody', 'purchase')}</p>
      <h4>{T('sekretess', 'purchase')}</h4>
      <p>{T('sekretessBody', 'purchase')}</p>
      <h4>{T('avtalsbrott', 'purchase')}</h4>
      <p>{T('avtalsbrottBody', 'purchase')}</p>
      <h4>{T('tvist', 'purchase')}</h4>
      <p>{T('tvistBody', 'purchase')}</p>
      <h3>{T('marknadsurval', 'purchase')}</h3>
      <h4>{T('sverige', 'purchase')}</h4>
      <p>{T('sverigeBody', 'purchase')}</p>
      <h4>{T('system', 'purchase')}</h4>
      <p>{T('systemBody', 'purchase')}</p>
      <h4>{T('kartlaggning', 'purchase')}</h4>
      <p>{T('kartlaggningBody', 'purchase')}</p>
      <h4>{T('partners', 'purchase')}</h4>
      <p>{T('partnersBody', 'purchase')}</p>
      <h4>{T('svedma', 'purchase')}</h4>
      <p>{T('svedmaBody', 'purchase')}</p>
    </div>
  );
  return (
    <DialogChildBoxComponent
      title={
        <div>
          <h3>{T('title', 'purchase')}</h3>
          <h3>{T('subtitle', 'purchase')}</h3>
        </div>
      }
      bodyText={formattedText}
      newOpen={open}
      setNewOpen={setOpen}
    >
      <ButtonComponent title={'Close'} onClick={handleClose} />
    </DialogChildBoxComponent>
  );
};

export default TermsDialogComp;
