import { Box, Button } from '@mui/material';
import '../../sass/page_frame_account.sass';
import { Outlet, useNavigate } from 'react-router-dom';
import { FunctionComponent } from 'react';
import { UserData } from '../../data_layer/user/UserData';

type Props = {
    userData: UserData,
}

export const PageFrameAccount: FunctionComponent <Props> = ({
    userData,
}) => {

    const navigate = useNavigate();

    return (
        <Box display="flex" alignItems="center" justifyContent="center" className="page-frame-account-container">
            <Box>
                <Outlet />
            </Box>
            <Box className="buttons-back-container" style={{padding: '10px'}}>
                <Button
                    variant={'outlined'}
                    type={'button'}
                    size={'large'}
                    color={'secondary'}
                    onClick={() => navigate('/order_leads')}>
                        {'Tillbaka'}
                </Button>
            </Box>
        </Box>
    );
};