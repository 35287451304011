import { Box, Button, IconButton } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { api_admin_user_get_exports } from '../../../api/endpoint/admin/user/exports/api_admin_user_get_exports';
import { ApiObjExport } from '../../../api/object/export/ApiObjExport';
import { DataGrid } from '@mui/x-data-grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_URL } from '../../../constants/Constants';
import ArticleIcon from '@mui/icons-material/Article';
import { ExportInfoModal } from '../../shared_components/ExportInfoModal';
import { MoveToUserModal } from '../../shared_components/MoveToUserModal';
import { api_admin_get_users } from '../../../api/endpoint/admin/api_admin_get_users';
import { ApiObjAdminUserCompact } from '../../../api/object/admin/ApiObjAdminUserCompact';
import { api_admin_user_transfer_export } from '../../../api/endpoint/admin/user/exports/api_admin_user_transfer_export';
import { util_format_datetime } from '../../../util/format/util_format';

type RowData = {
    id: number,
    name: string,
    created_at: string,
    leads_count: number,
    state: string,
    download: number,
}

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    otherUserId: number
}

export const AdminUserTabExports: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [exportObjs, setExportObjs] = useState <Array<ApiObjExport>> ([]);
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [allUsers, setAllUsers] = useState <Array<ApiObjAdminUserCompact>> ([]);
    const [infoModalExportObj, setInfoModalExportObj] = useState <undefined|ApiObjExport> (undefined);
    const [moveModalExportObj, setMoveModalExportObj] = useState <undefined|ApiObjExport> (undefined);

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        fetchExports();

    }, [otherUserId]);

    useEffect(() => {

        api_admin_get_users()
            .then((result) => {
                setAllUsers(result);

            }).catch((err) => {
                console.error("Could not fetch users list");
                console.error(err);
            });

    }, []);

    
    const fetchExports = () => {
        api_admin_user_get_exports(otherUserId)
            .then((result) => {
                setExportObjs(result);
                setIsLoading(false);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionOpenInfoModal = (exportId: number) => {
        let foundExport = undefined;
        for (const item of exportObjs) {
            if (item.id === exportId) {
                foundExport = item;
                break;
            }
        }
        setInfoModalExportObj(foundExport);
    }

    const actionOpenMoveModal = (exportId: number) => {
        let foundExport = undefined;
        for (const item of exportObjs) {
            if (item.id === exportId) {
                foundExport = item;
                break;
            }
        }
        setMoveModalExportObj(foundExport);
    }

    const actionMoveExport = (newUserId: number) => {

        if (moveModalExportObj === undefined) {
            return;
        }

        api_admin_user_transfer_export(otherUserId, moveModalExportObj.id, newUserId)
            .then(() => {
                console.log("Flytten lyckades!");
                fetchExports();
                setMoveModalExportObj(undefined);

            }).catch((err) => {
                console.error("Move export failed");
                console.error(err);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 80,
            editable: false
        },
        {
            field: 'name',
            headerName: 'Namn',
            width: 220,
            editable: false,
        },
        {
            field: 'created_at',
            headerName: 'Skapad',
            width: 180,
            editable: false,
        },
        {
            field: 'leads_count',
            headerName: 'Antal',
            width: 100,
            editable: false,
        },
        {
            field: 'details',
            headerName: 'Detaljer',
            width: 100,
            editable: false,
            renderCell: (params: any) => {
                
                const exportId = params.row.id;
                
                return (
                    <Box>
                        <IconButton
                            color={'secondary'}
                            onClick={(e) => {
                                actionOpenInfoModal(exportId);
                            }}>
                                <ArticleIcon />
                        </IconButton>
                    </Box>
                );
            }
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 150,
            editable: false,
        },
        {
            field: 'download',
            headerName: 'Val',
            width: 350,
            editable: false,
            renderCell: (params: any) => {

                const downloadDisabled = params.row.download === -1;
                const url = API_URL+'/admin/user/'+otherUserId+'/export/'+params.row.download+'/download';

                return (
                    <Box display={'flex'} flexDirection={'row'}>
                        <Button
                            variant={'contained'}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            onClick={(e) => {
                                const exportId = params.row.id;
                                actionOpenMoveModal(exportId);
                            }}>
                                {'Flytta'}
                        </Button>
                        <Button
                            style={{marginLeft: '8px'}}
                            disabled={downloadDisabled}
                            variant={'contained'}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            startIcon={<CloudDownloadIcon />}
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(url);
                            }}>
                                {'Ladda ner'}
                        </Button>
                    </Box>
                );
            }
        },
    ];

    const getRows = () => {

        const ret : Array<RowData> = [];
        for (const item of exportObjs) {
            ret.push({
                id: item.id,
                name: item.name,
                created_at: util_format_datetime(item.created_at),
                leads_count: item.leads_count,
                state: item.getStateName(),
                download: (item.state == 7) ? item.id : -1,
            });
        }
        return ret;
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    const tryRenderInfoModal = () : undefined|JSX.Element => {

        if (infoModalExportObj === undefined) {
            return undefined;
        }

        const actionClose = () => {
            setInfoModalExportObj(undefined);
        }

        return (
            <ExportInfoModal
                baseData={baseData}
                userData={userData}
                exportObj={infoModalExportObj}
                onClose={actionClose}
            />
        );
    }

    const tryRenderMoveModal = () : undefined|JSX.Element => {

        if (moveModalExportObj === undefined) {
            return undefined;
        }

        return (
            <MoveToUserModal
                objectTypeName={'Export'}
                objectDescription={moveModalExportObj.name}
                users={allUsers}
                onClose={() => {setMoveModalExportObj(undefined)}}
                onMakeMove={(newUserId: number) => {actionMoveExport(newUserId)} }
            />
        );
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box height={'770px'}>
                <DataGrid
                    rows={getRows()}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                    isRowSelectable={() => {return false;}}
                />
            </Box>
            {tryRenderInfoModal()}
            {tryRenderMoveModal()}
        </Box>
    );
}
