import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState } from 'react';
import { Box } from '@mui/system';
import { TargetGroupEditData } from '../../../data_layer/target_group/TargetGroupEditData';
import { TargetGroupEditManager } from '../../../data_layer/target_group/TargetGroupEditManager';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { HouseholdOptionModal } from '../../shared_components/HouseholdOptionModal';
import { util_format_number } from '../../../util/format/util_format';

type Props = {
    baseData: ApiObjBaseData,
    targetGroupData: TargetGroupEditData,
    targetGroupManager: TargetGroupEditManager,
    userData: UserData,
}

export const TargetGroupEditStep1: FunctionComponent <Props> = ({
    baseData,
    targetGroupData,
    targetGroupManager,
    userData,
}) => {

    // ======================================================================
    // === State
    // ======================================================================

    const [visibleModal, setVisibleModal] = useState <string> ('');

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionChangeName = (newValue: string) => {
        targetGroupManager.actionSetName(newValue);
    }

    const actionChangeDescription = (newValue: string) => {
        targetGroupManager.actionSetDescription(newValue);
    }

    const actionChangeNix = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            targetGroupManager.actionSetNixOptionId(newValue);
        }
    }

    const actionChangeSource = (event: SelectChangeEvent<number>) => {
        const newValue = event.target.value;
        if (typeof newValue === 'number') {
            targetGroupManager.actionSetSourceId(newValue);
        }
    }

    const actionHouseholdModalOpen = () => {
        setVisibleModal('household_option');
    }

    const actionHouseholdModalSelected = (newHouseholdOption: number) => {
        targetGroupManager.actionSetHouseholdOptionId(newHouseholdOption);
        setVisibleModal('');
    }

    const actionHouseholdModalCancel = () => {
        setVisibleModal('');
    }

    // ======================================================================
    // === Render name
    // ======================================================================

    const renderNameElement = () => {

        let errorElement = undefined;
        if (targetGroupData.name.length == 0) {
            errorElement = (<FormHelperText error={true}>Du måste ange ett namn.</FormHelperText>);
        } else if (targetGroupData.name.length > 50) {
            errorElement = (<FormHelperText error={true}>Namnet är för långt.</FormHelperText>);
        }

        return (
            <Box mt={1}>
                <FormControl>
                    <TextField
                        id="target_group_name"
                        label={'Namn'}
                        disabled={targetGroupData.state_is_saving}
                        variant="outlined"
                        value={targetGroupData.name}
                        onChange={(e) => actionChangeName(e.target.value)}
                        error={errorElement !== undefined}
                        style = {{width: '300px'}}
                    />
                    {errorElement}
                </FormControl>
            </Box>
        );
    }

    const renderDescriptionElement = () => {

        const maxLength = 1000;
        const curLength = targetGroupData.description.length;
        const counterText = '('+curLength+' / '+maxLength+')';

        return (
            <Box mt={4}>
                <FormControl>
                    <TextField
                        id="target_group_description"
                        label={'Beskrivning (valfri)'}
                        multiline
                        disabled={targetGroupData.state_is_saving}
                        variant="outlined"
                        value={targetGroupData.description}
                        onChange={(e) => actionChangeDescription(e.target.value)}
                        error={curLength > maxLength}
                        style = {{width: '300px'}}
                    />
                    <FormHelperText>{counterText}</FormHelperText>
                </FormControl>
            </Box>
        );
    }

    // ======================================================================
    // === Render Nix option
    // ======================================================================

    const renderNixElement = () => {

        let nixOptions = baseData.nix_options;
        if (userData.isLoggedIn()) {
            nixOptions = userData.getNixOptions();
        }

        const menuItems = [];
        for (const item of nixOptions) {
            menuItems.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {item.name}
                </MenuItem>
            )
        }

        return (
            <Box mt={4}>
                <FormControl>
                    <InputLabel id="label-nix">
                        {'NIX-alternativ'}
                    </InputLabel>
                    <Select
                        labelId='label-nix'
                        label={'NIX-alternativ'}
                        value={targetGroupData.nix_option_id}
                        onChange={(event) => {actionChangeNix(event)}}
                        disabled={targetGroupData.state_is_saving}>
                            {menuItems}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    // ======================================================================
    // === Render Source
    // ======================================================================

    const renderSourceElement = () => {

        let sources = baseData.sources;
        if (userData.isLoggedIn()) {
            sources = userData.getSources();
        }

        const options = [];
        options.push(
            <MenuItem
                key={0}
                value={0}>
                    {'Standard'}
            </MenuItem>
        );
        for (const item of sources) {
            const name = 
                item.name + ' ('
                + util_format_number(item.person_count)
                +')';

            options.push(
                <MenuItem
                    key={item.id}
                    value={item.id}>
                        {name}
                </MenuItem>
            );
        }

        return (
            <Box mt={4}>
                <FormControl>
                    <InputLabel id="label-source">
                        {'Källa'}
                    </InputLabel>
                    <Select
                        label={'Källa'}
                        labelId={'label-source'}
                        value={targetGroupData.source_id}
                        onChange={(event) => {actionChangeSource(event)}}
                        disabled={targetGroupData.state_is_saving}>
                            {options}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    // ======================================================================
    // === Render Household option
    // ======================================================================

    const renderHouseholdElement = () => {

        let householdOptions = baseData.household_options;
        if (userData.isLoggedIn()) {
            householdOptions = userData.getHouseholdOptions();
        }
        
        let householdOptionText = "Ingen";

        for (const item of householdOptions) {
            if (item.id === targetGroupData.household_option_id) {
                householdOptionText = item.name;
                break;
            }
        }

        return (
            <Box display={'flex'} flexDirection={'column'} mt={4}>
                <Typography style={{marginTop: '2px'}}>
                    Hushållsbegränsning
                </Typography>
                <Button
                    size={'large'}
                    style={{textTransform:'none', minWidth: '100px', maxWidth: '400px'}}
                    variant='contained'
                    fullWidth={false}
                    color={'primary'}
                    onClick={(event) => { event.stopPropagation(); actionHouseholdModalOpen() }}>
                        {householdOptionText}
                </Button>
            </Box>
        );
    }

    const tryRenderHouseholdModal = () : undefined|JSX.Element => {
        if (visibleModal !== 'household_option') {
            return undefined;
        }
        return (
            <HouseholdOptionModal
                baseData={baseData}
                userData={userData}
                householdOptionId={targetGroupData.household_option_id}
                onClose={actionHouseholdModalCancel}
                onChange={actionHouseholdModalSelected}
            />
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Box>
            {renderNameElement()}
            {renderDescriptionElement()}
            {renderNixElement()}
            {renderSourceElement()}
            {renderHouseholdElement()}
            {tryRenderHouseholdModal()}
        </Box>
    );
}
