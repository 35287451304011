import { UserData } from '../user/UserData';
import { MapAreaCircle } from '../map/MapAreaCircle';
import { MapAreaPolygon } from '../map/MapAreaPolygon';
import { CountOrderData } from '../count/order/CountOrderData';
import { CountFilterDataItem } from '../count/filter/CountFilterDataItem';
import { ApiObjTargetGroup } from '../../api/object/target_group/ApiObjTargetGroup';
import { UtilArray } from '../../util/code/UtilArray';
import { MapArea } from '../map/MapArea';
import { OrderPriceData } from '../order_price/OrderPriceData';
import { FilterTreeMini } from '../filtertree/FilterTreeMini';
import { ApiObjNixOption } from '../../api/object/nix_option/ApiObjNixOption';
import { util_format_datetime } from '../../util/format/util_format';

/**
 * Holds all data to define an order
 * 
 * ..plus all variable data to build the
 * order screens (5 steps plus summary).
 * 
 * Created in OrderManager and passed down
 * to UI components as immutable.
 */
export class OrderData {

    // ==========================================================================
    // === Purchase State
    // ==========================================================================

    public static readonly PURCHASE_STATE_1_NOT_STARTED = 1;
    public static readonly PURCHASE_STATE_2_CREATING_EXPORT = 2;
    public static readonly PURCHASE_STATE_3_EXPORT_FAILED = 3;
    public static readonly PURCHASE_STATE_4_WAITING_FOR_STRIPE_INPUT = 4;
    public static readonly PURCHASE_STATE_5_STRIPE_FAILED = 5;
    public static readonly PURCHASE_STATE_6_SUCCESS = 6;

    // ==========================================================================
    // === Payment method
    // ==========================================================================

    public static readonly PAYMENT_METHOD_SUBSCRIPTION = 1;
    public static readonly PAYMENT_METHOD_STRIPE = 2;

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public nix_option_id: number;
    public source_id: number;
    public filter_ids_selected: Array<number>;
    public filter_ids_visible: Array<number>;
    public map_areas: Array<MapAreaCircle|MapAreaPolygon>
    public export_field_ids : Array<number>;
    public stopfile_ids : Array<number>;
    public household_option_id: number;
    public size_limit: number;
    public filename: string;
    public payment_method: number;
    public terms_accepted: boolean;
    public filter_count : Array<CountFilterDataItem>;
    public order_count: undefined | CountOrderData;
    public order_price: undefined | OrderPriceData;
    public filter_tree_mini: undefined | FilterTreeMini;
    public purchase_state: number;
    public purchase_export_id: number;
    public purchase_stripe_client_secret: string;
    public purchase_stripe_public_key: string;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = () : OrderData => {
        return new OrderData(undefined);
    }

    public static createClone = (original: OrderData) : OrderData => {
        return new OrderData(original);
    }

    private constructor(old : OrderData|undefined = undefined) {

        if (old === undefined) {
            this.nix_option_id = ApiObjNixOption.ID._3_NIX_FREE_PHONE_MOBILE;
            this.source_id = 0;
            this.filter_ids_selected = [];
            this.filter_ids_visible = [];
            this.map_areas = [];
            this.export_field_ids = [1,2,3,4,5,6,7,8];   // Hard coded default. Will break if export fields are removed from DB. Should "never" happen.
            this.stopfile_ids = [];
            this.household_option_id = 0;
            this.size_limit = 0;
            this.filename = 'Export-' + util_format_datetime(new Date()).substring(0, 16).replace(':', '-');
            this.payment_method = OrderData.PAYMENT_METHOD_STRIPE;
            this.terms_accepted = false;
            this.filter_count = [];
            this.order_count = undefined;
            this.order_price = undefined;
            this.purchase_state = OrderData.PURCHASE_STATE_1_NOT_STARTED;
            this.purchase_export_id = -1;
            this.purchase_stripe_client_secret = '';
            this.purchase_stripe_public_key = '';

        } else {
            this.nix_option_id = old.nix_option_id;
            this.source_id = old.source_id;
            this.filter_ids_selected = [...old.filter_ids_selected];
            this.filter_ids_visible = [...old.filter_ids_visible];
            this.export_field_ids = [...old.export_field_ids];
            this.stopfile_ids = [...old.stopfile_ids];
            this.household_option_id = old.household_option_id;
            this.size_limit = old.size_limit;
            this.filename = old.filename;
            this.payment_method = old.payment_method;
            this.terms_accepted = old.terms_accepted;

            const mapAreas = [];
            for (const item of old.map_areas) {
                if (item instanceof MapAreaCircle) {
                    mapAreas.push(MapAreaCircle.createClone(item));
                } else if (item instanceof MapAreaPolygon) {
                    mapAreas.push(MapAreaPolygon.createClone(item));
                }
            }
            this.map_areas = mapAreas;

            const filterCount = [];
            for (const item of old.filter_count) {
                filterCount.push(CountFilterDataItem.createClone(item));
            }
            this.filter_count = filterCount;
            
            if (old.order_count !== undefined) {
                this.order_count = CountOrderData.createClone(old.order_count);
            } else {
                this.order_count = undefined;
            }
            if (old.order_price !== undefined) {
                this.order_price = OrderPriceData.createClone(old.order_price);
            } else {
                this.order_price = undefined;
            }
            this.filter_tree_mini = old.filter_tree_mini;

            this.purchase_state = old.purchase_state;
            this.purchase_export_id = old.purchase_export_id;
            this.purchase_stripe_client_secret = old.purchase_stripe_client_secret;
            this.purchase_stripe_public_key = old.purchase_stripe_public_key;
        }
    }

    // ==========================================================================
    // === Helpers
    // ==========================================================================

    public getFilterCountItem(filterId: number) : CountFilterDataItem|undefined {
        for (const item of this.filter_count) {
            if (item.filter_id == filterId) {
                return item;
            }
        }
        return undefined;
    }

    public canEditOrder = () => {
        return this.purchase_state === OrderData.PURCHASE_STATE_1_NOT_STARTED;
    }

    public canMakePurchase = (userData: UserData) : boolean => {

        if (
            !userData.isLoggedIn()
            || !userData.isIdle()
        ) {
            return false;
        }

        const leadsLeft = userData.getSubscriptionLeadsLeft();

        const validStates = [
            OrderData.PURCHASE_STATE_1_NOT_STARTED,
            OrderData.PURCHASE_STATE_3_EXPORT_FAILED,
            OrderData.PURCHASE_STATE_5_STRIPE_FAILED
        ];

        const count = this.getCountToOrder();
        
        return this.nix_option_id != 0
            && this.export_field_ids.length > 0
            && validStates.includes(this.purchase_state)
            && this.terms_accepted
            && this.filename.length > 0
            && this.filename.length <= 50
            && count >= 100
            && count <= 100000
            && (
                (this.payment_method === 1 && leadsLeft >= count)
                || this.payment_method === 2
            )
    }

    public getCountToOrder = () => {
        let count = -1;
        if(
            this.order_count !== undefined
            && this.order_count.state === CountOrderData.STATE_READY
            && this.order_count.api_data !== undefined
        ) {
            count = this.order_count.api_data.counts.final_count;
            if (this.size_limit > 0 && this.size_limit < count) {
                count = this.size_limit;
            }
        }
        return count;
    }

    public isEqualToTargetGroup = (targetGroup: ApiObjTargetGroup) : boolean => {
        const t = targetGroup;
        return (
            this.nix_option_id === t.nix_option_id
            && this.source_id === t.source_id
            && UtilArray.arrayEquals(this.filter_ids_selected, t.filter_ids)
            && MapArea.isArraysEqual(this.map_areas, t.map_areas)
            && UtilArray.arrayEquals(this.export_field_ids, t.export_field_ids)
            && this.household_option_id === t.household_option_id
        );
    }
}