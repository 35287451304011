import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjAdAccount } from '../../object/facebook/ApiObjAdAccount';

export const api_get_ad_accounts = async () : Promise<Array<ApiObjAdAccount>> => {
    
    const url = API_URL+'/facebook/ad_account';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret : Array<ApiObjAdAccount> = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdAccount(item));
    }
    return ret;
}
