import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_stopfile = async (stopfileId: number) : Promise<void> => {
    
    const url = API_URL+'/stopfile/'+stopfileId;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    await Axios.delete(
        url,
        config
    );
}