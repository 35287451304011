export class ApiObjAdminUser {
    
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_admin: boolean;
    is_active: boolean;
    company_name: string;
    company_number: string;
    address: string;
    zipcode: string;
    city: string;
    phone_number: string;
    api_monthly_quota: number;
    created_at: Date;
    updated_at: Date;
    has_invoice: boolean;
    main_subscription: {
        type: number,
        price: number,
        total_leads: number,
        max_leads: number,
    };
    populate_file_subscription: {
        type: number,
        price: number,
        spending: number,
        spending_max: number
    };

    constructor(data: any) {

        this.id = data.id;
        this.first_name = data.first_name,
        this.last_name = data.last_name,
        this.email = data.email,
        this.is_admin = parseInt(data.is_admin) === 1,
        this.is_active = parseInt(data.is_active) === 1,
        this.company_name = data.company_name,
        this.company_number = data.company_number,
        this.address = data.address,
        this.zipcode = data.zipcode,
        this.city = data.city,
        this.phone_number = data.phone_number,
        this.api_monthly_quota = parseInt(data.api_monthly_quota);
        this.created_at = new Date(data.created_at),
        this.updated_at = new Date(data.updated_at),
        this.has_invoice = parseInt(data.has_invoice) === 1,
        this.main_subscription = {
            type: parseInt(data.main_subscription.type),
            price: parseInt(data.main_subscription.price),
            total_leads: parseInt(data.main_subscription.total_leads),
            max_leads: parseInt(data.main_subscription.max_leads),
        };
        this.populate_file_subscription = {
            type: parseInt(data.populate_file_subscription.type),
            price: parseInt(data.populate_file_subscription.price),
            spending: parseFloat(data.populate_file_subscription.spending),
            spending_max: parseInt(data.populate_file_subscription.spending_max)
        }
    }
}
