import { Autocomplete, Box, Button, Modal, Paper, TextField, Typography, } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { ApiObjAdminUserCompact } from '../../api/object/admin/ApiObjAdminUserCompact';

type Props = {
    objectTypeName: string,
    objectDescription: string,
    users: Array<ApiObjAdminUserCompact>
    onClose: () => void,
    onMakeMove: (selectedUserId: number) => void,
}

export const MoveToUserModal: FunctionComponent<Props> = ({
    objectTypeName,
    objectDescription,
    users,
    onClose,
    onMakeMove,
}) => {

    // ===========================================================
    // === State
    // ===========================================================

    const [selectedUserId, setSelectedUserId] = useState <number> (-1);

    // ===========================================================
    // === Render
    // ===========================================================

    const autoCompleteOptions = [];
    autoCompleteOptions.push(
        {
            label: '',
            user_id: -1,
        }
    );
    for (const item of users) {
        autoCompleteOptions.push({
            label: item.id+' | '+item.email+' | '+item.first_name+' '+item.last_name,
            user_id: item.id,
        });
    }

    let selectedOption = undefined;
    for (const item of autoCompleteOptions) {
        if (item.user_id === selectedUserId) {
            selectedOption = item;
            break;
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        p: 4,
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={() => {onClose()}}
        >
            <Box style={style}>
                <Paper>
                    <Box style={{padding: '12px'}}>
                        <Typography variant="h6">
                            Flytta {objectTypeName}:
                        </Typography>
                        <Typography style={{marginTop: '2px'}}>
                            {objectDescription}
                        </Typography>
                        <Box
                            style={{marginTop: '10px'}}>

                            <Autocomplete
                                disablePortal
                                value={selectedOption}
                                options={autoCompleteOptions}
                                sx={{ width: '576px' }}
                                onChange={ (event: any, newValue) => {
                                    if (newValue == null) {
                                        setSelectedUserId(-1);
                                    } else {
                                        setSelectedUserId(newValue.user_id);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Ny användare" />}
                            />
                        </Box>
                        <Box
                            style={{marginTop: '12px'}}>
                            <Button
                                variant='contained'
                                disabled={selectedUserId === -1}
                                color='secondary'
                                size='medium'
                                onClick={() => {
                                    if (selectedUserId > -1) {
                                        onMakeMove(selectedUserId);
                                    }
                                }}>
                                    Genomför flytt
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}