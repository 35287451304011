
export class UtilArray {

    public static arrayEquals(a: Array<any>, b: Array<any>) : boolean {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    public static arrayContainsAll(haystack: Array<number>, needles: Array<number>) : boolean {

        if (needles.length === 0) {
            throw new Error("Needles cannot be empty");
        }

        const needlesMap = new Map<number, number>();
        for (const item of needles) {
            needlesMap.set(item, 0);
        }
        for (const item of haystack) {
            if (needlesMap.has(item)) {
                needlesMap.set(item, 1);
            }
        }
        let zeroFound = false;
        needlesMap.forEach((value, key) => {
            if (value === 0) {
                zeroFound = true;
            }
        });
        return !zeroFound;
    }

    public static createUniqueSortedIntArray = (array: Array<number>) : Array<number> => {
        const map = new Map<number,number>();
        for (const id of array) {
            map.set(id, -1);
        }
        const newArray : Array<number> = [];
        map.forEach((value: number, key: number) => {
            newArray.push(key);
        });
        newArray.sort((a : number, b: number) => (a - b));
        return newArray;
    }

    public static removeArrayFromArray = (remove: Array<number>, original: Array<number>) : Array<number> => {
        const result = [];
        for (const item of original) {
            if (!remove.includes(item)) {
                result.push(item);
            }
        }
        return result;
    }

    public static sortIntArray = (array: Array<number>) : Array<number> => {
        const newArray = [...array];
        newArray.sort((a : number, b: number) => (a - b));
        return newArray;
    }
}