import { ApiObjPriceObject } from "../price/ApiObjPriceObject";

export class ApiObjPopulateFileOrderStep4 {

    // ==============================================================
    // === Fields
    // ==============================================================

    price: {
        excl_vat: number,
        vat: number,
        total: number,
        spec_items: Array<{
            price_object: ApiObjPriceObject
            items_count: number,
            price_total: number
        }>
    }
    match_count_details: Array<{type: number, count: number}>;
    include_base_price: boolean;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any, includeBasePrice: boolean) {

        this.include_base_price = includeBasePrice;

        const specItems = [];
        for (const item of data.price.spec_items) {
            specItems.push({
                price_object: new ApiObjPriceObject(item.price_object),
                items_count: parseInt(item.items_count),
                price_total: parseFloat(item.price_total)
            });
        }

        this.price = {
            excl_vat: parseFloat(data.price.excl_vat),
            vat: parseFloat(data.price.vat),
            total: parseFloat(data.price.total),
            spec_items: specItems
        };

        this.match_count_details = [];
        for (const item of data.match_count_details) {
            this.match_count_details.push({
                type: parseInt(item.type),
                count: parseInt(item.count)
            });
        }
    }
}