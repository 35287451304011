export const util_format_number = (num: number): string => {

    if (isNaN(num)) {
      return '';
    }
    const roundedNum = Math.round(num * 100) / 100;
    const parts = roundedNum.toString().split('.');
  
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1]) {
      parts[1] = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  
    return parts.join(',');
};

export const util_format_price_with_decimals = (num: number): string => {
    if (isNaN(num)) {
        return '';
    }
    const roundedNum = Math.round(num * 100) / 100;
    const parts = roundedNum.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1]) {
        parts[1] = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    if (parts[1] === undefined || parts[1] === null || parts[1].length == 0) {
        parts[1] = '00';
    } else if (parts[1].length == 1) {
        parts[1] = parts[1]+'0';
    } else if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
    }

    return parts.join(',');
};

/**
 * @param date Date to format
 * @returns Date in format YYYY-MM-DD
 */
export const util_format_date = (date: Date) : string => {
    return date.toISOString().split('T')[0];
}

/**
 * @param date Date to format
 * @returns Date in format YYYY-MM-DD HH:MM:SS
 */
export const util_format_datetime = (date: Date) : string => {
    const parts = date.toISOString().split('T');
    return parts[0]+' '+parts[1].substring(0, 8);
}
