import { FunctionComponent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography, CircularProgress, LinearProgress, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { UserData } from '../../../data_layer/user/UserData';
import { UserManager } from '../../../data_layer/user/UserManager';
import { util_format_number } from '../../../util/format/util_format';

type Props = {
    userData: UserData,
    userManager: UserManager,
    title: string
}

export const HeaderComp: FunctionComponent <Props> = ({
    userData,
    userManager,
    title,
}) => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        if (userData.isIdle()) {
            userManager.actionLogOut();
        }
    };

    // ==============================================================
    // === Render leads counter
    // ==============================================================

    const renderLeadsCounter = () => {

        if (!userData.hasSubscription()) {
            return undefined;
        }

        const leadsMax = userData.getSubscriptionLeadsMax();
        const leadsLeft = userData.getSubscriptionLeadsLeft();
        
        if (leadsMax === 0) {
            return undefined;
        }

        const leftProgress = (leadsLeft / leadsMax) * 100;
        const leftString = util_format_number(leadsLeft);

        return (
        <Box width={320} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} mr={4}>
            <Box mb={1} textAlign={'center'}>Återstående leads: {leftString} st</Box>
            <LinearProgress color={'secondary'} variant="determinate" value={leftProgress} />
        </Box>
        );
    }

    const renderPopulateCounter = () => {

        if (!userData.hasPopulateSubscription()) {
            return undefined;
        }

        const populateMax = userData.getPopulateSpendingMax();
        const populateLeft = userData.getPopulatePotLeft();
        
        if (populateMax === 0) {
            return undefined;
        }

        const leftProgress = (populateLeft / populateMax) * 100;
        const leftString = util_format_number(populateLeft);

        return (
        <Box width={320} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} mr={4}>
            <Box mb={1} textAlign={'center'}>Nummersättningspott: {leftString} kr</Box>
            <LinearProgress color={'secondary'} variant="determinate" value={leftProgress} />
        </Box>
        );
    }

    // ==============================================================
    // === Render user
    // ==============================================================
  
    const getUserElement = () => {

        if (!userData.isIdle()) {
            return (
                <CircularProgress size={16} />
            );
        }

        if (userData.isLoggedIn()) {

            return (
                <Box display="flex" alignItems="center">
                    {!location.pathname.includes("populate") && renderLeadsCounter()}
                    {!location.pathname.includes("order_leads") && renderPopulateCounter()}
                    <Typography variant="subtitle1">
                        {userData.getFirstName()}
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        size='large'
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu id="menu-appbar" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem key={1} onClick={ () => { navigate('/my_user') }}>
                            <Typography variant="subtitle1">
                                {'Användarinställningar'}
                            </Typography>
                        </MenuItem>
                        <MenuItem key={2} onClick={handleLogout}>
                            <Typography variant="subtitle1">
                                {'Logga ut'}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            );
        } else {

            return (
                <Box display="flex" alignItems="center">
                    
                    <Button
                        variant='outlined'
                        color={'secondary'}
                        onClick={() => { navigate('/account/register')}}>
                        {'Skapa konto'}
                    </Button>

                    <Button
                        style={{marginLeft: '8px'}}
                        variant='outlined'
                        color={'secondary'}
                        onClick={() => { navigate('/account/login')}}>
                        {'Logga in'}
                    </Button>
                    
                </Box>
            );
        }
    }

    // ==============================================================
    // === Render main
    // ==============================================================

    return (
        <AppBar color='primary'>
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Link to="/" style={{ textDecoration: "none" }}>                
                    <Typography marginLeft={'15px'}
                        variant='h5' color="white">
                        Målgruppsväljaren
                    </Typography>
                </Link>
                
                <Toolbar>
                    {getUserElement()}
                </Toolbar>
            </Box>
        </AppBar>
    );
};
