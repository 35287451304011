import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpansionPanelComponent = ({ data }) => {
  return (
    <Box>
      {data
        ? data.map((element, index) => {
            return element.dataCallback || element.dataCallback === 0 ? (
              <Accordion key={index}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  onClick={() => element.callback(element.dataCallback)}
                >
                  <Typography color="primary">{element.title}</Typography>
                </AccordionSummary>
              </Accordion>
            ) : (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel1a-content">
                  <Typography color="primary">{element.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{element.content ? element.content : 'Empty Data'}</AccordionDetails>
              </Accordion>
            );
          })
        : ''}
    </Box>
  );
};

export default ExpansionPanelComponent;
