import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_logout = async () : Promise<{success: boolean}> => {

    const url = API_URL+'/user/logout';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    await Axios.post(url, {}, config);
        
    return {
        success: true
    };
}