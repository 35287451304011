import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createAppTheme } from './MuiTheme';
import { Provider } from 'react-redux';
import store from './reducers';
import './sass/root.sass';
import { SnackbarProvider } from 'notistack';
import { AppRouter } from './pages/AppRouter';
import { IntlProvider } from 'react-intl';
import Languages from './translations/index.js';

const Root = (props) => {
  const [locale] = useState('se');
  return (
    <section>
      <IntlProvider locale={locale} messages={Languages[locale] || Languages['se']}>
        <ThemeProvider theme={createAppTheme()}>
          <SnackbarProvider maxSnack={3}>
            <Provider store={store}>
              <AppRouter />
            </Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </IntlProvider>
    </section>
  );
};

export default Root;
