import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Modal, Paper, } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApiObjPopulateFile } from '../../api/object/populate_file/ApiObjPopulateFile';
import { StaticPopulateFileDataOption } from '../../api/constants/populate_file/StaticPopulateFileDataOption';

type Props = {
    userData: UserData,
    baseData: ApiObjBaseData,
    populateObj: ApiObjPopulateFile
    onClose: () => void
}

export const PopulateInfoModal: FunctionComponent<Props> = ({
    userData,
    baseData,
    populateObj,
    onClose
}) => {

    console.log(populateObj)

    // ===========================================================
    // === State
    // ===========================================================

    const [hoverItem, setHoverItem] = useState <string> ('');

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const styleInfoItem = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 8px',
    } as React.CSSProperties;

    const helperGetStyleForItem = (itemName: string) : React.CSSProperties => {
        if (itemName === hoverItem) {
            return {
                ...styleInfoItem,
                backgroundColor: '#f5f5f5'
            }
        }
        return styleInfoItem;
    }

    const renderInfo = () => {
        return (
            <Box>
                <Box
                    onMouseEnter={() => { setHoverItem('name') }}
                    onMouseLeave={() => { setHoverItem('') }}
                    style={helperGetStyleForItem('name')}>
                    
                    <Box>Namn:</Box>
                    <Box>{populateObj.params.name}</Box>
                </Box>
                <Box
                    onMouseEnter={() => { setHoverItem('rows') }}
                    onMouseLeave={() => { setHoverItem('') }}
                    style={helperGetStyleForItem('rows')}>
                    
                    <Box>Rader:</Box>
                    <Box>{populateObj.count.matches}</Box>
                </Box>
                <Box
                    onMouseEnter={() => { setHoverItem('match') }}
                    onMouseLeave={() => { setHoverItem('') }}
                    style={helperGetStyleForItem('match')}>
                    
                    <Box>Matchningar:</Box>
                    <Box>{populateObj.count.matches}</Box>
                </Box>
            </Box>
        );
    }

    const renderStopfiles = () => {

        const stopfileCount = populateObj.params.clean_stopfile_ids.length;

        return (
            <Box
                onMouseEnter={() => { setHoverItem('stopfiles') }}
                onMouseLeave={() => { setHoverItem('') }}
                style={helperGetStyleForItem('stopfiles')}>

                <Box>
                    Stoppfiler:
                </Box>
                <Box>
                    {stopfileCount + ' stoppfiler'}
                </Box>
            </Box>
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '528px',
        height: '528px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        width={'500px'}
                        maxHeight={'500px'}
                        padding={'14px'}>
                        {renderInfo()}
                        {renderStopfiles()}
                        <Accordion style={{outline: "none", boxShadow: "none", border: "none", position: "static", margin: "0rem -0.5rem 0 -0.5rem"}} variant='elevation'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>Data hämtat</AccordionSummary>
                            <AccordionDetails style={{ paddingRight: "0.5rem" }}>
                                {
                                    StaticPopulateFileDataOption.ALL.map((item) => (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Box>{item.name}</Box>
                                            <Box>
                                                <Checkbox
                                                    checked={populateObj.params.data_option_ids.includes(item.id)}
                                                    color={'secondary'}
                                                />
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{outline: "none", boxShadow: "none", border: "none", position: "static", margin: "0rem -0.5rem 0 -0.5rem"}} variant='elevation'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>Tvättar</AccordionSummary>
                            <AccordionDetails style={{ paddingRight: "0.5rem" }}>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Box>Betalningsförmåga</Box>
                                    <Box>
                                        <Checkbox
                                            checked={!!populateObj.params.clean_low_income}
                                            color={'secondary'}
                                        />
                                    </Box>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Box>Nix</Box>
                                    <Box>
                                        <Checkbox
                                            checked={!!populateObj.params.clean_nix}
                                            color={'secondary'}
                                        />
                                    </Box>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Box>Pinga nummer</Box>
                                    <Box>
                                        <Checkbox
                                            checked={!!populateObj.params.clean_hlr}
                                            color={'secondary'}
                                        />
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}