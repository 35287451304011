export class ApiObjNixOption {

    // ==============================================================
    // === Hardcoded for logic
    // ==============================================================

    static ID = {
        _1_NIX_FREE_ADDRESS: 1,
        _2_NIX_FREE_PHONE_MOBILE_OR_FIXED: 2,
        _3_NIX_FREE_PHONE_MOBILE: 3,
        _4_NIX_FREE_PHONE_FIXED: 4,
        _5_ADDRESS: 5,
        _6_PHONE_MOBILE_OR_FIXED: 6,
        _7_PHONE_MOBILE: 7,
        _8_PHONE_FIXED: 8,
    }

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    name: string;
    is_premium: boolean;
    sort_order: number;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.is_premium = parseInt(data.is_premium) === 1;
        this.sort_order = parseInt(data.sort_order);
    }
}