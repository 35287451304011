import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserPrice } from '../../../../object/admin/ApiObjAdminUserPrice';

export const api_admin_user_get_prices = async (userId: number) : Promise<Array<ApiObjAdminUserPrice>> => {

    const url = API_URL+'/admin/user/'+userId+'/price';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };

    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserPrice(item));
    }
    return ret;
}
