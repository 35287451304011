import React from 'react';
import { Box, TextField, Hidden } from '@mui/material';
//import '../css/TextFieldComponent.css';

// const style = (theme) => ({
//   input: {
//     color: '#ffffff'
//   },
//   underline: {
//     '&:before': {
//       borderColor: `white !important`
//     },
//     '&:after': {
//       borderColor: `white  !important`
//     }
//   },
//   multilineColor: {
//     color: '#ffffff'
//   },
//   focused: {
//     color: `#ffffff !important`
//   },
//   helperText: {
//     background: '#fcb8b8',
//     padding: '5px 10px',
//     marginTop: 0,
//     borderRadius: 1
//   }
// });

const TextFieldComponent = ({
  classes,
  multiline,
  customHelperText,
  label,
  fullWidth,
  type,
  color,
  formikProps,
  id,
  readOnly,
  hidden,
  //onBlur = () => {},
  //onChange = () => {}
}) => {
  const value = formikProps.values[id];
  const error = formikProps.errors[id];
  const touched = formikProps.touched[id];
  const isError = () => {
    return error && Boolean(touched) ? true : false;
  };
  const handleBlur = (event) => {
    //onBlur(event);
    formikProps.handleBlur(event);
  };
  const handleChange = (event) => {
    //onChange(event);
    formikProps.handleChange(event);
  };
  return (
    <Box>
      <Hidden xsDown={hidden} xsUp={hidden}>
        <TextField
          error={customHelperText ? Boolean(customHelperText) : isError()}
          label={label}
          margin="normal"
          fullWidth={fullWidth}
          multiline={multiline}
          type={type}
          name={id}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          // InputProps={{
          //   classes: {
          //     input: color ? classes.input : '',
          //     underline: color ? classes.underline : ''
          //   },
          //   readOnly
          // }}
          // InputLabelProps={{
          //   classes: {
          //     root: color ? classes.multilineColor : '',
          //     focused: color ? classes.focused : ''
          //   },
          //   shrink: true
          // }}
          // FormHelperTextProps={{
          //   classes: {
          //     root: color ? classes.helperText : ''
          //   }
          // }}
          helperText={customHelperText ? customHelperText : isError() ? error : ''}
        />
      </Hidden>
    </Box>
  );
};

//export default withStyles(style)(TextFieldComponent);
export default TextFieldComponent;
