import React, { FunctionComponent, useState } from 'react';
import { Checkbox, Box, Typography, Button, Select, SelectChangeEvent, MenuItem, Chip, FormGroup, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { UserData } from '../../../../data_layer/user/UserData';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { ApiObjStopfile } from '../../../../api/object/stopfile/ApiObjStopfile';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ApiObjPriceObjectType } from '../../../../api/object/price/ApiObjPriceObjectType';
import { CreateStopFileModal } from '../../../shared_components/CreateStopFileModal';

type Props = {
    userData: UserData
    orderData: OrderData,
    orderManager: OrderManager,
    stopfiles: Array<ApiObjStopfile>
}

export const Step4StopfilesComp: FunctionComponent<Props> = ({
    userData,
    orderData,
    orderManager,
    stopfiles
}) => {

    const navigate = useNavigate();    
    const [createdFileType, setCreatedFileType] = useState(0)
    const [createStopFile, setCreateStopFile] = useState(false)
    const [stopfileMonthSelected, setStopFileMonthSelected] = useState(0)

    // =====================================================================
    // === Actions
    // =====================================================================

    const actionCheckboxClicked = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            orderManager.actionAddStopfiles([id]);
        } else {
            orderManager.actionRemoveStopfiles([id]);
        }
    }

    const actionCheckAllClicked = () => {
        const list = createdFileType === 1 ? [...stopfiles].filter((item) => !item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : createdFileType === 2 ? [...stopfiles].filter((item) => item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : [...stopfiles].filter((item) => item.state === ApiObjStopfile.STATE_3_SUCCESS)
        if (list.filter((item) => orderData.stopfile_ids.includes(item.id)).length === list.length) {
            orderManager.actionRemoveStopfiles(orderData.stopfile_ids);

        } else {
            const idsToAdd = [];
            for (const item of stopfiles) {
                idsToAdd.push(item.id);
            }
            orderManager.actionAddStopfiles(idsToAdd);
        }
    }

    const actionHandleMonthsChange = (event: SelectChangeEvent<number>) => {
        setStopFileMonthSelected(Number(event.target.value))
        const maxDays = event.target.value;
        if (typeof maxDays !== 'number') {
            return;
        }

        const idsToSelect = [];
        const now = moment();

        for (const item of stopfiles) {
            const createdAt = moment(item.created_at);
            const diffDays = now.diff(createdAt, 'days');
            if (diffDays <= maxDays) {
                idsToSelect.push(item.id);
            }
        }
        orderManager.actionRemoveStopfiles(orderData.stopfile_ids);
        orderManager.actionAddStopfiles(idsToSelect);
    }
    
    const actionNext = () => {
        navigate('/order_leads/5');
    }

    const actionBack = () => {
        navigate('/order_leads/3');
    }

    // =====================================================================
    // === Render partial
    // =====================================================================

    const tryCreatePriceBox = () : JSX.Element|undefined => {
        if (!userData.isLoggedIn()) {
            return undefined;
        }
        const priceObject = userData.getPriceObjectFromType(ApiObjPriceObjectType.ID_LEAD_PRICE_STOPFILES, 0);
        if (priceObject === undefined) {
            return undefined;
        }
        const price = priceObject.getPriceFloat();
        if (price <= 0) {
            return undefined;
        }
        
        return (
            <Box marginLeft={'12px'}>
                <Chip label={priceObject.getPriceStringFull()} variant={'filled'} />
            </Box>
        );
    }

    const tryCreateNotLoggedIn = () : JSX.Element|undefined => {
        if (userData.isLoggedIn()) {
            return undefined;
        }
        return (
            <div>
                Du måste vara inloggad för att använda stoppfiler.
            </div>
        );
    }

    const tryCreateStopfileList = () : JSX.Element|undefined => {

        if (!userData.isLoggedIn()) {
            return undefined;
        }

        const fields = [];
        const list = createdFileType === 1 ? [...stopfiles].filter((item) => !item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : createdFileType === 2 ? [...stopfiles].filter((item) => item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS) : [...stopfiles].filter((item) => item.state === ApiObjStopfile.STATE_3_SUCCESS)

        for (const item of list) {

            const isSelected = orderData.stopfile_ids.includes(item.id);

            fields.push(
                <Box display="flex" key={item.id} justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={isSelected}
                        disabled={!orderData.canEditOrder()}
                        color={'secondary'}
                        onClick={(event) => { event.stopPropagation(); }}
                        onChange={(event) => { actionCheckboxClicked(item.id, event) }}/>
                    <Typography>{item.name}</Typography>
                    </Box>
                </Box>
            );
        }

        const isAllChecked = stopfiles.length > 0 
            && list.filter((item) => orderData.stopfile_ids.includes(item.id)).length === list.length

        const menuItemTimeSincCreate : Array<JSX.Element> = [];
        menuItemTimeSincCreate.push(
            <MenuItem
                key={0}
                value={0}>
                    {'Välj'}
            </MenuItem>
        );
        const options = [
            { value: 30, name: '30 dagar'},
            { value: 60, name: '2 månader'},
            { value: 90, name: '3 månader'},
            { value: 120, name: '4 månader'},
            { value: 150, name: '5 månader'},
            { value: 180, name: '6 månader'},
            { value: 210, name: '7 månader'},
            { value: 240, name: '8 månader'},
            { value: 270, name: '9 månader'},
            { value: 300, name: '10 månader'},
            { value: 330, name: '11 månader'},
            { value: 365, name: '12 månader'},
        ];
        for (const op of options) {
            menuItemTimeSincCreate.push(
                <MenuItem
                    key={op.value}
                    value={op.value}>
                        {op.name}
                </MenuItem>
            );
        }

        return (
            <Box mt={2}>

                <Box 
                    mb={2}
                    display="flex"
                    flexDirection={'row'}
                    key={0}
                    justifyContent="space-between"
                    alignItems="center">

                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={isAllChecked}
                            disabled={!orderData.canEditOrder()}
                            color={'secondary'}
                            onClick={(e) => { e.stopPropagation(); }}
                            onChange={(e) => { actionCheckAllClicked() }}/>
                        <Typography>Välj Alla</Typography>
                    </Box>
                    <Box display="flex" flexDirection={'row'} alignItems="center">
                        <Box>
                            <Typography>Skapad inom</Typography>
                        </Box>
                        <Box marginLeft={1}>
                            <Select
                                value={stopfileMonthSelected}
                                onChange={(event) => {actionHandleMonthsChange(event)}}>
                                    {menuItemTimeSincCreate}
                            </Select>
                        </Box>
                    </Box>
                </Box>

                <Box style={{ height: "30rem", overflowY: "auto" }}>{fields}</Box>

            </Box>
        );
    }

    return (
        <>
            <Box>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={"100%"}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}  width={"100%"}>
                        <Typography variant="h4">{'Välj Stoppfiler'}</Typography>
                        <Button variant="contained" onClick={() => setCreateStopFile(true)}>Ladda upp fil</Button>
                    </Box>
                    {tryCreatePriceBox()}
                </Box>
                <Box mt={2}>
                    {tryCreateNotLoggedIn()}
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        aria-label="Platform"
                        style={{marginTop: "1rem"}}
                        value={createdFileType}
                        onChange={(e, value) => setCreatedFileType(value)}
                    >
                        <ToggleButton value={0}>Alla ({[...stopfiles].filter((item) => item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                        <ToggleButton value={1}>Egna ({[...stopfiles].filter((item) => !item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                        <ToggleButton value={2}>System ({[...stopfiles].filter((item) => item.export_id && item.state === ApiObjStopfile.STATE_3_SUCCESS).length})</ToggleButton>
                    </ToggleButtonGroup>
                    {tryCreateStopfileList()}
                </Box>
            </Box>

            <Box
                marginTop={4}
                display={'flex'}
                flexDirection={'row'}
                style={{gap: '15px'}}
                justifyContent={'flex-start'}>

                <Button
                    onClick={actionBack}
                    variant={'outlined'}
                    color={'secondary'}
                    size={'large'}>
                        {'Tillbaka'}
                </Button>
                <Button
                    onClick={actionNext}
                    variant={'contained'}
                    color={'secondary'}
                    size={'large'}>
                        {'Nästa'}
                </Button>
            </Box>
            {
                createStopFile && (
                    <CreateStopFileModal onClose={() => {setCreateStopFile(false); navigate(0)}} userData={userData}/>
                )
            }
        </>
    );
}
