import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjApiKey } from '../../object/api_key/ApiObjApiKey';

export const api_create_api_key = async () : Promise<ApiObjApiKey> => {
    
    const url = API_URL+'/api_key';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.post(
        url,
        {},
        config
    );
        
    return new ApiObjApiKey(resp.data);
}
