import React from 'react';
import { Box, Typography } from '@mui/material';
import DialogComponent from './DialogComponent';

const DialogChildBoxComponent = ({
    newOpen = false,
    setNewOpen = (newValue) => { /* */},
    title = '',
    bodyText = '',
    bodyTextMore = '',
    children = [],
    className = ''
}) => {
  return (
    <DialogComponent newOpen={newOpen} setNewOpen={setNewOpen} className={className}>
      <Box p={5}>
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">{bodyText}</Typography>
          <br />
          {bodyTextMore ? <Typography variant="body1">{bodyTextMore}</Typography> : ''}
          {children}
        </Box>
      </Box>
    </DialogComponent>
  );
};

export default DialogChildBoxComponent;
