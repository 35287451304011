import { Box, Button, Modal, Paper, TextField } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import { api_get_ad_accounts } from '../../api/endpoint/facebook/api_get_ad_accounts';
import { ApiObjAdAccount } from '../../api/object/facebook/ApiObjAdAccount';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import { api_create_ad_account } from '../../api/endpoint/facebook/api_create_ad_account';
import { api_delete_ad_account } from '../../api/endpoint/facebook/api_delete_ad_account';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    onClose: () => void
}

export const FacebookAdAccountsModal: FunctionComponent<Props> = ({
    baseData,
    userData,
    onClose,
}) => {

    // ===========================================================
    // === States
    // ===========================================================

    const STATE_1_LOADING = 1;
    const STATE_2_IDLE = 2;
    const STATE_3_SAVING = 3;
    const STATE_4_ERROR = 4;

    // ===========================================================
    // === State
    // ===========================================================

    const [formNewName, setFormNewName] = useState <string> ('');
    const [formNewAdAccountId, setFormAdAccountId] = useState <string> ('');
    const [allAdAccounts, setAllAdAccounts] = useState <Array<ApiObjAdAccount>> ([]);
    const [myState, setMyState] = useState <number> (STATE_1_LOADING);
    const [errorMessageNew, setErrorMessageNew] = useState <string> ('');

    useEffect(() => {
        fetchAdAccounts();
    }, []);

    // ===========================================================
    // === Fetch
    // ===========================================================

    const fetchAdAccounts = () => {
        setMyState(STATE_1_LOADING);
        api_get_ad_accounts()
            .then((result) => {
                setAllAdAccounts(result);
                setMyState(STATE_2_IDLE);
            }).catch((err) => {
                setMyState(STATE_4_ERROR);
            });
    }

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionAddNew = () => {

        const errorMessage = validateNewForm();

        setErrorMessageNew(errorMessage);
        if (errorMessage !== '') {
            return;
        }

        setMyState(STATE_3_SAVING);

        const params = {
            name: formNewName,
            remote_id: formNewAdAccountId,
        }
        api_create_ad_account(params)
            .then((result) => {
                fetchAdAccounts();

            }).catch((err) => {
                setMyState(STATE_4_ERROR);
            });
    }

    const actionDelete = (adAccountId: number) => {
        api_delete_ad_account(adAccountId)
            .then((result) => {
                fetchAdAccounts();

            }).catch((err) => {
                setMyState(STATE_4_ERROR);
            });
    }

    // ===========================================================
    // === Validate
    // ===========================================================

    const validateNewForm = () : string => {

        if (formNewName.length === 0) {
            return 'Namn måste anges.';
        } else if (formNewName.length > 50) {
            return 'Namn är för långt.';
        }
        if (formNewAdAccountId.length === 0) {
            return 'Annonskonto-id måste anges.';
        } else if (formNewAdAccountId.length > 50) {
            return 'Annonskonto-id är för långt.'
        }
        for (const item of allAdAccounts) {
            if (item.remote_id === formNewAdAccountId) {
                return 'Detta annonskonto är redan sparat under namnet '+item.name
            }
        }
        const allowedChars = ['0','1','2','3','4','5','6','7','8','9'];
        for (let k = 0; k < formNewAdAccountId.length; k ++) {
            const tempChar = formNewAdAccountId.charAt(k);
            if (!allowedChars.includes(tempChar)) {
                return 'Annonskonto-id får bara innehålla siffror.';
            }
        }
        return '';
    }

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const renderAddForm = () : JSX.Element => {

        const disabled = ![STATE_2_IDLE, STATE_4_ERROR].includes(myState);

        return (
            <Box>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'12px'}>

                    <TextField
                        id="new_name"
                        label={'Namn'}
                        disabled={disabled}
                        variant="outlined"
                        value={formNewName}
                        onChange={(e) => {setFormNewName(e.target.value)}}
                        style = {{width: '200px'}}
                    />

                    <TextField
                        id="new_ad_account_id"
                        label={'Annonskonto-id'}
                        disabled={disabled}
                        variant="outlined"
                        value={formNewAdAccountId}
                        onChange={(e) => {setFormAdAccountId(e.target.value)}}
                        style = {{width: '200px'}}
                    />

                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={disabled}
                        onClick={(event) => {
                            event.preventDefault();
                            actionAddNew();
                        }}>
                            Lägg till
                    </Button>

                </Box>
                <Box
                    paddingTop={'6px'}
                    color={'red'}>
                        {errorMessageNew}
                </Box>
            </Box>
        );
    }

    const columns = [

        {
            field: 'id',
            headerName: 'id',
            width: 70,
            editable: false,
            hideable: false,
            disableColumnMenu: true,
        },
        {
            field: 'name',
            headerName: 'Namn',
            width: 250,
            editable: false,
            hideable: false,
            disableColumnMenu: true,
        },
        {
            field: 'remote_id',
            headerName: 'Annonskonto-id',
            width: 150,
            editable: false,
            hideable: false,
            disableColumnMenu: true,
        },
        {
            field: 'edit',
            headerName: '',
            width: 250,
            editable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => {
                
                const adAccountId = params.row.id;
                const disabled = ![STATE_2_IDLE, STATE_4_ERROR].includes(myState);
                
                return (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        gap={'12px'}>

                        <Button
                            color={'warning'}
                            variant='outlined'
                            disabled={disabled}
                            onClick={(e) => {
                                actionDelete(adAccountId);
                            }}>
                                <ClearIcon />
                        </Button>
                    </Box>
                );
            }
        },
    ];

    const renderList = () : JSX.Element => {
        return (
            <Box height={'700px'}>
                <DataGrid
                    rows={allAdAccounts}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                    isRowSelectable={() => {return false;}}
                />
            </Box>
        );
    }

    const tryRenderError = () : JSX.Element|undefined => {

        if (myState !== STATE_4_ERROR) {
            return undefined;
        }
        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                color={'red'}
                paddingTop={'16px'}>
                    {'Något gick fel'}
            </Box>
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '820px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        width={'780px'}
                        height={'800px'}
                        padding={'14px 14px 14px 14px'}
                        gap={'16px'}>
                        <Box>
                            {'Lägg till annonskonto'}
                        </Box>
                        {renderAddForm()}
                        <Box>
                            {'Sparade Konton'}
                        </Box>
                        {renderList()}
                        {tryRenderError()}
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}