import { Box, Chip, Typography } from '@mui/material';
import '../sass/animatedBlockComponent.sass';

export const AnimatedBlockComponent = ({
  className = '',
  title,
  subtitle,
  onClick,
  data = '',
  align = 'left',
  blockSubtitle = '',
  isChecked = false,
  leadsPrice = '',
}) => {
  
  let leadsPriceElement = undefined;

  if (leadsPrice !== '') {
    leadsPriceElement = (
      <Box marginLeft={'12px'}>
        <Chip label={leadsPrice} color={isChecked ? 'secondary' : 'primary'} variant={'outlined'} />
      </Box>
    );
  }

  return (
    <Box
      mt={2}
      className={`animated-block ${className} ${isChecked ? 'checked-block' : ''}`}
      onClick={() => (onClick ? onClick(data) : '')}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="h6" align={align}>
          {title}
        </Typography>
        <Box display={'flex'} flexDirection={'row'}>
          {leadsPriceElement}
        </Box>
      </Box>
      {blockSubtitle ? <Box>{subtitle}</Box> : <Typography variant="body1">{subtitle}</Typography>}
    </Box>
  );
};
