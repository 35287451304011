import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, LinearProgress, LinearProgressProps, MenuItem, Select, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { api_populate_file_order_get_step_1 } from '../../../api/endpoint/populate_file/api_populate_file_order_get_step_1';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';
import { ApiObjPopulateFileOrderStep1 } from '../../../api/object/populate_file/ApiObjPopulateFileOrderStep1';
import CircularProgress from '@mui/material/CircularProgress';
import { api_populate_file_order_post_step_1 } from '../../../api/endpoint/populate_file/api_populate_file_order_post_step_1';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
    populateFile: ApiObjPopulateFile,
    parentSetPopulateFile: (populateFile: ApiObjPopulateFile) => void
}

export const OrderStep1Preview: FunctionComponent <Props> = ({
    baseData,
    userData,
    populateFile,
    parentSetPopulateFile
}) => {

    const STATE_LOADING = 1;
    const STATE_IDLE = 2;
    const STATE_SAVING = 3;
    const STATE_WAITING_FOR_COMPLETION = 4;

    const [state, setState] = useState <number> (STATE_LOADING);

    const [orderStep1Obj, setOrderStep1Obj] = useState <undefined|ApiObjPopulateFileOrderStep1> (undefined);

    const [formColumnTypes, setFormColumnTypes] = useState <Array<number>> ([]);
    const [formMatchMode, setFormMatchMode] = useState <number> (ApiObjPopulateFile.MATCH_MODE_1_STRICT);

    const navigate = useNavigate();

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const delay = populateFile.count.rows * 0.003254 * 100;
        //const delay = populateFile.count.rows <= 10000 ? 500 : populateFile.count.rows <= 50000 ? 1000 : 1500;
        let timer: NodeJS.Timer | undefined;
        if(state === STATE_LOADING || state === STATE_SAVING || state === STATE_WAITING_FOR_COMPLETION){
            if(timer) clearInterval(timer)
            timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 90 ? 95 : prevProgress + 10));
            }, delay);
        }
        return () => {
        if(timer) clearInterval(timer);
        };
    }, [state]);
    
    useEffect(() => {
        setState(STATE_LOADING);
        api_populate_file_order_get_step_1(populateFile.id)
            .then((result) => {
                setOrderStep1Obj(result);
                setFormColumnTypes([...result.column_types]);
                setFormMatchMode(result.match_mode);
                setState(STATE_IDLE);
            });
    }, [populateFile.id]);

    useEffect(() => {
        if (state === STATE_WAITING_FOR_COMPLETION) {
            if (populateFile.state === ApiObjPopulateFile.STATE_6_PARSE_COMPLETE) {
                setState(STATE_IDLE);
                navigate('/populate_file/'+populateFile.id+'/order_step_2');
            } else if (populateFile.state === ApiObjPopulateFile.STATE_5_PARSE_FAILED) {
                setState(STATE_IDLE);
                // TODO hantera fel.
            }
        }
    }, [populateFile.state]);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionSetSelectedType = (index: number, newType: number) => {
        const newArray = [...formColumnTypes];
        newArray[index] = newType;
        setFormColumnTypes(newArray);
    }

    const actionNext = () => {
        if (state !== STATE_IDLE || populateFile.isStateProcessing()) {
            return;
        }
        setState(STATE_SAVING);
        api_populate_file_order_post_step_1(populateFile.id, formColumnTypes, formMatchMode)
            .then((result) => {
                setState(STATE_WAITING_FOR_COMPLETION);
                parentSetPopulateFile(result);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    // TODO ERIK byt denna mot EstimatedProgressBar
    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }

    const renderMatchMode = () : undefined|JSX.Element => {

        if (state === STATE_LOADING || orderStep1Obj === undefined) {
            return undefined;
        }

        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} gap={'13px'} alignItems={'center'}>
                <Box>Matchningsmetod</Box>
                <Select
                    value={formMatchMode}
                    disabled={state !== STATE_IDLE}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        if (typeof newValue === 'string') {
                            newValue = parseInt(newValue);
                        }
                        setFormMatchMode(newValue);
                    }}>
                        <MenuItem key={1} value={ApiObjPopulateFile.MATCH_MODE_1_STRICT}>Strikt</MenuItem>
                        <MenuItem key={2} value={ApiObjPopulateFile.MATCH_MODE_2_LOOSE}>Mindre strikt (fler matcher men högre risk för fel)</MenuItem>
                </Select>
            </Box>
        );

    }

    const renderRowSelects = () : undefined|JSX.Element => {

        if (state === STATE_LOADING || orderStep1Obj === undefined) {
            return undefined;
        }

        const cells: Array<JSX.Element> = [];
        for (const k in formColumnTypes) {

            const index = parseInt(k);
            const currentType = formColumnTypes[k];

            let selStyle = {
                backgroundColor: '#EEEEEE',
                paddingLeft: '10px',
                width: "90%",
            } as React.CSSProperties;

            if (currentType > 0) {
                selStyle = {...selStyle, fontWeight: 'bold'};
            }

            const options = [];
            options.push(<MenuItem key={0} value={0}>-</MenuItem>);
            for (const col of ApiObjPopulateFileOrderStep1.COLUMN_NAMES) {
                options.push(<MenuItem key={col.column} value={col.column}>{col.name}</MenuItem>);
            }

            const select = (
                <Select
                    id={k}
                    value={currentType}
                    style={selStyle}
                    disabled={state !== STATE_IDLE}
                    onChange={(e) => {
                        let newValue = e.target.value;
                        if (typeof newValue === 'string') {
                            newValue = parseInt(newValue);
                        }
                        actionSetSelectedType(index, newValue);
                    }}>
                        {options}
                </Select>
            );
            cells.push(<td key={k}>{select}</td>);
        }

        return (<tr key={1}>{cells}</tr>);
    }

    const renderRowsData = () : Array<JSX.Element> => {
        const rows: Array<JSX.Element> = [];

        if (orderStep1Obj === undefined) {
            return [];
        }

        for (const rowIndex in orderStep1Obj.rows) {

            const row = orderStep1Obj.rows[rowIndex];
            const cells : Array<JSX.Element> = [];

            for (const cellIndex in row) {
                const cell = row[cellIndex];
                cells.push(<td key={cellIndex} style={{padding: "0.5rem 7.5rem 0.5rem 0"}}>{cell}</td>);
            }

            rows.push(<tr key={rowIndex}>{cells}</tr>);
        }
        return rows;
    }

    const renderButtonNext = () : JSX.Element => {
        return (
            <Button
                variant='contained'
                disabled={state !== STATE_IDLE}
                onClick={actionNext}>
                    Läs filen
            </Button>
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <>
            {
                state === STATE_LOADING ? <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}><CircularProgress/></Box> :
                state === STATE_LOADING || state === STATE_SAVING || state === STATE_WAITING_FOR_COMPLETION ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"50vh"}>
                        <Box sx={{ width: '50%' }}>
                            <LinearProgressWithLabel variant="determinate" value={progress} />
                        </Box>
                    </Box>
                ) : (
                    <Box display={'flex'} flexDirection={'column'}>
                        <Box margin="1.5rem 0">
                            {renderMatchMode()}
                        </Box>
                        <Box marginTop={'12px'} style={{overflow: 'auto', height: "30rem"}}>
                            <table>
                                <tbody>
                                    {renderRowSelects()}
                                    {renderRowsData()}
                                    
                                </tbody>
                            </table>
                        </Box>
                        <Box mt={2} style={{overflowX: 'auto'}}>
                            {renderButtonNext()}
                        </Box>
                    </Box>
                )
            }
        </>
    );
};
