import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { MapAreaCircle } from '../../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../../data_layer/map/MapAreaPolygon';
import { ApiObjCountOrder } from '../../object/filter/ApiObjCountOrder';

export interface CountOrderParams {
    nixOptionId: number,
    sourceId: number,
    filterIds: Array<number>,
    mapAreas: Array<MapAreaCircle|MapAreaPolygon>,
    stopfileIds: Array<number>,
    householdOptionId: number,
    requestCounter: number,
}

export const api_get_filter_count_order = async (params: CountOrderParams) : Promise<ApiObjCountOrder> => {

    params.filterIds = [...params.filterIds];
    params.stopfileIds = [...params.stopfileIds];

    const url = API_URL+'/data/count-order';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const payloadMapAreas = [];
    for (const mapArea of params.mapAreas) {
        if (mapArea instanceof MapAreaCircle) {
            payloadMapAreas.push({
                type: 'circle',
                center_lat: mapArea.centerPoint.latitude,
                center_long: mapArea.centerPoint.longitude,
                radius: mapArea.radius
            });

        } else if (mapArea instanceof MapAreaPolygon) {
            const tempPoints = [];
            for (const point of mapArea.points) {
                tempPoints.push({
                    lat: point.latitude,
                    long: point.longitude,
                });
            }
            payloadMapAreas.push({
                type: 'polygon',
                points: tempPoints
            });
        } else {
            throw new Error("Unexpected map area type");
        }
    }

    const data = {
        nix_option_id: params.nixOptionId,
        source_id: params.sourceId,
        filter_ids: params.filterIds,
        map_areas: payloadMapAreas,
        stopfile_ids:  params.stopfileIds,
        household_option_id: params.householdOptionId,
    }
    
    const result = await Axios.post(
        url,
        data,
        config
    );
        
    const tempParams = {
        data: result.data,
        requestCounter: params.requestCounter,
    }
    return new ApiObjCountOrder(tempParams);
}
