import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { POST } from '../../../functions/axiosSending';
import { USER_REGISTER_CONFIRM } from '../../../ApiRoutes';

export const RegisterConfirmRoute = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate('/account/login');
  };

  const handleConfirm = async () => {
    const { id, email } = await POST(USER_REGISTER_CONFIRM, { token });
  };

  useEffect(() => {
    handleConfirm().catch((e) => console.log(e));
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h4">{'Registrering Bekräftad'}</Typography>
      <Typography variant="body1" align="center">
        {'Du har bekräftat din registrering.'}
      </Typography>
      <Box mt={5} display={'flex'} justifyContent={'center'}>
        <Button
          type={'submit'}
          variant={'contained'}
          color={'secondary'}
          size={'large'}
          onClick={handleRoute}>
            {'Logga in'}
        </Button>
      </Box>
    </Box>
  );
};
