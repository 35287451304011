import React, { useEffect, useState } from 'react';
// import '../sass/tabsComponent.sass';
import { Box, Tab, Tabs, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const TabsComponent = ({ value, search, tabs, tabContent, selectedDate, setValue, setSearch, setSelectedDate }) => {
  const handleChange = (event, newValue) => {
    setVisibleElement(tabContent[newValue]);
    setValue(newValue);
  };
  const [titleTabs, setTitleTabs] = useState([]);
  const [visibleElement, setVisibleElement] = useState('');
  useEffect(() => {
    handleChange(0, value);
    setTitleTabs(tabs.map((el, index) => <Tab key={index} label={el} />));
    // eslint-disable-next-line
  }, [tabContent]);
  return (
    <section className="tabs-container">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Tabs
          value={value}
          onChange={handleChange}
          color="primary"
          indicatorColor="primary"
          className="tab"
          aria-label="tabs"
        >
          {titleTabs}
        </Tabs>
        <Box display={"flex"} alignItems={"end"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              slotProps={{ textField: { variant: 'standard', },actionBar: {
                actions: ["clear"]
              } }}
            />
          </LocalizationProvider>
          <Box maxWidth={"25rem"} marginLeft={"2.5rem"} width="100%">
            <TextField id="standard-basic" label="Sök" variant="standard" value={search} onChange={(e) => setSearch(e.target.value)} fullWidth />
          </Box>
        </Box>
      </Box>
      {visibleElement}
    </section>
  );
};

export default TabsComponent;
