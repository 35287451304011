import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_get_sources = async (userId: number) : Promise<Array<number>> => {

    const url = API_URL+'/admin/user/'+userId+'/source';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    
    const resp = await Axios.get(
        url,
        config
    );
        
    const ret = [];
    for (const item of resp.data) {
        ret.push(parseInt(item));
    }
    return ret;
}
