import { useEffect, useState } from 'react';
import CustomersTableComponent from '../../../components/customer/CustomersTable/CustomersTableComponent';
import { useNavigate } from 'react-router-dom';

import { POST } from '../../../functions/axiosSending';
import { CUSTOMERS } from '../../../ApiRoutes';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';

export const CustomersListingPage = ({
  userData
}) => {

  if (!userData.isLoggedInAndAdmin()) {
    return (<ErrorPageNoAdmin />);
  }

  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const editUser = (id) => navigate(`/admin/customers/${id}`);

  useEffect(() => {
    POST(CUSTOMERS, { admin: 'foobar' })
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
      <CustomersTableComponent customers={customers} editUser={editUser} />
  );
};
