import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_save_nix_options = async (userId: number, nixOptionIds: Array<number>) : Promise<Array<number>> => {

    const url = API_URL+'/admin/user/'+userId+'/nix_option';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.put(
        url,
        nixOptionIds,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(parseInt(item));
    }
    return ret;
}
