import { MapAreaCircle } from "./MapAreaCircle";
import { MapAreaPolygon } from "./MapAreaPolygon";

export class MapArea {

    // ==========================================================================
    // === Static Compare
    // ==========================================================================

    public static isEqual(o1: MapAreaCircle|MapAreaPolygon, o2: MapAreaCircle|MapAreaPolygon) : boolean {
        if (o1 instanceof MapAreaCircle && o2 instanceof MapAreaCircle) {
            return o1.isEqual(o2);

        } else if (o1 instanceof MapAreaPolygon && o2 instanceof MapAreaPolygon) {
            return o1.isEqual(o2);

        } else {
            return false;
        }
    }

    public static isArraysEqual(a1: Array<MapAreaCircle|MapAreaPolygon>, a2: Array<MapAreaCircle|MapAreaPolygon>) {
        if (a1.length !== a2.length) {
            return false;
        }
        for (let k = 0; k < a1.length; k ++) {
            if (!MapArea.isEqual(a1[k], a2[k])) {
                return false;
            }
        }
        return true;
    }
}