import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const RegisterSuccessPage = () => {
  
  const navigate = useNavigate();

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h4" align='center'>{'Ditt konto har skapats!'}</Typography>
      <Typography variant="body1" align="center">
        {'Ditt konto har skapats och vi har skickat en bekräftelselänk till din e-post.'}
      </Typography>
      <Box mt={5} display={'flex'} justifyContent={'center'}>
        <Button
          type={'submit'}
          variant={'contained'}
          color={'secondary'}
          size={'large'}
          onClick={() => {navigate('/account/login')}}>
            {'Logga in'}
        </Button>
      </Box>
    </Box>
  );
};
