import { Box, Button } from '@mui/material';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorPageNoAdmin } from '../../error/ErrorPageNoAdmin';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { ApiObjAdminBaseData } from '../../../api/object/admin/ApiObjAdminBaseData';
import { DataGrid } from '@mui/x-data-grid';
import { MoveToUserModal } from '../../shared_components/MoveToUserModal';
import { api_admin_get_users } from '../../../api/endpoint/admin/api_admin_get_users';
import { ApiObjAdminUserCompact } from '../../../api/object/admin/ApiObjAdminUserCompact';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import { api_admin_user_get_stopfiles } from '../../../api/endpoint/admin/user/stopfiles/api_admin_user_get_stopfiles';
import { api_admin_user_transfer_stopfile } from '../../../api/endpoint/admin/user/stopfiles/api_admin_user_transfer_stopfile';
import { util_format_datetime } from '../../../util/format/util_format';

type RowData = {
    id: number,
    name: string,
    created_at: string,
    state: string,
    create_method: string,
    row_count: number,
    match_count: number,
    options: number,
}

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    adminBaseData: ApiObjAdminBaseData
    otherUserId: number
}

export const AdminUserTabStopfiles: FunctionComponent <Props> = ({
    baseData,
    userData,
    adminBaseData,
    otherUserId
}) => {

    if (!userData.isLoggedInAndAdmin()) {
        return <ErrorPageNoAdmin />;
    }

    const [stopfileObjs, setStopfileObjs] = useState <Array<ApiObjStopfile>> ([]);
    const [isLoading, setIsLoading] = useState <boolean> (true);
    const [allUsers, setAllUsers] = useState <Array<ApiObjAdminUserCompact>> ([]);
    const [moveModalStopfileObj, setMoveModalStopfileObj] = useState <undefined|ApiObjStopfile> (undefined);

    // ======================================================================
    // === Setup
    // ======================================================================

    useEffect(() => {

        setIsLoading(true);
        fetchStopfiles();

    }, [otherUserId]);

    useEffect(() => {

        api_admin_get_users()
            .then((result) => {
                setAllUsers(result);

            }).catch((err) => {
                console.error("Could not fetch users list");
                console.error(err);
            });

    }, []);

    
    const fetchStopfiles = () => {
        api_admin_user_get_stopfiles(otherUserId)
            .then((result) => {
                setStopfileObjs(result);
                setIsLoading(false);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionOpenMoveModal = (stopfileId: number) => {

        let foundStopfile = undefined;
        for (const item of stopfileObjs) {
            if (item.id === stopfileId) {
                foundStopfile = item;
                break;
            }
        }
        setMoveModalStopfileObj(foundStopfile);
    }

    const actionMoveStopfile = (newUserId: number) => {

        if (moveModalStopfileObj === undefined) {
            return;
        }

        api_admin_user_transfer_stopfile(otherUserId, moveModalStopfileObj.id, newUserId)
            .then(() => {
                console.log("Flytten lyckades!");
                fetchStopfiles();
                setMoveModalStopfileObj(undefined);

            }).catch((err) => {
                console.error("Move stopfile failed");
                console.error(err);
            });
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 80,
            editable: false
        },
        {
            field: 'name',
            headerName: 'Namn',
            width: 220,
            editable: false,
        },
        {
            field: 'created_at',
            headerName: 'Skapad',
            width: 180,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 100,
            editable: false,
        },
        {
            field: 'create_method',
            headerName: 'Metod',
            width: 100,
            editable: false,
        },
        {
            field: 'row_count',
            headerName: 'Rader',
            width: 100,
            editable: false,
        },
        {
            field: 'match_count',
            headerName: 'Matchingar',
            width: 100,
            editable: false,
        },
        {
            field: 'options',
            headerName: 'Val',
            width: 350,
            editable: false,
            renderCell: (params: any) => {

                return (
                    <Box display={'flex'} flexDirection={'row'}>
                        <Button
                            variant={'contained'}
                            type={'button'}
                            color={'secondary'}
                            size={'small'}
                            onClick={(e) => {
                                const stopfileId = params.row.id;
                                actionOpenMoveModal(stopfileId);
                            }}>
                                {'Flytta'}
                        </Button>
                    </Box>
                );
            }
        }
    ];

    const getRows = () => {

        const ret : Array<RowData> = [];
        for (const item of stopfileObjs) {
            ret.push({
                id: item.id,
                name: item.name,
                created_at: util_format_datetime(item.created_at),
                state: item.state + '',
                create_method: item.create_method,
                row_count: item.row_count,
                match_count: item.match_count.total,
                options: 0
            });
        }
        return ret;
    }

    if (isLoading) {
        return (<Box padding={'14px'}>Laddar...</Box>);
    }

    const tryRenderMoveModal = () : undefined|JSX.Element => {

        if (moveModalStopfileObj === undefined) {
            return undefined;
        }

        return (
            <MoveToUserModal
                objectTypeName={'Stoppfil'}
                objectDescription={moveModalStopfileObj.name}
                users={allUsers}
                onClose={() => {setMoveModalStopfileObj(undefined)}}
                onMakeMove={(newUserId: number) => {actionMoveStopfile(newUserId)} }
            />
        );
    }

    return (
        <Box padding={'14px'} display={'flex'} flexDirection={'column'}>
            <Box height={'770px'}>
                <DataGrid
                    rows={getRows()}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                    isRowSelectable={() => {return false;}}
                />
            </Box>
            {tryRenderMoveModal()}
        </Box>
    );
}
