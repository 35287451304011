import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';

export const api_delete_ad_account = async (id: number) : Promise<void> => {
    
    const url = API_URL+'/facebook/ad_account/'+id;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
        
    await Axios.delete(
        url,
        config
    );
}
