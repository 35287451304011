import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjUserMy } from '../../object/user/ApiObjUserMy';

export interface UpdateMyUserData {
    first_name?: string,
    last_name?: string,
    company_name?: string,
    company_number?: string,
    address?: string,
    zipcode?: string,
    city?: string,
    phone_number?: string,
}

export const api_update_my_user_details = async (data: UpdateMyUserData) : Promise<ApiObjUserMy> => {

    const url = API_URL+'/my_user/details'
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }

    const resp = await Axios.patch(
        url,
        data,
        config
    )

    return new ApiObjUserMy(resp.data);
}
