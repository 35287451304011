import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_1 = async (populateFileId: number, columnTypes: Array<number>, matchMode: number) : Promise<ApiObjPopulateFile> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_1';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const body = {
        column_types: columnTypes,
        match_mode: matchMode
    }
    const resp = await Axios.post(
        url,
        body,
        config
    );
        
    return new ApiObjPopulateFile(resp.data);
}
