export class StaticPopulateFileDataOption {

    // ==============================================================
    // === Types
    // ==============================================================

    public static readonly ID_1_NAME_AND_ADDRESS = 1;
    public static readonly ID_2_PHONE_NUMBER_NIX_FREE = 2;
    public static readonly ID_3_PHONE_NUMBER_ALL = 3;
    public static readonly ID_4_HOME_INFO = 4;
    public static readonly ID_5_CAR_INFO = 5;
    public static readonly ID_6_PERSONAL_ID = 6;
    public static readonly ID_7_ELNET = 7;

    // ==============================================================
    // === Instances
    // ==============================================================

    public static readonly ALL : Array<StaticPopulateFileDataOption> = [
        {
            id: this.ID_1_NAME_AND_ADDRESS,
            name: 'Namn och adress'
        },
        {
            id: this.ID_2_PHONE_NUMBER_NIX_FREE,
            name: 'Mobilnummer nix fria'
        },
        {
            id: this.ID_3_PHONE_NUMBER_ALL,
            name: 'Mobilnummer alla'
        },
        {
            id: this.ID_4_HOME_INFO,
            name: 'Bostadsinformation'
        },
        {
            id: this.ID_5_CAR_INFO,
            name: 'Bilinformation'
        },
        {
            id: this.ID_6_PERSONAL_ID,
            name: 'Personnummer'
        },
        {
            id: this.ID_7_ELNET,
            name: 'Elnätsinformation'
        },
    ];

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    name: string;
    
    // ==============================================================
    // === Create
    // ==============================================================

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }

    // ==============================================================
    // === Get
    // ==============================================================

    static fromId = (id: number) : undefined|StaticPopulateFileDataOption => {
        for (const item of this.ALL) {
            if (item.id === id) {
                return item;
            }
        }
        return undefined;
    }
}