import { Box, Button, Checkbox, Modal, Paper, TextField, Autocomplete } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../data_layer/user/UserData';
import { MapAreaCircle } from '../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../data_layer/map/MapAreaPolygon';
import { ApiObjTargetGroup } from '../../api/object/target_group/ApiObjTargetGroup';
import { api_get_ad_accounts } from '../../api/endpoint/facebook/api_get_ad_accounts';
import { ApiObjAdAccount } from '../../api/object/facebook/ApiObjAdAccount';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { api_update_target_group } from '../../api/endpoint/target_group/api_update_target_group';
import { api_create_target_group } from '../../api/endpoint/target_group/api_create_target_group';
import { FacebookAdAccountsModal } from './FacebookAdAccountsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import { ConfirmModal } from './ConfirmModal';

type Props = {
    target_group: ApiObjTargetGroup|undefined
    new_target_group_data?: {
        name?: string,
        source_id?: number,
        nix_option_id?: number,
        filter_ids?: Array<number>,
        map_areas?: Array<MapAreaCircle|MapAreaPolygon>;
        export_field_ids?: Array<number>,
        stopfile_ids?: Array<number>,
        stopfile_days?: number,
        household_option_id?: number
    }
    baseData: ApiObjBaseData,
    userData: UserData,
    onClose: () => void
}

export const FacebookTargetGroupModal: FunctionComponent<Props> = ({
    target_group,
    new_target_group_data,
    baseData,
    userData,
    onClose,
}) => {

    // ===========================================================
    // === States
    // ===========================================================

    const STATE_1_LOADING = 1;
    const STATE_2_IDLE = 2;
    const STATE_3_SAVING = 3;
    const STATE_4_ERROR = 4;

    // ===========================================================
    // === State
    // ===========================================================

    const [formName, setFormName] = useState <string> ('');
    const [formIsAutoUpdate, setFormIsAutoUpdate] = useState <boolean> (false);
    const [formAdAccountIds, setFormAdAccountIds] = useState <Array<string>> ([]);
    const [allAdAccounts, setAllAdAccounts] = useState <Array<ApiObjAdAccount>> ([]);
    const [allAdAccountsMap, setAllAdAccountsMap] = useState <Map<string, ApiObjAdAccount>> (new Map());
    const [isEditAccountsOpen, setIsEditAccountsOpen] = useState <boolean> (false);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState <boolean> (false);
    const [myState, setMyState] = useState <number> (STATE_1_LOADING);

    useEffect(() => {

        if (target_group !== undefined && target_group.facebook_target_group !== undefined) {
            setFormIsAutoUpdate(target_group.facebook_target_group.is_auto_update);
            setFormAdAccountIds([...target_group.facebook_target_group.ad_account_ids]);
        }
        fetchAdAccounts();
        if (new_target_group_data !== undefined && new_target_group_data.name !== undefined) {
            setFormName(new_target_group_data.name);
        }

    }, []);

    // ===========================================================
    // === Fetch
    // ===========================================================

    const fetchAdAccounts = () => {
        api_get_ad_accounts()
            .then((result) => {
                const tempMap = new Map();
                for (const item of result) {
                    tempMap.set(item.remote_id, item);
                }
                setAllAdAccounts(result);
                setAllAdAccountsMap(tempMap);
                setMyState(STATE_2_IDLE);
            });
    }

    // ===========================================================
    // === Actions
    // ===========================================================

    const actionCreateAndShare = () => {

        if (new_target_group_data === undefined) {
            return;
        }

        const nd = new_target_group_data;

        const data = {
            name: formName,
            nix_option_id: nd.nix_option_id,
            source_id: nd.source_id,
            filter_ids: nd.filter_ids,
            map_areas: nd.map_areas,
            export_field_ids: nd.export_field_ids,
            stopfile_ids: nd.stopfile_ids,
            stopfile_days: nd.stopfile_days,
            household_option_id: nd.household_option_id,
            facebook_target_group: {
                is_enabled: true,
                is_auto_update: formIsAutoUpdate,
                ad_account_ids: formAdAccountIds
            }
        };
        setMyState(STATE_3_SAVING);
        api_create_target_group(data)
            .then((result) => {
                onClose();

            }).catch((err) => {
                console.error(err);
                setMyState(STATE_4_ERROR);
            })
    }

    const actionShare = () => {

        if (target_group === undefined) {
            return;
        }
        const data = {
            id: target_group.id,
            facebook_target_group: {
                is_enabled: true,
                is_auto_update: formIsAutoUpdate,
                ad_account_ids: formAdAccountIds
            }
        }
        setMyState(STATE_3_SAVING);
        api_update_target_group(data)
            .then((result) => {
                onClose();

            }).catch((err) => {
                console.error(err);
                setMyState(STATE_4_ERROR);
            });
    }

    const actionUnShare = () => {

        if (target_group === undefined) {
            return;
        }
        const data = {
            id: target_group.id,
            facebook_target_group: {
                is_enabled: false,
                is_auto_update: formIsAutoUpdate,
                ad_account_ids: formAdAccountIds
            }
        }
        setMyState(STATE_3_SAVING);
        api_update_target_group(data)
            .then((result) => {
                onClose();

            }).catch((err) => {
                console.error(err);
                setMyState(STATE_4_ERROR);
            });
    }

    // ===========================================================
    // === Render Sections
    // ===========================================================

    const renderModalTitle = () : JSX.Element => {

        let text = '';
        if (target_group === undefined) {
            text = 'Skapa målgrupp och dela till Meta'
        } else if (target_group.facebook_target_group === undefined) { 
            text = 'Dela målgrupp till Meta'
        } else {
            text = 'Redigera Meta delning';
        }

        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box fontWeight={'bold'}>
                    {text}
                </Box>
                <Box>
                    {tryRenderButtonUnshare()}
                </Box>
            </Box>
        );
    }

    const tryRenderInputName = () : JSX.Element|undefined => {

        if (target_group !== undefined) {
            return undefined;
        }
        const disabled = myState !== STATE_2_IDLE;
        return (
            <TextField
                id="target_group_description"
                label={'Namn på målgrupp'}
                disabled={disabled}
                variant="outlined"
                value={formName}
                onChange={(e) => {setFormName(e.target.value)}}
                style = {{width: '300px'}}
            />
        );
    }

    const renderInputIsAutoUpdate = () : JSX.Element => {

        const disabled = myState !== STATE_2_IDLE;

        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                alignItems={'center'}
                gap={'12px'}>
                <Box>
                    Uppdatera automatiskt
                </Box>
                <Box>
                    <Checkbox
                        checked={formIsAutoUpdate}
                        disabled={disabled}
                        color={'secondary'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFormIsAutoUpdate(event.target.checked);
                        }}
                    />
                </Box>
            </Box>
        );
    }

    const renderSelectAdAccounts = () : JSX.Element => {

        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;

        const allAccountIds = [];
        for (const item of allAdAccounts) {
            allAccountIds.push(item.remote_id);
        }

        const disabled = myState !== STATE_2_IDLE && myState !== STATE_4_ERROR;

        return (
            <Box
                paddingTop={'16px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={'12px'}>

                <Autocomplete
                    multiple
                    id="select_ad_accounts"
                    disabled={disabled}
                    options={allAccountIds}
                    disableCloseOnSelect
                    value={formAdAccountIds}
                    onChange={(event, value) => {
                        setFormAdAccountIds(value)
                    }}
                    getOptionLabel={(option) => {
                        const adAccount = allAdAccountsMap.get(option);
                        if (adAccount !== undefined) {
                            return adAccount.name + ' ('+adAccount.remote_id+')'
                        } else {
                            return option;
                        }
                    }}
                    renderOption={(props, option, { selected }) => {

                        const adAccount = allAdAccountsMap.get(option);
                        let optionName = option;
                        let key = option;
                        if (adAccount !== undefined) {
                            optionName = adAccount.name + ' ('+adAccount.remote_id+')';
                            key = adAccount.id+'';
                        }

                        return (
                            <li {...props} key={key}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                    {optionName}
                            </li>
                        );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Dela till annonskonton" placeholder="Dela till annonskonton" />
                    )}
                />

                <Button
                    color={'secondary'}
                    size='medium'
                    variant="contained"
                    onClick={(e) => {
                        setIsEditAccountsOpen(true);
                    }}>
                        <SettingsIcon />
                </Button>
                
            </Box>
        );
    }

    const tryRenderButtonCreateAndShare = () : JSX.Element|undefined => {

        if (target_group !== undefined) {
            return undefined;
        }

        const disabled = 
            myState === STATE_1_LOADING
            || myState === STATE_3_SAVING
            || formAdAccountIds.length === 0
            || formName.length === 0;

        return (
            <Button
                variant='contained'
                color='secondary'
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    actionCreateAndShare();
                }}>
                    Skapa och dela
            </Button>
        );
    }

    const tryRenderButtonShare = () : JSX.Element|undefined => {

        if (target_group === undefined || target_group.facebook_target_group !== undefined) {
            return undefined;
        }

        const disabled = 
            myState === STATE_1_LOADING
            || myState === STATE_3_SAVING
            || formAdAccountIds.length === 0

        return (
            <Button
                variant='contained'
                color='secondary'
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    actionShare();
                }}>
                    Dela
            </Button>
        );
    }

    const tryRenderButtonUpdateShare = () : JSX.Element|undefined => {

        if (target_group === undefined || target_group.facebook_target_group === undefined) {
            return undefined;
        }

        const disabled = 
            myState === STATE_1_LOADING
            || myState === STATE_3_SAVING;

        return (
            <Button
                variant='contained'
                color='secondary'
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    actionShare();
                }}>
                    Uppdatera delning
            </Button>
        );
    }

    const tryRenderButtonUnshare = () : JSX.Element|undefined => {

        if (target_group === undefined || target_group.facebook_target_group === undefined) {
            return undefined;
        }

        const disabled = 
            myState === STATE_1_LOADING
            || myState === STATE_3_SAVING;

        return (
            <Button
                variant='contained'
                color='warning'
                disabled={disabled}
                onClick={(event) => {
                    event.preventDefault();
                    setIsConfirmDeleteOpen(true);
                }}>
                    Ta bort delning
            </Button>
        );
    }

    const renderButtons = () : JSX.Element => {

        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                paddingTop={'16px'}>
                    {tryRenderButtonCreateAndShare()}
                    {tryRenderButtonShare()}
                    {tryRenderButtonUpdateShare()}
            </Box>
        )
    }

    const tryRenderError = () : JSX.Element|undefined => {

        if (myState !== STATE_4_ERROR) {
            return undefined;
        }
        return (
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyItems={'space-between'}
                color={'red'}
                paddingTop={'16px'}>
                    {'Något gick fel'}
            </Box>
        );
    }

    const tryRenderAccountsModal = () : JSX.Element|undefined => {
        if (!isEditAccountsOpen) {
            return undefined;
        }
        return (
            <FacebookAdAccountsModal
                baseData={baseData}
                userData={userData}
                onClose={() => {
                    setIsEditAccountsOpen(false);
                    setMyState(STATE_1_LOADING);
                    fetchAdAccounts();
                }}
            />
        );
    }

    const tryRenderConfirmDeleteModal = () : JSX.Element|undefined => {
        if (!isConfirmDeleteOpen) {
            return undefined;
        }

        return (
            <ConfirmModal
                title={'Ta bort delning'}
                text={'Är du säker på att du vill ta bort delningen. Målgruppen kommer att bli raderad från Meta.'}
                onYes={() => {
                    setIsConfirmDeleteOpen(false);
                    actionUnShare();
                }}
                onCancel={() => {
                    setIsConfirmDeleteOpen(false);
                }}
            />
        );
    }

    // ===========================================================
    // === Render Main
    // ===========================================================

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '528px',
        height: '828px'
    } as React.CSSProperties;

    const styleScroll = {
        overflowY: 'auto'
    } as React.CSSProperties;

    return (
        <Modal
            open={true}
            onClose={onClose}>

            <Box style={style}>
                <Paper>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        style={styleScroll}
                        width={'500px'}
                        padding={'14px 14px 14px 14px'}
                        gap={'16px'}>
                        {renderModalTitle()}
                        {tryRenderInputName()}
                        {renderInputIsAutoUpdate()}
                        {renderSelectAdAccounts()}
                        {renderButtons()}
                        {tryRenderError()}
                        {tryRenderAccountsModal()}
                        {tryRenderConfirmDeleteModal()}
                    </Box>
                </Paper>
            </Box>

        </Modal>
    );
}