import { MapAreaCircle } from "../../../data_layer/map/MapAreaCircle";
import { MapAreaPolygon } from "../../../data_layer/map/MapAreaPolygon";
import { MapPoint } from "../../../data_layer/map/MapPoint";
import { ApiObjPriceObject } from "../price/ApiObjPriceObject";

export class ApiObjExport {
    
    public static readonly STATE_1_INITIAL = 1;
    public static readonly STATE_2_WAITING_FOR_PAYMENT = 2;
    public static readonly STATE_3_PAYMENT_SUCCESS = 3;
    public static readonly STATE_4_PAYMENT_FAILED = 4;
    public static readonly STATE_5_BUILDING_FILE = 5;
    public static readonly STATE_6_BUILDING_FILE_FAILED = 6;
    public static readonly STATE_7_READY = 7;
    public static readonly STATE_8_DELETED = 8;

    public static readonly STATE_NAMES : Array<{state: number, name: string}> = [
        {state: this.STATE_1_INITIAL, name: 'Initial'},
        {state: this.STATE_2_WAITING_FOR_PAYMENT, name: 'Väntar på betalning'},
        {state: this.STATE_3_PAYMENT_SUCCESS, name: 'Betalning lyckades'},
        {state: this.STATE_4_PAYMENT_FAILED, name: 'Betalning misslyckades'},
        {state: this.STATE_5_BUILDING_FILE, name: 'Bygger fil'},
        {state: this.STATE_6_BUILDING_FILE_FAILED, name: 'Filbygge misslyckades'},
        {state: this.STATE_7_READY, name: 'Klar'},
        {state: this.STATE_8_DELETED, name: 'Borttagen'},
    ];

    id : number;
    name : string;
    params: {
        nix_option_id: number,
        source_id: number,
        filter_ids: Array<number>,
        map_areas: Array<MapAreaCircle|MapAreaPolygon>
        export_field_ids: Array<number>,
        stopfile_ids: Array<number>,
        household_option_id: number,
        size_limit: number,
    };
    payment_method: number;
    leads_count: number;
    price: {
        base: number,
        per_item: number,
        total_excl_vat: number,
        total_vat: number,
        total: number
    };
    price_spec_items: Array<ApiObjPriceObject>
    item_count: {
        total: number,
        paid: number,
        free: number
    }
    state: number;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {

        const mapAreas = [];
        let tempId = 1;
        for (const item of data.params.map_areas) {
            if (item.type === 'circle') {
                mapAreas.push(
                    MapAreaCircle.createNew(
                        tempId,
                        parseFloat(item.center_lat),
                        parseFloat(item.center_long),
                        parseFloat(item.radius)
                    )
                )
            } else if (item.type === 'polygon') {

                const tempPoints = [];
                for (const p of item.points) {
                    tempPoints.push(new MapPoint(
                        parseFloat(p.lat),
                        parseFloat(p.long)
                    ));
                }
                mapAreas.push(
                    MapAreaPolygon.createNew(
                        tempId,
                        tempPoints
                    )
                );

            } else {
                console.error("Unknown map area type");
            }
            tempId ++;
        }

        const filterIds = [];
        for (const item of data.params.filter_ids) {
            filterIds.push(parseInt(item));
        }

        const exportFieldIds = [];
        for (const item of data.params.export_field_ids) {
            exportFieldIds.push(parseInt(item));
        }

        const stopfileIds = [];
        for (const item of data.params.stopfile_ids) {
            stopfileIds.push(parseInt(item));
        }

        const priceSpecItems = [];
        for (const item of data.price_spec_items) {
            if (item.price !== undefined) {
                // Hack for handling legacy structure of spec item. Remove after 2024-02-01 or so.
                item['price_global'] = item.price;
            }
            priceSpecItems.push(new ApiObjPriceObject(item));
        }

        this.id = parseInt(data.id);
        this.name = data.name;
        this.params = {
            nix_option_id: parseInt(data.params.nix_option_id),
            source_id: parseInt(data.params.source_id),
            filter_ids: filterIds,
            map_areas: mapAreas,
            stopfile_ids: stopfileIds,
            export_field_ids: exportFieldIds,
            household_option_id: parseInt(data.params.household_option_id),
            size_limit: parseInt(data.params.size_limit),
        };
        this.payment_method = parseInt(data.payment_method);
        this.leads_count = parseInt(data.leads_count);
        this.price = {
            base: parseFloat(data.price.base),
            per_item: parseFloat(data.price.per_item),
            total_excl_vat: parseFloat(data.price.total_excl_vat),
            total_vat: parseFloat(data.price.total_vat),
            total: parseFloat(data.price.total)
        };
        this.price_spec_items = priceSpecItems;
        this.item_count = {
            total: parseInt(data.item_count.total),
            paid: parseInt(data.item_count.paid),
            free: parseInt(data.item_count.free)
        };
        this.state = parseInt(data.state);
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    getStateName () : string {
        for (const item of ApiObjExport.STATE_NAMES) {
            if (this.state === item.state) {
                return item.name;
            }
        }
        return '';
    }
}