import { FunctionComponent, useState, useEffect } from 'react';
import { OrderManager } from '../../../../data_layer/order/OrderManager';
import { OrderData } from '../../../../data_layer/order/OrderData';
import { UserData } from '../../../../data_layer/user/UserData';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { api_get_link_order_data } from '../../../../api/endpoint/link/api_get_link_order_data';
import { api_get_stopfiles } from '../../../../api/endpoint/stopfile/api_get_stopfiles';

type Props = {
    userData: UserData,
    orderData: OrderData,
    orderManager: OrderManager,
}

export const LinkLandingComp: FunctionComponent <Props> = ({
    userData,
    orderData,
    orderManager,
}) => {

    const STATE_INITIAL = 1;
    const STATE_FETCHING = 2;
    const STATE_ERROR = 3;
    const STATE_SUCCESS = 4;

    const [loadingState, setLoadingState] = useState <number> (STATE_INITIAL);
    const {link_code} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        actionFetchData();
    }, []);

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionFetchData = () => {

        if (link_code === undefined) {
            setLoadingState(STATE_ERROR);
            return;
        }

        if (userData.isLoggedIn()) {

            setLoadingState(STATE_FETCHING);
            const p1 = api_get_link_order_data(link_code);
            const p2 = api_get_stopfiles();
            Promise.all([p1, p2])
                .then((values) => {

                    const linkData = values[0];
                    const stopfiles = values[1];

                    orderManager.actionPopulateFromLinkData(linkData, stopfiles);
                    setLoadingState(STATE_SUCCESS);
                    navigate('/order_leads/2');

                }).catch((err) => {
                    console.error("Could not fetch link or stopfiles data.");
                    console.error(err);
                    setLoadingState(STATE_ERROR);
                });

        } else {
            api_get_link_order_data(link_code)
                .then((result) => {
                    orderManager.actionPopulateFromLinkData(result, []);
                    setLoadingState(STATE_SUCCESS);
                    navigate('/order_leads/2');

                }).catch((err) => {
                    console.error("Could not fetch link data.");
                    console.error(err);
                    setLoadingState(STATE_ERROR);
                });
        }
    }

    // ======================================================================
    // === Render
    // ======================================================================

    const getText = () => {
        switch (loadingState) {
            case STATE_INITIAL:
            case STATE_FETCHING:
                return 'Hämtar länkdata...';
            case STATE_SUCCESS:
                return 'Lyckades';
            case STATE_ERROR:
                return 'Länken verkar vara felaktig.';
        }
    }

    return (
        <Box padding={'20px'}>
            {getText()}
        </Box>
    );
};
