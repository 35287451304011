import makeT from '../../../translations/t';
import { useIntl } from 'react-intl';
import ExpansionPanelComponent from '../../../components/ExpansionPanelComponent';
import GuideComponent from './components/GuideComponent';

const QuickGuidePage = () => {
  const intl = useIntl();
  const T = makeT(intl);
  const firstBlock = [
    {
      title: T('register_guide_title', 'quick_guide'),
      content: T('register_guide_content', 'quick_guide'),
      img: T('register_guide_content_image', 'quick_guide')
    },
    {
      title: T('login_guide_title', 'quick_guide'),
      content: T('login_guide_content', 'quick_guide'),
      img: T('login_guide_content_image', 'quick_guide')
    }
  ];
  const secondBlock = [
    {
      title: T('nix_guide_title', 'quick_guide'),
      content: T('nix_guide_content', 'quick_guide'),
      img: T('nix_guide_content_image', 'quick_guide')
    },
    {
      title: T('target_guide_title', 'quick_guide'),
      content: T('target_guide_content', 'quick_guide'),
      img: T('target_guide_content_image', 'quick_guide')
    },
    {
      title: T('selection_guide_title', 'quick_guide'),
      content: T('selection_guide_content', 'quick_guide'),
      img: T('selection_guide_content_image', 'quick_guide')
    },
    {
      title: T('address_selection_guide_title', 'quick_guide'),
      content: T('address_selection_guide_content', 'quick_guide'),
      img: T('address_guide_content_image', 'quick_guide')
    }
  ];
  const thirdBlock = [
    {
      title: T('quote_guide_title', 'quick_guide'),
      content: T('quote_guide_content', 'quick_guide'),
      img: T('quote_guide_content_image', 'quick_guide')
    },
    {
      title: T('history_guide_title', 'quick_guide'),
      content: T('history_guide_content', 'quick_guide'),
      img: T('history_guide_content_image', 'quick_guide')
    },
    {
      title: T('delivery_guide_title', 'quick_guide'),
      content: T('delivery_guide_content', 'quick_guide'),
      img: T('delivery_guide_content_image', 'quick_guide')
    }
  ];
  const data = [
    {
      title: T('registration_login', 'quick_guide'),
      content: <GuideComponent blocks={firstBlock} />
    },
    {
      title: T('selection_target', 'quick_guide'),
      content: <GuideComponent blocks={secondBlock} />
    },
    {
      title: T('buy_history', 'quick_guide'),
      content: <GuideComponent blocks={thirdBlock} />
    }
  ];
  return (
      <section>
        <ExpansionPanelComponent data={data} />
      </section>
  );
};

export default QuickGuidePage;
