import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjUserMy } from '../../object/user/ApiObjUserMy';

export const api_login = async (email: string, password: string) : Promise<ApiObjUserMy> => {

    const url = API_URL+'/user/login';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const data = {
        email: email,
        password:  password,
    }
    const resp = await Axios.post(url, data, config);
        
    return new ApiObjUserMy(resp.data);
}