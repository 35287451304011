import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

export const api_create_stopfile = async (file: File) : Promise<ApiObjStopfile> => {
    
    const url = API_URL+'/stopfile/create';

    const fd = new FormData();
    fd.append('file', new Blob([file], { type: 'text/csv' }), file.name);
        
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true,
        headers: {
            accept: 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'multipart/form-data;'
        }
    }
    const resp = await Axios.post(
        url,
        fd,
        config
    );
    
    return new ApiObjStopfile(resp.data);
}