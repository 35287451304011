const initialState = {
  // id: 1,
  // name: '',
  // parent: '',
  // selectable: false,
  // needRequest: false,
  // code: 'root',
  // children: [
  //     {
  //         children: [
  //             {
  //                 code: "age",
  //                 id: 5,
  //                 name: "Age",
  //                 needRequest: true,
  //                 parent: 2,
  //                 selectable: false,
  //             }
  //         ],
  //         code: "ageCategory",
  //         id: 2,
  //         name: "Age/Month",
  //         needRequest: false,
  //         parent: 1,
  //         selectable: false,
  //     }
  // ]
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_SELECTED_DATA':
      return { ...payload };

    default:
      return state;
  }
};
