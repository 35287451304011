import { MapAreaCircle } from '../map/MapAreaCircle';
import { MapAreaPolygon } from '../map/MapAreaPolygon';
import { ApiObjTargetGroup } from '../../api/object/target_group/ApiObjTargetGroup';
import { CountOrderData } from '../count/order/CountOrderData';
import { CountFilterDataItem } from '../count/filter/CountFilterDataItem';
import { ApiObjBaseData } from '../../api/object/base_data/ApiObjBaseData';
import { ApiObjStopfile } from '../../api/object/stopfile/ApiObjStopfile';
import { clean_source } from '../clean_objects/clean_source';
import { clean_filters } from '../clean_objects/clean_filters';
import { clean_export_fields } from '../clean_objects/clean_export_fields';
import { clean_stopfiles } from '../clean_objects/clean_stopfiles';
import { UtilArray } from '../../util/code/UtilArray';
import { MapArea } from '../map/MapArea';
import { ApiObjNixOption } from '../../api/object/nix_option/ApiObjNixOption';
import { UserData } from '../user/UserData';

/**
 * Holds all data to define a target group
 * plus all data used in edit.
 * 
 * Created in TargetGroupEditManager and passed down
 * to UI components as immutable.
 */
export class TargetGroupEditData {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public step: number;
    public name: string;
    public description: string;
    public sort_order: number;
    public nix_option_id : number;
    public source_id: number;
    public filter_ids_selected : Array<number>;
    public filter_ids_visible : Array<number>;
    public map_areas: Array<MapAreaCircle|MapAreaPolygon>
    public export_field_ids : Array<number>;
    public stopfile_ids : Array<number>;
    public stopfile_days: number;
    public household_option_id: number;
    
    public filter_count : Array<CountFilterDataItem>;
    public order_count: undefined | CountOrderData;

    public state_is_saving: boolean;
    public has_unsaved_changes: boolean;
    
    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = () : TargetGroupEditData => {
        return new TargetGroupEditData(undefined);
    }

    public static createFromApiObj = (
        apiObj: ApiObjTargetGroup,
        baseData: ApiObjBaseData,
        allStopfiles: Array<ApiObjStopfile>,
        userData: UserData) => {

        const obj = new TargetGroupEditData(undefined);

        obj.name = apiObj.name;
        obj.description = apiObj.description;
        obj.sort_order = apiObj.sort_order;
        obj.source_id = clean_source(apiObj.source_id, userData);
        obj.nix_option_id = apiObj.nix_option_id;
        obj.filter_ids_selected = clean_filters(apiObj.filter_ids, baseData, userData);
        obj.filter_ids_visible = [];
        obj.map_areas = [];
            for (const item of apiObj.map_areas) {
                if (item instanceof MapAreaCircle) {
                    obj.map_areas.push(MapAreaCircle.createClone(item));
                } else if (item instanceof MapAreaPolygon) {
                    obj.map_areas.push(MapAreaPolygon.createClone(item));
                } else {
                    throw new Error("Unexpected type");
                }
            }
        obj.export_field_ids = clean_export_fields(apiObj.export_field_ids, baseData, userData);
        obj.stopfile_ids = clean_stopfiles(apiObj.stopfile_ids, allStopfiles, true, false, false);
        obj.stopfile_days = apiObj.stopfile_days;
        obj.household_option_id = apiObj.household_option_id;
        obj.filter_count = [];
        obj.order_count = undefined;
        obj.state_is_saving = false;
        obj.has_unsaved_changes = false;

        return obj;
    }

    public static createClone = (original: TargetGroupEditData) : TargetGroupEditData => {
        return new TargetGroupEditData(original);
    }

    private constructor(old : TargetGroupEditData|undefined = undefined) {

        if (old !== undefined) {
            this.step = old.step;
            this.name = old.name;
            this.description = old.description;
            this.sort_order = old.sort_order;
            this.nix_option_id = old.nix_option_id;
            this.source_id = old.source_id;
            this.filter_ids_selected = [...old.filter_ids_selected];
            this.filter_ids_visible = [...old.filter_ids_visible];
            this.export_field_ids = [...old.export_field_ids];
            this.stopfile_ids = [...old.stopfile_ids];
            this.stopfile_days = old.stopfile_days;
            this.household_option_id = old.household_option_id;

            const mapAreas = [];
            for (const item of old.map_areas) {
                if (item instanceof MapAreaCircle) {
                    mapAreas.push(MapAreaCircle.createClone(item));
                } else if (item instanceof MapAreaPolygon) {
                    mapAreas.push(MapAreaPolygon.createClone(item));
                } else {
                    throw new Error("Unexpected type");
                }
            }
            this.map_areas = mapAreas;

            const filterCount = [];
            for (const item of old.filter_count) {
                filterCount.push(CountFilterDataItem.createClone(item));
            }
            this.filter_count = filterCount;
            
            if (old.order_count !== undefined) {
                this.order_count = CountOrderData.createClone(old.order_count);
            } else {
                this.order_count = undefined;
            }

            this.state_is_saving = old.state_is_saving;
            this.has_unsaved_changes = old.has_unsaved_changes;

        } else {
            this.step = 1;
            this.name = '';
            this.description = '';
            this.sort_order = 1;
            this.nix_option_id = ApiObjNixOption.ID._3_NIX_FREE_PHONE_MOBILE;
            this.source_id = 0;
            this.filter_ids_selected = [];
            this.filter_ids_visible = [];
            this.map_areas = [];
            this.export_field_ids = [1,2,3,4,5,6,7,8];   // Hard coded default. Will break if export fields are removed from DB. Should "never" happen.
            this.stopfile_ids = [];
            this.stopfile_days = 0;
            this.household_option_id = 0;
            this.filter_count = [];
            this.order_count = undefined;
            this.state_is_saving = false;
            this.has_unsaved_changes = false;
        }
    }

    // ==========================================================================
    // === Helpers
    // ==========================================================================

    public getFilterCountItem(filterId: number) : CountFilterDataItem|undefined {
        for (const item of this.filter_count) {
            if (item.filter_id == filterId) {
                return item;
            }
        }
        return undefined;
    }

    public isDataEqual(apiObj: ApiObjTargetGroup) : boolean {
        return this.name === apiObj.name
            && this.description === apiObj.description
            && this.sort_order === apiObj.sort_order
            && this.nix_option_id === apiObj.nix_option_id
            && this.source_id === apiObj.source_id
            && UtilArray.arrayEquals(this.filter_ids_selected, apiObj.filter_ids)
            && MapArea.isArraysEqual(this.map_areas, apiObj.map_areas)
            && UtilArray.arrayEquals(this.export_field_ids, apiObj.export_field_ids)
            && UtilArray.arrayEquals(this.stopfile_ids, apiObj.stopfile_ids)
            && this.stopfile_days === apiObj.stopfile_days
            && this.household_option_id === apiObj.household_option_id
    }
}