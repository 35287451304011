import { api_get_export_price } from '../../api/endpoint/export/api_get_export_price';
import { ApiObjExportPrice } from '../../api/object/export/ApiObjExportPrice';
import { OrderPriceData } from './OrderPriceData';
import { OrderPriceParams, orderPriceParamsClone } from './OrderPriceParams';

/**
 * Manages calls to order price.
 */
export class OrderPriceManager {
    
    private callbackNewData: (newData: OrderPriceData) => void;
    private lastParams: undefined|OrderPriceParams;
    private orderPriceData: undefined|OrderPriceData = undefined;

    // ================================================================
    // === Create
    // ================================================================

    constructor(callbackNewData: (newData: OrderPriceData) => void) {
        this.callbackNewData = callbackNewData;
    }

    // ================================================================
    // === Actions
    // ================================================================

    public actionNewParams = (params: OrderPriceParams) => {
        this.lastParams = orderPriceParamsClone(params);
        this.tryUpdateOrderPriceData();
        this.tryMakeApiCall();
    }

    private makeCallback = () => {
        if (this.orderPriceData !== undefined) {
            const newData = OrderPriceData.createClone(this.orderPriceData);
            this.callbackNewData(newData);
        }
    }

    // ================================================================
    // === Helpers
    // ================================================================

    private tryUpdateOrderPriceData = () : void => {

        if (this.lastParams === undefined) {
            return;
        }

        if (this.orderPriceData === undefined || !this.orderPriceData.isParamsEqual(this.lastParams)) {
            this.orderPriceData = OrderPriceData.createNew(this.lastParams);
            this.makeCallback();
        }
    }

    private tryMakeApiCall = () : void => {

        if (this.orderPriceData === undefined) {
            console.error("orderPriceData should not be undefined here.");
            return;
        }
        if (this.orderPriceData.state === OrderPriceData.STATE_WAITING_FOR_REQUEST_START) {

            api_get_export_price(this.orderPriceData.params)
                .then((result) => {
                    this.handleResponse(result);
                }).catch((err) => {
                    console.error("Could not fetch export price");
                    console.error(err);
                });
            this.orderPriceData.actionRequestSent();
            this.makeCallback();
        }
    }

    private handleResponse = (result: ApiObjExportPrice) => {
        if (
            this.orderPriceData !== undefined
            && this.orderPriceData.isParamsEqual(result.request_params)
        ) {
            this.orderPriceData.actionReady(result);
            this.makeCallback();
        }
    }
}