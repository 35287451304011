import { MapPoint } from "./MapPoint";

/**
 * A circle shape on the map.
 */
export class MapAreaPolygon {

    // ==========================================================================
    // === Variables
    // ==========================================================================

    public id: number;
    public points: Array<MapPoint>;

    // ==========================================================================
    // === Create
    // ==========================================================================

    public static createNew = (id: number, points: Array<MapPoint>) : MapAreaPolygon => {
        return new MapAreaPolygon(id, points);
    }

    public static createClone = (original: MapAreaPolygon) : MapAreaPolygon => {
        return new MapAreaPolygon(original.id, original.points);
    }

    private constructor(id: number, points: Array<MapPoint>) {
        this.id = id;
        const tempArray = [];
        for (const item of points) {
            tempArray.push(new MapPoint(item.latitude, item.longitude));
        }
        this.points = tempArray;
    }

    public isEqual(other: MapAreaPolygon) : boolean {
        if (this.points.length !== other.points.length) {
            return false;
        }
        for (let k = 0; k < this.points.length; k ++) {
            if (
                this.points[k].latitude !== other.points[k].latitude
                || this.points[k].longitude !== other.points[k].longitude
            ) {
                return false;
            }
        }
        return true;
    }
}