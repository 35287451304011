import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjTargetGroup } from '../../object/target_group/ApiObjTargetGroup';

export const api_get_target_groups = async () : Promise<Array<ApiObjTargetGroup>> => {
    
    const url = API_URL+'/target_group';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjTargetGroup(item));
    }
    return ret;
        
}