import { ApiObjExportField } from "../export/ApiObjExportField";
import { ApiObjHouseholdOption } from "../household/ApiObjHouseholdOption";
import { ApiObjNixOption } from "../nix_option/ApiObjNixOption";
import { ApiObjPriceObject } from "../price/ApiObjPriceObject";
import { ApiObjSource } from "../source/ApiObjSource";

export class ApiObjUserMy {

    id: number;
    email: string;
    is_admin: boolean;
    is_active: boolean;
    is_facebook_enabled: boolean;
    first_name: string;
    last_name: string;
    phone_number: string;
    company_name: string;
    company_org_number: string;
    address: string;
    zipcode: string;
    city: string;
    created_at: Date;
    updated_at: Date;
    main_subscription: {
        type: number,
        price: number,
        leads_used: number
        leads_max: number,
    };
    populate_file_subscription: {
        type: number,
        price: number,
        spending: number,
        spending_max: number
    };
    enabled_filters_for_export: Array<number>;
    enabled_filters_for_marketing: Array<number>;
    enabled_sources: Array<ApiObjSource>;                   // TODO ERIK byt namn.
    enabled_export_fields: Array<ApiObjExportField>;        // TODO ERIK byt namn.
    price_objects: Array<ApiObjPriceObject>;
    household_options: Array<ApiObjHouseholdOption>;
    nix_options: Array<ApiObjNixOption>;

    constructor(data: any) {

        this.id = parseInt(data.id);
        this.email = data.email;
        this.is_admin = parseInt(data.is_admin) === 1;
        this.is_active = parseInt(data.is_active) === 1;
        this.is_facebook_enabled = parseInt(data.is_facebook_enabled) === 1;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.phone_number = data.phone_number;
        this.company_name = data.company_name;
        this.company_org_number = data.company_org_number;
        this.address = data.address;
        this.zipcode = data.zipcode;
        this.city = data.city;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);

        this.main_subscription = {
            price: parseFloat(data.main_subscription.price),
            type: parseInt(data.main_subscription.type),
            leads_used: parseInt(data.main_subscription.leads_used),
            leads_max: parseInt(data.main_subscription.leads_max),
        }

        this.populate_file_subscription = {
            type: parseInt(data.populate_file_subscription.type),
            price: parseInt(data.populate_file_subscription.price),
            spending: parseFloat(data.populate_file_subscription.spending),
            spending_max: parseInt(data.populate_file_subscription.spending_max)
        }

        this.enabled_filters_for_export = [];
        for (const item of data.enabled_filters_for_export) {
            this.enabled_filters_for_export.push(parseInt(item));
        }

        this.enabled_filters_for_marketing = [];
        for (const item of data.enabled_filters_for_marketing) {
            this.enabled_filters_for_marketing.push(parseInt(item));
        }

        this.enabled_sources = [];
        for (const item of data.enabled_sources) {
            this.enabled_sources.push(new ApiObjSource(item));
        }

        this.enabled_export_fields = [];
        for (const item of data.enabled_export_fields) {
            this.enabled_export_fields.push(new ApiObjExportField(item));
        }

        this.price_objects = [];
        for (const item of data.price_objects) {
            this.price_objects.push(new ApiObjPriceObject(item));
        }

        this.household_options = [];
        for (const item of data.household_options) {
            this.household_options.push(new ApiObjHouseholdOption(item));
        }

        this.nix_options = [];
        for (const item of data.nix_options) {
            this.nix_options.push(new ApiObjNixOption(item));
        }
    }

    public hasSubscription = () => {
        return this.main_subscription.type != 0;
    }

    public getSubscriptionLeadsLeft = () => {
        if (!this.hasSubscription()) {
            return 0;
        }
        let leadsLeft = this.main_subscription.leads_max - this.main_subscription.leads_used;
        if (leadsLeft < 0) {
            leadsLeft = 0;
        }
        return leadsLeft;
    }

    public hasPopulateSubscription = () => {
        return this.populate_file_subscription.type != 0;
    }

    public getPopulatePotLeft = () => {
        if (!this.hasPopulateSubscription()) {
            return 0;
        }
        let potLeft = this.populate_file_subscription.spending_max - this.populate_file_subscription.spending;
        if (potLeft < 0) {
            potLeft = 0;
        }
        return potLeft;
    }

    public getSource = (sourceId: number) : undefined | ApiObjSource => {
        for (const item of this.enabled_sources) {
            if (item.id === sourceId) {
                return item;
            }
        }
        return undefined;
    }

    public getExportField = (exportFieldId: number) : ApiObjExportField|undefined => {
        for (const item of this.enabled_export_fields) {
            if (item.id === exportFieldId) {
                return item;
            }
        }
        return undefined;
    }

    public getPriceObjectFromId = (priceObjectId: number) : ApiObjPriceObject|undefined => {
        for (const item of this.price_objects) {
            if (item.id === priceObjectId) {
                return item;
            }
        }
        return undefined;
    }
    
    public getPriceObjectFromType = (priceObjectTypeId: number, objectId: number) : ApiObjPriceObject|undefined => {
        for (const item of this.price_objects) {
            if (item.object_type_id === priceObjectTypeId && item.object_id === objectId) {
                return item;
            }
        }
        return undefined;
    }

    public getNixOption = (nixOptionId: number) : ApiObjNixOption|undefined => {
        for (const item of this.nix_options) {
            if (item.id === nixOptionId) {
                return item;
            }
        }
        return undefined;
    }
}