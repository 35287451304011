import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjTargetGroup } from '../../object/target_group/ApiObjTargetGroup';

export const api_get_target_group = async (id: number) : Promise<ApiObjTargetGroup> => {

    const url = API_URL+'/target_group/'+id;
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(
        url,
        config
    )
        
    return new ApiObjTargetGroup(resp.data);
}