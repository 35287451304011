import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjUserMy } from '../../object/user/ApiObjUserMy';

export interface GetMyUserResponse {
    logged_in: boolean,
    user_data: undefined|ApiObjUserMy
}

export const api_get_my_user = async () : Promise<GetMyUserResponse> => {

    const url = API_URL+'/my_user';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const resp = await Axios.get(url, config);
    
    if (resp.data.logged_in == 0) {
        return {
            logged_in: false,
            user_data: undefined
        };
    } else {
        return {
            logged_in: true,
            user_data: new ApiObjUserMy(resp.data.user_data)
        };
    }
}