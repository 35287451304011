import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import CardSelectionComponent from '../../../../components/CardSelectionComponent';
import ButtonComponent from '../../../../components/ButtonComponent';
import { Box, Typography } from '@mui/material';

const CheckoutForm = (props) => {
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = (ev) => {
    setIsComplete(false);
    ev.preventDefault();
    stripe
      .confirmCardPayment(props.paymentIntentClientSecret, {
        payment_method: {
          card: elements.getElement('card'),
          billing_details: {
            name: 'Jenny Rosen'
          }
        }
      })
      .then((success) => props.handleSubmit(success.paymentIntent));
  };
  const handleChange = (e) => {
    setIsComplete(e.complete);
    if (!e.complete && e.error) {
      setErrorMessage(e.error.message);
    } else {
      setErrorMessage('');
    }
  };
  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit}>
        <CardSelectionComponent handleChange={handleChange} />
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
        <Box mt={2}>
          <ButtonComponent title={'Bekräfta Order'} fullWidth onClick={handleSubmit} disabled={!isComplete} />
        </Box>
      </form>
    </Box>
  );
};

export default CheckoutForm;
