import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserFilter } from '../../../../object/admin/ApiObjAdminUserFilter';

export const api_admin_user_get_filters = async (userId: number) : Promise<Array<ApiObjAdminUserFilter>> => {

    const url = API_URL+'/admin/user/'+userId+'/filter';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserFilter(item));
    }
    return ret;
}
