import { Box, Paper, Typography } from '@mui/material';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import { UserData } from '../../../data_layer/user/UserData';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { OrderStep1Preview } from './OrderStep1Preview';
import { OrderStep2Content } from './OrderStep2Content';
import { OrderStep3Stopfiles } from './OrderStep3Stopfiles';
import { OrderStep4Payment } from './OrderStep4Payment';
import { useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { api_populate_file_get_single } from '../../../api/endpoint/populate_file/api_populate_file_get_single';
import { ApiObjPopulateFile } from '../../../api/object/populate_file/ApiObjPopulateFile';

type Props = {
    baseData: ApiObjBaseData,
    userData: UserData,
}

export const PopulateFileOrderPage: FunctionComponent <Props> = ({
    baseData,
    userData,
}) => {

    const location = useLocation();
    const { populate_file_id } = useParams();
    let isDestroyed = false;
    const [estimatePrice, setEstimatePrice] = useState(0);
    const [populateFile, setPopulateFile] = useState <ApiObjPopulateFile|undefined> (undefined);

    useEffect(() => {
        return function cleanup() {
            // Run when component is unmounted.
            // Use this to stop timer from running forever.
            isDestroyed = true;
        }
    }, []);

    useEffect(() => {
        setPopulateFile(undefined);
        fetchPopulateFile();
    }, [populate_file_id]);

    // ======================================================================
    // === Actions
    // ======================================================================

    const childSetPopulateFile = (newPopulateFile: ApiObjPopulateFile) => {
        setPopulateFile(newPopulateFile);
        tryAddTimedRefetch(newPopulateFile);
    }

    const fetchPopulateFile = () => {

        if (isDestroyed || populate_file_id === undefined) {
            return;
        }

        const populateFileId = parseInt(populate_file_id);
        if (isNaN(populateFileId)) {
            return;
        }

        api_populate_file_get_single(populateFileId)
            .then((result) => {
                if (populateFileId !== undefined && populateFileId === result.id && !isDestroyed) {
                    setPopulateFile(result);
                    tryAddTimedRefetch(result);
                }
            });
    }

    // ======================================================================
    // === Reload
    // ======================================================================

    const tryAddTimedRefetch = (newPopulateFile: ApiObjPopulateFile) => {
        if (populate_file_id === undefined || isDestroyed) {
            return;
        }
        if (parseInt(populate_file_id) !== newPopulateFile.id) {
            return;
        }
        const loadingStates = [
            ApiObjPopulateFile.STATE_4_PARSE_STARTED,
            ApiObjPopulateFile.STATE_7_BUILD_STARTED,
            ApiObjPopulateFile.STATE_10_PAYMENT_STARTED,
            ApiObjPopulateFile.STATE_12_PAYMENT_COMPLETE
        ];
        if (loadingStates.includes(newPopulateFile.state)) {
            setTimeout(() => {fetchPopulateFile()}, 2000);
        }
    }

    // ======================================================================
    // === Render no user
    // ======================================================================

    if (!userData.isLoggedIn()) {
        return (
            <Paper style={{padding: '20px'}}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="h4">{'Nummersättning'}</Typography>
                    <Typography variant="subtitle1">{'Du måste vara inloggad för att använda denna funktion.'}</Typography>
                </Box>
            </Paper>
        );
    }

    // ======================================================================
    // === Render invalid id
    // ======================================================================

    if (populate_file_id === undefined || populateFile === undefined) {
        return (
            <Paper style={{padding: '20px'}}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="h4">{'Nummersättning'}</Typography>
                    Laddar...
                </Box>
            </Paper>
        );
    }

    // ======================================================================
    // === Render
    // ======================================================================

    return (
        <Paper style={{padding: '20px'}}>

            <Box display={'flex'} alignItems={"start"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant="h4">{'Nummersättning'}</Typography>
                    {
                        location.pathname.includes("step_1") && (
                            <p>Välj rubrik för er data om det inte har autovalts</p>
                        )
                    }
                    {
                        location.pathname.includes("step_2") && (
                            <p>Välj data att lägga till och de tvättar ni vill göra på er lista</p>
                        )
                    }
                    {
                        location.pathname.includes("step_3") && (
                            <p>Välj de stoppfiler ni vill tvätta mot och om raden ska markeras eller tas bort</p>
                        )
                    }
                    {
                        location.pathname.includes("step_4") && (
                           <Box marginTop={"1rem"}>
                                <Typography variant="h5" marginBottom={"0"}>Översikt</Typography>
                                <p>Volym rabatt? Kontakta oss för att få en offert</p>
                           </Box>
                        )
                    }
                    {!!populateFile.count.matches && <p>{populateFile.count.matches} matchningar</p>}
                    {!!estimatePrice && !!populateFile.count.matches && !location.pathname.includes("step_4") && <p>{estimatePrice.toFixed(2)} kr (estimerat pris)</p>}
                </Box>
                {
                    location.pathname.includes("step_1") && (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Typography variant="h6">Steg 1/4</Typography>
                        </div>
                    )
                }
                {
                    location.pathname.includes("step_2") && (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Link to={"/populate_file/"+populate_file_id+"/order_step_1"}><ArrowBack color="primary" style={{width: "75%", margin: "0.2rem 0.5rem 0 0"}}/></Link>
                            <Typography variant="h6">Steg 2/4</Typography>
                        </div>
                    )
                }
                {
                    location.pathname.includes("step_3") && (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Link to={"/populate_file/"+populate_file_id+"/order_step_2"}><ArrowBack color="primary" style={{width: "75%", margin: "0.2rem 0.5rem 0 0"}}/></Link>
                            <Typography variant="h6">Steg 3/4</Typography>
                        </div>
                    )
                }
                {
                    location.pathname.includes("step_4") && (
                        <div style={{display: "flex", alignItems: "center"}}>
                            {
                                populateFile?.state !== ApiObjPopulateFile.STATE_10_PAYMENT_STARTED && (
                                    <Link to={"/populate_file/"+populate_file_id+"/order_step_3"}><ArrowBack color="primary" style={{width: "75%", margin: "0.2rem 0.5rem 0 0"}}/></Link>
                                )
                            }
                            <Typography variant="h6">Steg 4/4</Typography>
                        </div>
                    )
                }
            </Box>

            <Routes>
                <Route 
                    path='order_step_1'
                    element={
                        <OrderStep1Preview
                            baseData={baseData}
                            userData={userData}
                            populateFile={populateFile}
                            parentSetPopulateFile={childSetPopulateFile}
                        />
                    }
                />
                <Route 
                    path='order_step_2'
                    element={
                        <OrderStep2Content
                            baseData={baseData}
                            userData={userData}
                            populateFile={populateFile}
                            setEstimatePrice={setEstimatePrice}
                            parentSetPopulateFile={childSetPopulateFile}
                        />
                    }
                />
                <Route 
                    path='order_step_3'
                    element={
                        <OrderStep3Stopfiles
                            baseData={baseData}
                            userData={userData}
                            populateFile={populateFile}
                            parentSetPopulateFile={childSetPopulateFile}
                        />
                    }
                />
                <Route 
                    path='order_step_4'
                    element={
                        <OrderStep4Payment
                            baseData={baseData}
                            userData={userData}
                            populateFile={populateFile}
                            parentSetPopulateFile={childSetPopulateFile}
                        />
                    }
                />
            </Routes>
        </Paper>
        
    );
}
