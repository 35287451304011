export class ApiObjHouseholdOption {

    // ========================================================================
    // === Hardcoded ids
    // ========================================================================

    static ID_PERSON_RANDOM = 1;
    static ID_MALE_RANDOM = 2;
    static ID_FEMALE_RANDOM = 3;

    static ID_PERSON_AGE_MAX = 4;
    static ID_MALE_AGE_MAX = 5;
    static ID_FEMALE_AGE_MAX = 6;
    
    static ID_PERSON_AGE_MIN = 7;
    static ID_MALE_AGE_MIN = 8;
    static ID_FEMALE_AGE_MIN = 9;
    
    static ID_PERSON_INCOME_MAX = 10;
    static ID_MALE_INCOME_MAX = 11;
    static ID_FEMALE_INCOME_MAX = 12;
    
    static ID_PERSON_INCOME_MIN = 13;
    static ID_MALE_INCOME_MIN = 14;
    static ID_FEMALE_INCOME_MIN = 15;
    
    static ID_PERSON_CAPITAL_INCOME_MAX = 16;
    static ID_MALE_CAPITAL_INCOME_MAX = 17;
    static ID_FEMALE_CAPITAL_INCOME_MAX = 18;
    
    static ID_PERSON_CAPITAL_INCOME_MIN = 19;
    static ID_MALE_CAPITAL_INCOME_MIN = 20;
    static ID_FEMALE_CAPITAL_INCOME_MIN = 21;
    
    static ID_PERSON_CAR_COUNT_MAX = 22;
    static ID_MALE_CAR_COUNT_MAX = 23;
    static ID_FEMALE_CAR_COUNT_MAX = 24;

    // ========================================================================
    // === Fields
    // ========================================================================

    public id: number;
    public name: string;
    public is_premium: boolean;
    public sort_order: number;

    // ========================================================================
    // === Create
    // ========================================================================

    constructor(data: any) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.is_premium = parseInt(data.is_premium) === 1;
        this.sort_order = parseInt(data.sort_order);
    }
}