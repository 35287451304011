export class ApiObjAdminUserFilter {
    
    id: number;
    user_id: number;
    filter_id: number;
    is_enabled_for_export: boolean;
    is_enabled_for_marketing: boolean;
    
    constructor(data: any) {
        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);
        this.filter_id = parseInt(data.filter_id);
        this.is_enabled_for_export = parseInt(data.is_enabled_for_export) === 1;
        this.is_enabled_for_marketing = parseInt(data.is_enabled_for_marketing) === 1;
    }
}