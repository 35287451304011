import { ApiObjBaseData } from "../../api/object/base_data/ApiObjBaseData";
import { UtilArray } from "../../util/code/UtilArray";
import { UserData } from "../user/UserData";

export const clean_export_fields = (ids: Array<number>, baseData : ApiObjBaseData, userData: UserData) : Array<number> => {

    const cleanIds = [];

    for (const id of ids) {
        const isValid = (
            baseData.getExportField(id) !== undefined
            || userData.getExportField(id) !== undefined
        )
        if (isValid) {
            cleanIds.push(id);
        }
    }
    return UtilArray.createUniqueSortedIntArray(cleanIds);
}