import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';

export const api_admin_user_transfer_export = async (currentUserId: number, exportId: number, newUserId: number) : Promise<void> => {
    
    const url = API_URL+'/admin/user/'+currentUserId+'/export/'+exportId+'/transfer';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    const payload = {
        new_user_id: newUserId
    };

    Axios.post(
        url,
        payload,
        config
    );
}
