import Axios from 'axios';
import { API_URL } from '../../../../../constants/Constants';
import { ApiObjAdminUserFilter } from '../../../../object/admin/ApiObjAdminUserFilter';

export const api_admin_user_save_filters = async (
    userId: number,
    userFilters: Array<{filter_id: number, is_enabled_for_export: boolean, is_enabled_for_marketing: boolean}>
) : Promise<Array<ApiObjAdminUserFilter>> => {

    const url = API_URL+'/admin/user/'+userId+'/filter';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    };
    const data = [];
    for (const item of userFilters) {
        data.push({
            filter_id: item.filter_id,
            is_enabled_for_export: item.is_enabled_for_export ? 1 : 0,
            is_enabled_for_marketing: item.is_enabled_for_marketing ? 1 : 0
        });
    }

    const resp = await Axios.put(
        url,
        data,
        config
    );
    
    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjAdminUserFilter(item));
    }
    return ret;
}