import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { api_process_stopfile } from '../../../api/endpoint/stopfile/api_process_stopfile';
import { api_get_stopfile_preview } from '../../../api/endpoint/stopfile/api_get_stopfile_preview';
import { api_get_stopfile } from '../../../api/endpoint/stopfile/api_get_stopfile';
import { ApiObjStopfile } from '../../../api/object/stopfile/ApiObjStopfile';
import { ApiObjStopfilePreview } from '../../../api/object/stopfile/ApiObjStopfilePreview';
import { api_create_stopfile } from '../../../api/endpoint/stopfile/api_create_stopfile';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { EstimatedProgressBar } from '../../shared_components/EstimatedProgressBar';

const STATE_1_SELECT_FILE = 1;
const STATE_2_UPLOADING = 2;
const STATE_3_INPUT = 3;
const STATE_4_PROCESSING = 4;
const STATE_5_SUCCESS = 5;
const STATE_6_ERROR = 6;

export const CompCreateStopfile: FunctionComponent = () => {

    // ======================================================================
    // === Setup
    // ======================================================================

    const [state, setState] = useState <number> (STATE_1_SELECT_FILE);
    const [stopfileObj, setStopfileObj] = useState <undefined|ApiObjStopfile> (undefined);
    const [stopfilePreviewObj, setStopfilePreviewObj] = useState <undefined|ApiObjStopfilePreview> (undefined);
    const [columnTypes, setColumnTypes] = useState <Array<number>> ([]);

    const [progressStartMillis, setProgressStartMillis] = useState <number> (0);
    const [estimatedTimeMillis, setEstimatedTimeMillis] = useState <number> (0);

    // ======================================================================
    // === Fetch
    // ======================================================================

    const fetchPreview = (stopfileId: number) => {
        api_get_stopfile_preview(stopfileId)
            .then((result) => {

                setStopfilePreviewObj(result);
                setColumnTypes(result.column_types);
                setState(STATE_3_INPUT);
                
            }).catch((err) => {
                setState(STATE_6_ERROR);
            })
    }

    const refetchStopfileUntilDone = async () => {

        if (stopfileObj === undefined) {
            return;
        }
        
        api_get_stopfile(stopfileObj.id)
            .then((stopfile) => {

                setStopfileObj(stopfile);

                switch (stopfile.state) {
                    case ApiObjStopfile.STATE_2_PROCESSING:
                        setTimeout(refetchStopfileUntilDone, 1500);
                        break;
                    case ApiObjStopfile.STATE_3_SUCCESS:
                        setState(STATE_5_SUCCESS);
                        break;
                    case ApiObjStopfile.STATE_4_FAILED:
                        setState(STATE_6_ERROR);
                        break;
                }

            }).catch((error) => {
                setState(STATE_6_ERROR);
            });
    }

    // ======================================================================
    // === Actions
    // ======================================================================

    const actionUploadFile = (file: File) => {

        if (state !== STATE_1_SELECT_FILE) {
            return;
        }

        setState(STATE_2_UPLOADING);
        
        api_create_stopfile(file)
            .then((result) => {
                setStopfileObj(result);
                fetchPreview(result.id);
            })
            .catch((error) => {
                setState(STATE_6_ERROR);
            });
    }

    const actionSetSelectedOption = (columnIndex: number, value: number) : void => {

        if (state !== STATE_3_INPUT) {
            return;
        }

        const tempArray = [...columnTypes];
        tempArray[columnIndex] = value;
        setColumnTypes(tempArray);
    }

    const actionConfirmPreview = () : void => {

        if (state !== STATE_3_INPUT || stopfileObj === undefined) {
            return;
        }

        setState(STATE_4_PROCESSING);
        setProgressStartMillis(Date.now());
        setEstimatedTimeMillis(stopfileObj.row_count * 0.00082725 * 1000);

        const tempColumnTypes = [...columnTypes];
        api_process_stopfile(stopfileObj.id, tempColumnTypes)
            .then((resp) => {
                setStopfileObj(resp);
                setTimeout(refetchStopfileUntilDone, 1500);

            }).catch((e) => {
                console.error(e);
                setState(STATE_6_ERROR);
            });
    }

    const actionBackToStart = () : void => {
        setState(STATE_1_SELECT_FILE);
        setStopfileObj(undefined);
        setStopfilePreviewObj(undefined);
        setColumnTypes([]);
    }
    
    // ======================================================================
    // === Render
    // ======================================================================

    const tryRenderUploadForm = () : undefined|JSX.Element => {

        if (state !== STATE_1_SELECT_FILE) {
            return undefined;
        }
        
        return (
            <Box mt={2}>
                <Box mt={2} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="body1">{'Välj en fil. Format som stöds: xlsx, csv.'}</Typography>
                    <p>OBS! Dina filer krypteras vid uppladdning och Marknadsurval tar inte del av er data.</p>
                </Box>
                <Box mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<UploadFileIcon />}
                    >
                        {'Ladda upp fil'}
                        <input
                            type="file"
                            accept=".xlsx, .csv"
                            hidden
                            onChange={(e) => {
                                if (e.target.files != null && e.target.files[0] != null) {
                                    actionUploadFile(e.target.files[0]);
                                }
                            }} />
                    </Button>
                </Box>
            </Box>
        );
    }

    const tryRenderUploading = () : undefined|JSX.Element => {
        if (state !== STATE_2_UPLOADING) {
            return undefined;
        }
        return (
            <Box mt={2} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                Laddar upp...
            </Box>
        );
    }

    const tryRenderPreview = () : undefined|JSX.Element => {

        if (state !== STATE_3_INPUT || stopfilePreviewObj === undefined) {
            return undefined;
        }

        const selectsElements = [];
        for (let colIndex = 0; colIndex < columnTypes.length; colIndex ++) {
        
            const options = [];
            options.push(<MenuItem key={0} value={0}>Använd ej</MenuItem>);
            options.push(<MenuItem key={1} value={1}>Personnummer</MenuItem>);
            options.push(<MenuItem key={2} value={2}>Mobilnummer</MenuItem>);
            options.push(<MenuItem key={3} value={3}>Fastnummer</MenuItem>);

            let selStyle = {
                backgroundColor: '#EEEEEE',
                paddingLeft: '10px',
                width: "90%",
            } as React.CSSProperties;

            if (columnTypes[colIndex] > 0) {
                selStyle = {...selStyle, fontWeight: 'bold'}
            }

            selectsElements.push(
                <td>
                    <Select
                        id={colIndex+''}
                        value={columnTypes[colIndex]}
                        label={'Innehåll'}
                        style={selStyle}
                        onChange={(e) => {
                            let tempValue = e.target.value;
                            if (typeof tempValue === 'string') {
                                tempValue = parseInt(tempValue);
                            }
                            actionSetSelectedOption(colIndex, tempValue);
                        }}>
                            {options}
                    </Select>
                </td>
            );
        }

        const rowElements = [];
        rowElements.push(
            <tr key={1}>
                {selectsElements}
            </tr>
        );

        const tempRows = stopfilePreviewObj.rows;
        
        let key = 2;
        for (const row of tempRows) {
            const cellElements = [];
            for (const cell of row) {
                cellElements.push(
                    <td>{cell}</td>
                );
            }
            rowElements.push(<tr key={key} style={{padding: "0.5rem 7.5rem 0.5rem 0"}}>{cellElements}</tr>);
            key ++;
        }

        let canPressConfirm = false;
        if (state === STATE_3_INPUT) {
            for (const colType of columnTypes) {
                if (colType !== 0) {
                    canPressConfirm = true;
                }
            }
        }
        
        return (
            <div>
                <Box mt={2} style={{overflow: 'auto', height: "30rem"}}>
                    <table>
                        <tbody>
                            {rowElements}
                        </tbody>
                    </table>
                </Box>
                <Box mt={4} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Button
                        onClick={actionConfirmPreview}
                        variant="contained"
                        component="label"
                        color="primary"
                        disabled={!canPressConfirm}>
                            {'Bekräfta'}
                    </Button>
                </Box>
            </div>
        );
    }

    const tryRenderProcessingFile = () : undefined|JSX.Element => {
        if (state !== STATE_4_PROCESSING) {
            return undefined;
        }
        return (
            <Box mt={2} mb={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box>
                    Vi håller på att matcha din stoppfil. Du kommer se den på mina filer när den är klar.
                </Box>
                <Box sx={{ width: '50%' }} marginTop={'10px'}>
                    <EstimatedProgressBar
                        startMillis={progressStartMillis}
                        estimatedMillis={estimatedTimeMillis}
                    /> 
                </Box>
            </Box>
        );
    }

    const tryRenderDone = () : undefined|JSX.Element => {
        if (state !== STATE_5_SUCCESS) {
            return undefined;
        }
        return (
            <Box mt={2}>
                <Box mt={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    Allt klart. Din stoppfil kan nu användas vid beställning.
                </Box>
                <Box mt={4} mb={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Button
                        onClick={actionBackToStart}
                        variant="contained"
                        component="label"
                        color="primary">
                            Ladda upp en till
                    </Button>
                </Box>
            </Box>
        );
    }

    const tryRenderErrorScreen = () : undefined|JSX.Element => {
        if (state !== STATE_6_ERROR) {
            return undefined;
        }
        return (
            <Box mt={2} >
                <Box mt={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    Ett fel inträffade. Försök igen eller kontakta supporten.
                </Box>
                <Box mt={4} mb={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Button 
                        onClick={actionBackToStart}
                        variant="contained"
                        component="label"
                        color="primary">
                            Försök igen
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <>
            {tryRenderUploadForm()}
            {tryRenderUploading()}
            {tryRenderPreview()}
            {tryRenderProcessingFile()}
            {tryRenderDone()}
            {tryRenderErrorScreen()}
        </>
    );
};
