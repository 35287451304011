export class ApiObjStopfile {

    // ==============================================================
    // === Constants
    // ==============================================================

    public static readonly STATE_1_UPLOADED = 1;
    public static readonly STATE_2_PROCESSING = 2;
    public static readonly STATE_3_SUCCESS = 3;
    public static readonly STATE_4_FAILED = 4;

    // ==============================================================
    // === Fields
    // ==============================================================

    id : number;
    name: string;
    create_method: 'manual'|'from_export';
    export_id: number;
    row_count: number;
    match_count: {
        total: number,
        personal_id: number,
        phone_mobile: number,
        phone_fixed: number,
    };
    item_count : {
        total: number,
        personal_id: number,
        phone_mobile: number,
        phone_fixed: number,
    };
    created_at: Date;
    state: number;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {

        this.id = parseInt(data.id);
        this.name = data.name;
        this.create_method = data.create_method;
        this.export_id = parseInt(data.export_id);
        this.row_count = parseInt(data.row_count);
        this.match_count = {
            total: parseInt(data.match_count.total),
            personal_id: parseInt(data.match_count.personal_id),
            phone_mobile: parseInt(data.match_count.phone_mobile),
            phone_fixed: parseInt(data.match_count.phone_fixed)
        }
        this.item_count = {
            total: parseInt(data.item_count.total),
            personal_id: parseInt(data.item_count.personal_id),
            phone_mobile: parseInt(data.item_count.phone_mobile),
            phone_fixed: parseInt(data.item_count.phone_fixed)
        }
        this.created_at = new Date(data.created_at);
        this.state = parseInt(data.state);
    }
}