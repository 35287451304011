import { Box, Button } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { MapContainer, TileLayer, Polygon, useMapEvent, Circle, Polyline, CircleMarker } from 'react-leaflet'
import { LatLng } from 'leaflet';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import DeleteIcon from '@mui/icons-material/Delete';
import { MapAreaCircle } from '../../data_layer/map/MapAreaCircle';
import { MapAreaPolygon } from '../../data_layer/map/MapAreaPolygon';
import { MapPoint } from '../../data_layer/map/MapPoint';

type Props = {
    editEnabled: boolean,
    expanded: boolean,
    areas: Array<MapAreaCircle|MapAreaPolygon>,
    onAddArea: (newArea: MapAreaCircle|MapAreaPolygon) => void,
    onRemoveArea: (areaId: number) => void,
    onToggleMapExpanded: () => void,
}

export const MapDrawArea: FunctionComponent<Props> = ({
    editEnabled,
    expanded,
    areas,
    onAddArea,
    onRemoveArea,
    onToggleMapExpanded,
}) => {

    // ===========================================================
    // === Settings
    // ===========================================================

    const MAX_POLYGON_POINTS = 40;
    const MAX_AREAS_COUNT = 20;
    const HEIGHT_NORMAL = 530;
    const HEIGHT_MINIMIZED = 70;

    const TOOLBAR_GEOMETRICS = {
        x: 10,
        y: 190,
        width: 60,
        height: 200
    };

    const EXPAND_BUTTON_GEOMETRICS = {
        x: 10,
        y: (expanded ? HEIGHT_NORMAL : HEIGHT_MINIMIZED) - 55,
        width: 150,
        height: 45,
    }

    // ===========================================================
    // === Active tool
    // ===========================================================

    const ACTIVE_TOOL_NONE = 0;
    const ACTIVE_TOOL_CIRCLE = 1;
    const ACTIVE_TOOL_POLYGON = 2;
    const ACTIVE_TOOL_DELETE = 3;

    // ===========================================================
    // === Tool circle
    // ===========================================================

    const [circleCenterLat, setCircleCenterLat] = useState<number>(-1);
    const [circleCenterLong, setCircleCenterLong] = useState<number>(-1);
    const [circleRadius, setCircleRadius] = useState<number>(-1);

    // ===========================================================
    // === Tool polygon
    // ===========================================================

    const [polygonPoints, setPolygonPoints] = useState<Array<[number, number]>>([]);

    // ===========================================================
    // === Tool remove
    // ===========================================================

    const [selectedArea, setSelectedArea] = useState<number>(-1);

    // ===========================================================
    // === Shared
    // ===========================================================

    const [activeTool, setActiveTool] = useState<number>(ACTIVE_TOOL_NONE);
    const [currentPoint, setCurrentPoint] = useState<[number, number]>([-1, -1]);
    
    // ===========================================================
    // === Actions
    // ===========================================================

    const actionCircleToolButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        setCircleCenterLat(-1);
        setCircleCenterLong(-1);
        setCircleRadius(-1);

        if (activeTool === ACTIVE_TOOL_CIRCLE) {
            setActiveTool(ACTIVE_TOOL_NONE);
        } else {
            if (areas.length < MAX_AREAS_COUNT) {
                setActiveTool(ACTIVE_TOOL_CIRCLE);
            }
        }
    }

    const actionPolygonToolButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        setPolygonPoints([]);

        if (activeTool === ACTIVE_TOOL_POLYGON) {
            setActiveTool(ACTIVE_TOOL_NONE);
        } else {
            if (areas.length < MAX_AREAS_COUNT) {
                setActiveTool(ACTIVE_TOOL_POLYGON);
            }
        }
    }

    const actionDeleteToolButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        setSelectedArea(-1);

        if (activeTool === ACTIVE_TOOL_DELETE) {
            setActiveTool(ACTIVE_TOOL_NONE);
        } else {
            setActiveTool(ACTIVE_TOOL_DELETE);
        }
    }

    const actionMouseMove = (lat: number, long: number) => {
        if (activeTool === ACTIVE_TOOL_CIRCLE) {
            setCurrentPoint([lat, long]);
            if (circleCenterLat != -1) {
                setCircleRadius(calculateDistance(circleCenterLat, circleCenterLong, lat, long));
            }
        } else if (activeTool == ACTIVE_TOOL_POLYGON) {
            setCurrentPoint([lat, long]);
        }
    }

    const actionMouseClick = (lat: number, long: number) => {
        setCurrentPoint([lat, long]);

        if (activeTool === ACTIVE_TOOL_CIRCLE) {

            if (circleCenterLat == -1) {
                setCircleCenterLat(lat);
                setCircleCenterLong(long);

            } else {
                const finalRadius = calculateDistance(circleCenterLat, circleCenterLong, lat, long);
                const newCircle = MapAreaCircle.createNew(0, circleCenterLat, circleCenterLong, finalRadius);
                onAddArea(newCircle);
                setActiveTool(ACTIVE_TOOL_NONE);
                setCircleCenterLat(-1);
                setCircleCenterLong(-1);
                setCircleRadius(-1);
            }

        } else if (activeTool === ACTIVE_TOOL_POLYGON) {
            if (polygonPoints.length < MAX_POLYGON_POINTS) {
                const tempPoints = [...polygonPoints];
                tempPoints.push([lat, long]);
                setPolygonPoints(tempPoints);
            }
        }
    }

    const actionPolygonStartClicked = () => {
        if (activeTool === ACTIVE_TOOL_POLYGON && polygonPoints.length >= 3) {
            const mapPoints: Array<MapPoint> = [];
            for (const item of polygonPoints) {
                mapPoints.push(new MapPoint(item[0], item[1]));
            }
            const newPolygon = MapAreaPolygon.createNew(0, mapPoints);
            onAddArea(newPolygon);
            setActiveTool(ACTIVE_TOOL_NONE);
            setPolygonPoints([]);
        }
    }

    const actionShapeHoverEnter = (areaId: number) => {
        if (activeTool !== ACTIVE_TOOL_DELETE) {
            return;
        }
        setSelectedArea(areaId);
    }

    const actionShapeHoverExit = (areaId: number) => {
        if (activeTool !== ACTIVE_TOOL_DELETE) {
            return;
        }
        if (selectedArea === areaId) {
            setSelectedArea(-1);
        }
    }

    const actionShapeClicked = (areaId: number) => {
        if (activeTool !== ACTIVE_TOOL_DELETE) {
            return;
        }
        onRemoveArea(areaId);
        setSelectedArea(-1);
    }

    // ===========================================================
    // === Events
    // ===========================================================

    const EventComponentMove: FunctionComponent = () => {
        useMapEvent('mousemove', (event) => {
            actionMouseMove(event.latlng.lat, event.latlng.lng);
        })
        return null;
    }

    const EventComponentClick: FunctionComponent = () => {
        useMapEvent('click', (event) => {
            if (!isPointInsideToolbar(event.containerPoint.x, event.containerPoint.y)) {
                actionMouseClick(event.latlng.lat, event.latlng.lng);
            }
        })
        return null;
    }

    // ===========================================================
    // === Helpers
    // ===========================================================

    const isPointInsideToolbar = (x: number, y: number) : boolean => {
        return (
            (
                x >= TOOLBAR_GEOMETRICS.x
                && x <= (TOOLBAR_GEOMETRICS.x + TOOLBAR_GEOMETRICS.width)
                && y >= TOOLBAR_GEOMETRICS.y
                && y <= (TOOLBAR_GEOMETRICS.y + TOOLBAR_GEOMETRICS.height)
            ) || (
                x >= EXPAND_BUTTON_GEOMETRICS.x
                && x <= (EXPAND_BUTTON_GEOMETRICS.x + EXPAND_BUTTON_GEOMETRICS.width)
                && y >= EXPAND_BUTTON_GEOMETRICS.y
                && y <= (EXPAND_BUTTON_GEOMETRICS.y + EXPAND_BUTTON_GEOMETRICS.height)
            )
        );
    }

    const calculateDistance = (lat1: number, long1: number, lat2: number, long2: number) : number => {
        const start = new LatLng(lat1, long1);
        const end = new LatLng(lat2, long2);
        return start.distanceTo(end);
    }

    // ===========================================================
    // === Render
    // ===========================================================

    const tryRenderToolBar = () : JSX.Element|undefined => {

        if (!editEnabled) {
            return undefined;
        }

        const styleContainer = {
            top: TOOLBAR_GEOMETRICS.y +'px',
            left: TOOLBAR_GEOMETRICS.x +'px',
            width: TOOLBAR_GEOMETRICS.width +'px',
            height: TOOLBAR_GEOMETRICS.height +'px',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 400
        } as React.CSSProperties;

        const styleCircle = {
            border: '1px solid ' + (activeTool === ACTIVE_TOOL_CIRCLE ? '#FFFFFF' : '#3b2408')
        } as React.CSSProperties;

        const stylePolygon = {
            marginTop: '8px',
            border: '1px solid ' + (activeTool === ACTIVE_TOOL_POLYGON ? '#FFFFFF' : '#3b2408')
        } as React.CSSProperties;

        const styleDelete = {
            marginTop: '8px',
            border: '1px solid ' + (activeTool === ACTIVE_TOOL_DELETE ? '#FFFFFF' : '#3b2408')
        } as React.CSSProperties;

        return (
            <div style={styleContainer}>

                <Button
                    onClick={(event) => {actionCircleToolButton(event) }}
                    variant='contained'
                    style={styleCircle}
                    color={'secondary'}
                    size={'large'}>
                    <CircleOutlinedIcon
                        style={{
                            color: (activeTool === ACTIVE_TOOL_CIRCLE) ? '#FFFFFF' : '#3b2408'
                        }}
                    />
                </Button>

                <Button
                    onClick={(event) => {actionPolygonToolButton(event) }}
                    variant='contained'
                    style={stylePolygon}
                    color={'secondary'}
                    size={'large'}>
                    <TimelineIcon
                        style={{
                            color: (activeTool === ACTIVE_TOOL_POLYGON) ? '#FFFFFF' : '#3b2408'
                        }}
                    />
                </Button>

                <Button
                    onClick={(event) => {actionDeleteToolButton(event) }}
                    variant='contained'
                    style={styleDelete}
                    color={'secondary'}
                    size={'large'}>
                    <DeleteIcon
                        style={{
                            color: (activeTool === ACTIVE_TOOL_DELETE) ? '#FFFFFF' : '#3b2408'
                        }}
                    />
                </Button>

            </div>
        );
    }

    const renderExpandButton = () : JSX.Element => {

        const styleContainer = {
            top: EXPAND_BUTTON_GEOMETRICS.y +'px',
            left: EXPAND_BUTTON_GEOMETRICS.x +'px',
            width: EXPAND_BUTTON_GEOMETRICS.width +'px',
            height: EXPAND_BUTTON_GEOMETRICS.height +'px',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 400
        } as React.CSSProperties;

        return (
            <Box style={styleContainer}>
                <Button
                    onClick={onToggleMapExpanded}
                    style={{border: '1px solid #3b2408'}}
                    variant={'contained'}
                    color="secondary"
                    size={'large'}>
                        {expanded ? 'Minimera' : 'Expandera'}
                </Button>
            </Box>
        );
    }

    const renderExistingAreas = () : Array<JSX.Element> => {
        
        const elements = [];
        for (const item of areas) {

            let pathOptions = { color: 'purple' };
            if (item.id === selectedArea) {
                pathOptions = { color: 'red'}
            }

            if (item instanceof MapAreaCircle) {
                elements.push(
                    <Circle
                        key={item.id}
                        center={[item.centerPoint.latitude, item.centerPoint.longitude]}
                        pathOptions={pathOptions}
                        radius={item.radius}
                        eventHandlers={{
                            click: (e) => {
                                actionShapeClicked(item.id);
                            },
                            mouseover: (e) => {
                                actionShapeHoverEnter(item.id);
                            },
                            mouseout: (e) => {
                                actionShapeHoverExit(item.id);
                            }
                        }}
                    />
                );

            } else if (item instanceof MapAreaPolygon) {
                const positions : Array<[number, number]> = [];
                for (const point of item.points) {
                    positions.push([point.latitude, point.longitude]);
                }
                elements.push(
                    <Polygon
                        key={item.id}
                        pathOptions={pathOptions}
                        positions={positions}
                        eventHandlers={{
                            click: (e) => {
                                actionShapeClicked(item.id);
                            },
                            mouseover: (e) => {
                                actionShapeHoverEnter(item.id);
                            },
                            mouseout: (e) => {
                                actionShapeHoverExit(item.id);
                            }
                        }}
                    />
                );
            }
        }

        return elements;
    }

    const tryRenderCircleEdit = () : JSX.Element|undefined => {
        if (activeTool !== ACTIVE_TOOL_CIRCLE) {
            return undefined;
        }
        if (circleCenterLat == -1) {    // User choosing center point.
            return (
                <CircleMarker
                    key={10001}
                    center={[currentPoint[0], currentPoint[1]]}
                    pathOptions={{ color: 'blue' }}
                    radius={50} />
            );
        } else {    // User choosing radius.

            const linePositions: Array<[number, number]> = [
                [circleCenterLat, circleCenterLong],
                currentPoint
            ];

            return (
                <>
                    <Polyline
                        positions={linePositions}
                        pathOptions={{ color: 'blue' }}
                    />
                    <Circle
                        key={10002}
                        center={[circleCenterLat, circleCenterLong]}
                        pathOptions={{ color: 'blue' }}
                        radius={circleRadius} />
                </>
            );
        }
    }

    const tryRenderPolygonEdit = () : JSX.Element|undefined => {

        if (activeTool !== ACTIVE_TOOL_POLYGON) {
            return undefined;
        }

        if (polygonPoints.length == 0) {
            return (
                <CircleMarker
                    key={10006}
                    center={[currentPoint[0], currentPoint[1]]}
                    pathOptions={{ color: 'blue' }}
                    radius={10} />
            );
        }

        const linePositions: Array<[number, number]> = [];
        for (const item of polygonPoints) {
            linePositions.push([item[0], item[1]]);
        }

        const previewLinePositions : Array<[number, number]> = [
            [
                polygonPoints[polygonPoints.length - 1][0],
                polygonPoints[polygonPoints.length - 1][1],
            ],
            [
                currentPoint[0],
                currentPoint[1]
            ]
        ];

        return (
            <>
                
                <Polyline
                    key={10004}
                    positions={linePositions}
                    pathOptions={{ color: 'blue' }}
                />

                <Polyline
                    key={10005}
                    positions={previewLinePositions}
                    pathOptions={{ color: 'blue', opacity:0.4 }}
                />

                <CircleMarker
                    key={10003}
                    center={[linePositions[0][0], linePositions[0][1]]}
                    eventHandlers={{
                        click: (e) => {
                            actionPolygonStartClicked();
                        },
                    }}
                    pathOptions={{ color: 'blue' }}
                    radius={10} />
            </>
        );
    }

    const mapHeight = expanded ? HEIGHT_NORMAL : HEIGHT_MINIMIZED;

    return (
        <Box mb={4} height={mapHeight}>
            <MapContainer
                style={{height: mapHeight+'px'}}
                center={[59.518884142108966, 15.8882688471977]}
                zoomControl={expanded}
                zoom={6}
                scrollWheelZoom={true}>

                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {renderExistingAreas()}

                {tryRenderCircleEdit()}
                {tryRenderPolygonEdit()}

                <EventComponentMove />
                <EventComponentClick />

                {tryRenderToolBar()}
                {renderExpandButton()}

            </MapContainer>
        </Box>
        
    );
}