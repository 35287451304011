import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjPopulateFile } from '../../object/populate_file/ApiObjPopulateFile';

export const api_populate_file_order_post_step_3 = async (populateFileId: number, cleanStopfile: number, stopfileIds: Array<number>) : Promise<ApiObjPopulateFile> => {
    
    const url = API_URL+'/populate_file/'+populateFileId+'/order_step_3';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    const body = {
        clean_stopfile: cleanStopfile,
        clean_stopfile_ids: stopfileIds
    }
    
    const resp = await Axios.post(
        url,
        body,
        config
    );
        
    return new ApiObjPopulateFile(resp.data);
}
