export class ApiObjPopulateFile {

    // ==============================================================
    // === Types
    // ==============================================================

    public static readonly STATE_1_UPLOAD_STARTED = 1;
    public static readonly STATE_2_UPLOAD_FAILED = 2;
    public static readonly STATE_3_UPLOAD_COMPLETE = 3;
    public static readonly STATE_4_PARSE_STARTED = 4;
    public static readonly STATE_5_PARSE_FAILED = 5;
    public static readonly STATE_6_PARSE_COMPLETE = 6;
    public static readonly STATE_7_BUILD_STARTED = 7;
    public static readonly STATE_8_BUILD_FAILED = 8;
    public static readonly STATE_9_BUILD_COMPLETE = 9;
    public static readonly STATE_10_PAYMENT_STARTED = 10;
    public static readonly STATE_11_PAYMENT_FAILED = 11;
    public static readonly STATE_12_PAYMENT_COMPLETE = 12;
    public static readonly STATE_13_READY = 13;
    public static readonly STATE_14_DELETED = 14;

    public static readonly STATE_NAMES : Array<{state: number, name: string}> = [
        {state: ApiObjPopulateFile.STATE_1_UPLOAD_STARTED, name: 'Laddar upp'},
        {state: ApiObjPopulateFile.STATE_2_UPLOAD_FAILED, name: 'Uppladdning misslyckades'},
        {state: ApiObjPopulateFile.STATE_3_UPLOAD_COMPLETE, name: 'Uppladdning lyckades'},
        {state: ApiObjPopulateFile.STATE_4_PARSE_STARTED, name: 'Analyserar filen'},
        {state: ApiObjPopulateFile.STATE_5_PARSE_FAILED, name: 'Analys misslyckades.'},
        {state: ApiObjPopulateFile.STATE_6_PARSE_COMPLETE, name: 'Analys lyckades.'},
        {state: ApiObjPopulateFile.STATE_7_BUILD_STARTED, name: 'Bygger fil.'},
        {state: ApiObjPopulateFile.STATE_8_BUILD_FAILED, name: 'Filbygge misslyckades.'},
        {state: ApiObjPopulateFile.STATE_9_BUILD_COMPLETE, name: 'Filbygge lyckades.'},
        {state: ApiObjPopulateFile.STATE_10_PAYMENT_STARTED, name: 'Betalning skickad.'},
        {state: ApiObjPopulateFile.STATE_11_PAYMENT_FAILED, name: 'Betalning misslyckades.'},
        {state: ApiObjPopulateFile.STATE_12_PAYMENT_COMPLETE, name: 'Betalning lyckades.'},
        {state: ApiObjPopulateFile.STATE_13_READY, name: 'Klar'},
        {state: ApiObjPopulateFile.STATE_14_DELETED, name: 'Borttagen'},
    ];

    // ==============================================================
    // === Match mode
    // ==============================================================

    public static readonly MATCH_MODE_1_STRICT = 1;
    public static readonly MATCH_MODE_2_LOOSE = 2;

    // ==============================================================
    // === Clean Setting
    // ==============================================================

    public static readonly CLEAN_SETTING_OFF = 0;
    public static readonly CLEAN_SETTING_MARK = 1;
    public static readonly CLEAN_SETTING_DELETE = 2;

    // ==============================================================
    // === Payment method
    // ==============================================================

    // ==============================================================
    // === Column types
    // ==============================================================

    // ==============================================================
    // === Fields
    // ==============================================================

    id: number;
    user_id: number;
    params: {
        name: string
        data_option_ids: Array<number>,
        clean_low_income: number,
        clean_nix: number,
        clean_hlr: number,
        clean_stopfile: number,
        clean_stopfile_ids: Array<number>,
        payment_method: number,
        column_types: Array<number>,
    };
    count: {
        columns: number
        rows: number,
        matches: number,
        phone_numbers_in: number,
    };
    state: number;
    error_msg: string;
    created_at: Date;
    updated_at: Date;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {

        this.id = parseInt(data.id);
        this.user_id = parseInt(data.user_id);

        const dataOptionIds = [];
        for (const item of data.params.data_option_ids) {
            dataOptionIds.push(parseInt(item));
        }

        const stopfileIds = [];
        for (const item of data.params.clean_stopfile_ids) {
            stopfileIds.push(parseInt(item));
        }

        const columnsTypes = [];
        for (const item of data.params.column_types) {
            columnsTypes.push(parseInt(item));
        }
        
        this.params = {
            name: data.params.name,
            data_option_ids: dataOptionIds,
            clean_low_income: parseInt(data.params.clean_low_income),
            clean_nix: parseInt(data.params.clean_nix),
            clean_hlr: parseInt(data.params.clean_hlr),
            clean_stopfile: parseInt(data.params.clean_stopfile),
            clean_stopfile_ids: stopfileIds,
            payment_method: parseInt(data.params.payment_method),
            column_types: columnsTypes,
        }

        this.count = {
            columns: parseInt(data.count.columns),
            rows: parseInt(data.count.rows),
            matches: parseInt(data.count.matches),
            phone_numbers_in: parseInt(data.count.phone_numbers_in),
        };

        this.state = parseInt(data.state);
        this.error_msg = data.error_msg;
        this.created_at = new Date(data.created_at);
        this.updated_at = new Date(data.updated_at);
    }

    // ==============================================================
    // === Methods
    // ==============================================================

    getStateName () : string {
        for (const item of ApiObjPopulateFile.STATE_NAMES) {
            if (this.state === item.state) {
                return item.name;
            }
        }
        return '';
    }

    isStateProcessing () : boolean {
        const processingStates = [
            ApiObjPopulateFile.STATE_1_UPLOAD_STARTED,
            ApiObjPopulateFile.STATE_4_PARSE_STARTED,
            ApiObjPopulateFile.STATE_7_BUILD_STARTED,
            ApiObjPopulateFile.STATE_10_PAYMENT_STARTED
        ];
        return processingStates.includes(this.state);
    }
}