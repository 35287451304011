export class ApiObjFilter {

    /** id */
    private readonly a : number;

    /** parent_id */
    private readonly b : number;

    /** filter_select_group */
    private readonly c : number;

    /** name */
    private readonly d : string;

    /** value_column */
    private readonly e : string;

    /** value_1 */
    private readonly f : string;

    /** value_2 */
    private readonly g : string;

    /** is_selectable */
    private readonly h : boolean;

    /** is_premium */
    private readonly i : boolean;

    /** is_premium_marketing */
    private readonly j : boolean;

    /** sort_order */
    private readonly k : number;

    /** level_in_tree */
    private readonly l : number;

    /** filter_id_premium_export */
    private readonly m : number;

    /** filter_id_premium_marketing */
    private readonly n : number;

    /** counts */
    private readonly z : Array<number>;

    /** children */
    private readonly x : Array<ApiObjFilter>;

    constructor(data: any, dataVersion: number) {

        if (dataVersion === 2) {
            this.a = parseInt(data.d[0]);
            this.b = parseInt(data.d[1]);
            this.c = parseInt(data.d[2]);
            this.d = data.d[3];
            this.e = data.d[4];
            this.f = data.d[5];
            this.g = data.d[6];
            this.h = parseInt(data.d[7]) === 1;
            this.i = parseInt(data.d[8]) === 1;
            this.j = parseInt(data.d[9]) === 1;
            this.k = parseInt(data.d[10]);
            this.l = parseInt(data.d[11]);
            this.m = parseInt(data.d[12]);
            this.n = parseInt(data.d[13]);
            this.z = [
                parseInt(data.d[14]),
                parseInt(data.d[15]),
                parseInt(data.d[16]),
                parseInt(data.d[17]),
                parseInt(data.d[18]),
                parseInt(data.d[19]),
                parseInt(data.d[20]),
                parseInt(data.d[21])
            ];
            this.x = [];
            for (const child of data.c) {
                this.x.push(new ApiObjFilter(child, dataVersion));
            }
        } else {
            throw new Error("Data version not handled: Dataversion: "+dataVersion);
        }
    }

    getId () : number {
        return this.a;
    }

    getParentId () : number {
        return this.b;
    }

    getFilterSelectGroup () : number {
        return this.c;
    }

    getName () : string {
        return this.d;
    }

    getValueColumn () : string {
        return this.e;
    }

    getValue1 () : string {
        return this.f;
    }

    getValue2 () : string {
        return this.g;
    }

    isSelectable () : boolean {
        return this.h;
    }

    isPremiumForExport () : boolean {
        return this.i;
    }

    isPremiumForMarketing () : boolean {
        return this.j;
    }

    getSortOrder () : number {
        return this.k;
    }

    getLevelInTree () : number {
        return this.l;
    }

    getFilterIdPremiumExport () : number {
        return this.m;
    }

    getFilterIdPremiumMarketing () : number {
        return this.n;
    }

    getCounts () : Array<number> {
        return this.z;
    }

    getCount(nixOptionId: number) : number {
        if (nixOptionId < 1 || nixOptionId > 8) {
            throw new Error("Unexpected value of nixOptionId");
        }
        return this.z[nixOptionId - 1];
    }

    getChildren () : Array<ApiObjFilter> {
        return this.x;
    }
}