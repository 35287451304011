import { ApiObjPriceObject } from "../price/ApiObjPriceObject";

export class ApiObjPopulateFileOrderStep2 {

    // ==============================================================
    // === Fields
    // ==============================================================

    match_count_details: Array<{type: number, count: number}>;
    price_estimate: Array<{
        price_object: ApiObjPriceObject,
        items_count: number,
        price_total: number
    }>;

    // ==============================================================
    // === Create
    // ==============================================================

    constructor(data: any) {
        this.match_count_details = [];
        for (const item of data.match_count_details) {
            this.match_count_details.push({
                type: parseInt(item.type),
                count: parseInt(item.count)
            });
        }
        this.price_estimate = [];
        for (const item of data.price_estimate) {
            this.price_estimate.push({
                price_object: new ApiObjPriceObject(item.price_object),
                items_count: parseInt(item.items_count),
                price_total: parseFloat(item.price_total)
            });
        }
    }
}