import { UtilArray } from '../../util/code/UtilArray';

export type OrderPriceParams = {
    has_user: boolean,
    item_count: number,
    nix_option_id: number,
    source_id: number,
    filter_ids: Array<number>,
    uses_map: boolean,
    export_field_ids: Array<number>,
    uses_stopfiles: boolean,
    household_option_id: number,
}

export const orderPriceParamsIsEqual = (p1: OrderPriceParams, p2: OrderPriceParams) : boolean => {
    return p1.has_user === p2.has_user
        && p1.item_count === p2.item_count
        && p1.nix_option_id === p2.nix_option_id
        && p1.source_id === p2.source_id
        && UtilArray.arrayEquals(p1.filter_ids, p2.filter_ids)
        && p1.uses_map === p2.uses_map
        && UtilArray.arrayEquals(p1.export_field_ids, p2.export_field_ids)
        && p1.uses_stopfiles === p2.uses_stopfiles
        && p1.household_option_id === p2.household_option_id
}

export const orderPriceParamsClone = (p: OrderPriceParams) : OrderPriceParams => {
    return {
        has_user: p.has_user,
        item_count: p.item_count,
        nix_option_id: p.nix_option_id,
        source_id: p.source_id,
        filter_ids: [...p.filter_ids],
        uses_map: p.uses_map,
        export_field_ids: [...p.export_field_ids],
        uses_stopfiles: p.uses_stopfiles,
        household_option_id: p.household_option_id
    }
}
