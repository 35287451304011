import { FunctionComponent, useEffect, useState } from 'react';
import { ApiObjBaseData } from '../../../api/object/base_data/ApiObjBaseData';
import TabsComponent from '../../../components/TabsComponent';
import { OrderManager } from '../../../data_layer/order/OrderManager';
import { UserData } from '../../../data_layer/user/UserData';
import { ErrorPageNoUser } from '../../error/ErrorPageNoUser';
import { ExportList } from './components/ExportList';
import { StopfileList } from './components/StopfileList';
import { PopulateFileList } from './components/PopulateFileList';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

type Props = {
    baseData: ApiObjBaseData
    userData: UserData,
    orderManager: OrderManager
}

export const MyFilesPage: FunctionComponent <Props> = ({
    baseData,
    userData,
    orderManager,
}) => {
    const [searchParams] = useSearchParams()
    const [search, setSearch] = useState("")
    const [value, setValue] = useState(Number(searchParams.get("key")));
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)

    if (!userData.isLoggedIn()) {
        return <ErrorPageNoUser />;
    }

    return (
        <TabsComponent
            value={value}
            search={search}
            selectedDate={selectedDate}
            setValue={setValue}
            setSearch={setSearch}
            setSelectedDate={setSelectedDate}
            tabs={["Exporter", "Stoppfiler", "Nummersättning"]}
            tabContent={[
                <ExportList
                    key={1}
                    search={search}
                    baseData={baseData}
                    userData={userData}
                    selectedDate={selectedDate}
                    orderManager={orderManager}
                />,
                <StopfileList key={2} search={search} selectedDate={selectedDate} />,
                <PopulateFileList
                key={3}
                search={search}
                baseData={baseData}
                userData={userData}
                selectedDate={selectedDate}
            />,
            ]}
        />
    );
};
