import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjApiKey } from '../../object/api_key/ApiObjApiKey';

export const api_get_api_keys = async () : Promise<Array<ApiObjApiKey>> => {
    
    const url = API_URL+'/api_key';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true 
    }
    
    const resp = await Axios.get(
        url,
        config
    );

    const ret = [];
    for (const item of resp.data) {
        ret.push(new ApiObjApiKey(item));
    }
    return ret;
}
