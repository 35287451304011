import { ApiObjExportField } from "../export/ApiObjExportField";
import { ApiObjNixOption } from "../nix_option/ApiObjNixOption";
import { ApiObjSource } from "../source/ApiObjSource";

export class ApiObjAdminBaseData {
    
    sources: Array<ApiObjSource>;
    export_fields: Array<ApiObjExportField>;
    nix_options: Array<ApiObjNixOption>;
    
    constructor(data: any) {

        this.sources = [];
        for (const item of data.sources) {
            this.sources.push(new ApiObjSource(item));
        }

        this.export_fields = [];
        for (const item of data.export_fields) {
            this.export_fields.push(new ApiObjExportField(item));
        }

        this.nix_options = [];
        for (const item of data.nix_options) {
            this.nix_options.push(new ApiObjNixOption(item));
        }
    }
}