import Axios from 'axios';
import { API_URL } from '../../../constants/Constants';
import { ApiObjStopfile } from '../../object/stopfile/ApiObjStopfile';

/**
 * @param {number} stopfileId 
 * @param {Array<{id: number, type: number}>} columns 
 * @returns 
 */
export const api_process_stopfile = async (stopfileId: number, columnTypes: Array<number>) : Promise<ApiObjStopfile> => {
    
    const url = API_URL+'/stopfile/'+stopfileId+'/process';
    const config = {
        'Access-Control-Allow-Origin': API_URL,
        withCredentials: true
    }
    const data = {
        column_types: columnTypes,
    }
    
    const resp = await Axios.post(
        url,
        data,
        config
    );
        
    return new ApiObjStopfile(resp.data);
}