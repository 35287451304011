String.prototype.interpolate = function (params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
};
export default (intl) =>
  function () {
    let a = Array.prototype.slice.call(arguments, 0);
    let o = a.pop();
    if (typeof o === 'object') {
      a.reverse();
      return intl.formatMessage({ id: a.join('.') }, o);
    } else {
      a.push(o);
      a.reverse();
      return intl.formatMessage({ id: a.join('.') });
    }
  };
